/* ==========================================================================
   Components / Excercice : Contenu Cliquable
   ========================================================================== */

  .clickcontent {
    margin-top: 15px;
    padding: 20px 25px;

    @include breakpoint(phablet) {
      padding: 20px 45px;
    }

    @include breakpoint(desktop) {
      margin: 45px auto 35px;
    }

    @include breakpoint(largedesktop) {
      padding: 20px 50px;
    }

    @include breakpoint(xldesktop) {
      padding: 20px 85px;
    }

    &-buttons {
      margin-bottom: 30px;

      @include breakpoint(768px) {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-around;
      }

      @include breakpoint(desktop) {
        display: inline-block;
        width: 40%;
        margin-right: 4%;
      }

      @include breakpoint(largedesktop) {
        width: 35%;
        margin-right: 6%;
      }

      .btn-click {
        display: block;
        width: 100%;
        background: transparent;
        border: 1px solid $blueColor;
        font-size: 17px;
        color: $greyText;
        line-height: 1.2;
        text-align: center;
        padding: 15px 10px;
        margin-bottom: 20px;
        cursor: pointer;

        @include breakpoint(768px) {
          flex-basis: 45%;
          width: inherit;
        }

        @include breakpoint(desktop) {
          width: 80%;
          margin: auto auto 30px;
        }

        @include breakpoint(largedesktop) {
          margin: auto auto 35px;
        }

        @include breakpoint(xldesktop) {
          margin: auto auto 40px;
        }

        &.current {
          background: lighten($blueColor, 3%);
          border: 1px solid lighten($blueColor, 3%);
          color: $whiteColor;
          position: relative;

          &:after {
            content: ' ';
            display: none;
            width: 23px;
            height: 27px;
            background: url('/assets/front/images/icone-clickctn.svg') center center/23px 27px no-repeat;
            position: absolute;
            right: -55px;
            top: 0;
            bottom: 0;
            margin: auto;
          }

          @include breakpoint(desktop) {
            &:after {
              display: block;
            }
          }

          @include breakpoint(largedesktop) {
            &:after {
              right: -75px;
            }
          }
        }

        &:focus {
          outline-style: none;
        }
      }
    }

    &-infos {
      position: relative;

      @include breakpoint(laptop) {
        width: 95%;
        margin: auto;
      }

      @include breakpoint(desktop) {
        display: inline-block;
        width: 55%;
        vertical-align: top;
      }

      @include breakpoint(largedesktop) {
        width: 58%;
      }

      .item {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        transition: all $duration $easeOutCubic;

        &.currentItem {
          opacity: 1.0;
          position: relative;
          transition: all $duration $easeOutCubic;
        }

        video {
          width: 100%;
          height: auto;
          border-radius: 10px;
          margin-bottom: 20px;
        }
      }

      .image {
        width: 100%;
        background: $darkColor;
        border-radius: 10px;
        margin-bottom: 20px;
        overflow: hidden;

        @include breakpoint(phablet) {
          margin-bottom: 20px;
        }

        img {
          display: block;
         // opacity: 0.8;
          width: 100%;
        }
      }

      .text {
        text-align: left;
        line-height: 1.4;
        margin-bottom: 35px;

        @include breakpoint(desktop) {
          text-align: left;
        }
      }
    }
  }