/* ==========================================================================
   Layout / Header
   ========================================================================== */

  .header {
    display: table;
    width: 100%;

    &-content {
      @include breakpoint(tablet) {
        display: flex;
        align-items: stretch;
        align-content: stretch;
      }
    }

    &-text {
      padding: 50px 30px 30px;
      position: relative;
      z-index: 1;

      @include breakpoint(phablet) {
        padding: 50px 80px;
      }

      @include breakpoint(tablet) {
        flex-basis: 50%;
        padding: 70px 20px 70px 60px;
      }

      @include breakpoint(desktop) {
        padding: 90px 100px;
        text-align: right;
      }

      .text {
        position: relative;
        z-index: 1;

        @include breakpoint(desktop) {
          float: right;
          max-width: 490px;
        }

        p {
          font-size: 14px;
          letter-spacing: 0.08rem;
        }
      }

      @include breakpoint(tablet) {
        &:after {
          content: ' ';
          display: block;
          transform: skewX(-10deg);
          background-color: $whiteColor;
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 0;
          top: 0;
          right: -8%;
        }
      }
    }

    &-image {
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      min-height: 255px;
      overflow: hidden;
      position: relative;

      @include breakpoint(tablet) {
        flex-basis: 50%;
      }

      @include breakpoint(desktop) {
        min-height: 295px;
      }

      @include breakpoint(xldesktop) {
        min-height: 335px;
      }

      &:before {
        content: ' ';
        display: none;
        width: 100%;
        height: 100%;
        background-color: $blueColor;
        opacity: 0.35;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
