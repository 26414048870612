/* ==========================================================================
   Page / Training Details Manager
   ========================================================================== */

  .traindetails {
    display: table;
    width: 100%;
    max-width: 1350px;
    margin: auto;
    padding: 25px 0px;
    background-color: $whiteColor;
    border-radius: 10px;
    border: 1px solid #dddddd;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.09);
    margin-bottom: 20px;
    position: relative;
    transition: all $duration $easeInCirc;



    @include breakpoint(phablet) {
      padding: 30px 0px;
    }

    @include breakpoint(tablet) {
      padding: 35px 0px;
    }

    @include breakpoint(desktop) {
      padding: 35px 0px;
    }

    .backArrow {
      display: block;
      position: absolute;
      top: 40px;
      left: 25px;
      width: 16px;
      height: 16px;
      transform: rotateZ(135deg);
      border-right: 3px solid $greyText;
      border-bottom: 3px solid $greyText;
      text-indent: -9999px;
      transition: all $duration $easeInOutCirc;

      &:hover {
        left: 30px;
        transition: all $duration $easeInOutCirc;
      }

      @include breakpoint(phablet) {
        top: 50px;
        left: 35px;
      }

      @include breakpoint(1200px) {
        top: 65px;
      }
    }

    &-infos {
      display: table;
      width: 100%;
      padding: 0 15px;

      @include breakpoint(phablet) {
        padding: 0 15px;
      }

      @include breakpoint(tablet) {
        padding: 0 20px;
      }

      @include breakpoint(desktop) {
        padding: 0 17px;
      }

      @include breakpoint(1100px) {
        padding: 0 17px 20px;
      }

      @include breakpoint(1200px) {
        padding: 0 17px;
      }

      .training-block {
        &.specialWidth {
          @include breakpoint(800px) {
            margin-left: 5px!important;
            max-width: 33%;
          }

          @include breakpoint(880px) {
            margin-left: 55px!important;
            max-width: 30%;
          }

          @include breakpoint(1200px) {
            max-width: auto;
            margin-left: 0px!important;
          }
        }

        @include breakpoint(800px) {
          padding: 0;
        }

        @include breakpoint(1200px) {
          padding: 0 10px;
        }

        @include breakpoint(desktop) {
          margin-top: 0;
          padding: 0;
        }

        &:after {
          @include breakpoint(768px) {
            display: none;
          }
        }
      }

      .training-place {
        padding-left: 25px;
        padding-top: 7px;
        margin-left: 45px;
        margin-bottom: 25px;
        width: 90%;

        @include breakpoint(phablet) {
          padding-left: 35px;
          margin-left: 65px;
        }

        &:after {
          display: block;
          content: ' ';
          position: absolute;
          bottom: 0;
          width: 1px;
          top: 2%;
          margin: auto;
          background-color: darken($greyBackground, 5%);
          height: 90%;
          left: 0;

          @include breakpoint(phablet) {
            height: 45px;
          }
        }

        @include breakpoint(1200px) {
          width: 25%;
          margin-bottom: 0px;

          &:after {
            top: 9%;
            height: 80px;
          }
        }

        @include breakpoint(1280px) {
          width: 29%;
        }
      }

      .training-type {
        font-size: 14px;
        margin-bottom: 10px;
      }

      .training-name {
        width: 100%;
        white-space: normal;
      }

      .training-infos {
        @include breakpoint(tablet) {
          max-width: 240px;
        }

        @include breakpoint(1200px) {
          width: 18%;
        }
      }
    }

    &-tools {
      display: table;
      width: 100%;
      text-align: center;
      padding: 15px 30px;
      margin: 5px 0;

      @include breakpoint(phablet) {
        padding: 15px 50px;
        text-align: right;
      }

      @include breakpoint(1100px) {
        padding: 0px 50px 25px;
      }

      @include breakpoint(1200px) {
        padding: 30px 50px;
      }

      div {
        display: block;
        text-align: center;
        margin: 0 auto 15px;

        @include breakpoint(905px) {
          display: inline-block;
          margin-left: 30px;
          margin-bottom: 5px;
        }

        &:first-child {
          @include breakpoint(760px) {
            position: absolute;
            top: 20px;
            right: 25px;
          }

          @include breakpoint(1200px) {
            float: left;
            position: inherit;
            top: 0;
            right: 0;
          }
        }

        a {
          margin: 5px;
        }
      }
    }

    &-users {
      display: table;
      width: 100%;
      border-top: 1px solid darken($greyBackground, 8%);
    }

    .userinfos {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 35px 15px 35px;
      border-bottom: 1px solid darken($greyBackground, 8%);

      @include breakpoint(340px) {
        padding: 35px 25px 35px;
      }

      @include breakpoint(992px) {
        padding: 25px 25px 35px;
      }

      @include breakpoint(1160px) {
        padding: 25px;
      }

      @include breakpoint(1200px) {
        padding: 35px;
      }

      &-name {
        font-size: 18px;
        font-weight: 300;
        line-height: 20px;
        padding-left: 60px;
        padding-top: 3px;
        padding-bottom: 3px;
        position: relative;
        margin-left: 15px;
        margin-bottom: 35px;
        background: url('/assets/front/images/picto-apprenant.svg') top left/ 45px 45px no-repeat;

        @include breakpoint(768px) {
          margin-bottom: 25px;
        }

        @include breakpoint(992px) {
          width: 20%;
          padding-left: 65px;
          padding-top: 3px;
          padding-bottom: 3px;
          margin-left: 0;
          margin-bottom: 0;
        }

        @include breakpoint(1160px) {
          width: 18%;
        }

        &:after {
          display: block;
          content: ' ';
          position: absolute;
          bottom: 0;
          width: 1px;
          height: 50px;
          top: 0px;
          right: -13%;
          margin: auto;
          background-color: #e1e7eb;
        }

        b {  display: block;  }

        &.late {
          &:before {
            position: absolute;
            left: 1px;
            top: 15px;
            width: 15px;
            height: 20px;
            content: url('/assets/front/images/icone-alerte.svg');

            @include breakpoint(1160px) {
              top: -5px;
            }
          }
        }

        .alert {
          display: table;
          margin-top: 5px;
          font-size: 13px;
          line-height: 10px;
          font-weight: bold;
          color: $whiteColor;
          background: $redColor;
          border-radius: 15px;
          padding: 2px 12px 3px;

          @include breakpoint(1160px) {
            position: absolute;
          }
        }
      }

      &-details {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding-left: 20px;

        @include breakpoint(768px) {
          flex-wrap: nowrap;
        }

        @include breakpoint(800px) {
          padding-right: 5%;
        }

        @include breakpoint(992px) {
          padding-left: 55px;
          padding-right: 5%;
          width: 60%;
        }

        @include breakpoint(1024px) {
          padding-left: 75px;
        }

        @include breakpoint(1040px) {
          padding-right: 3%;
        }

        @include breakpoint(1160px) {
          width: 52%;
          padding-right: 2%;
        }

        @include breakpoint(1240px) {
          width: 48%;
        }

        @include breakpoint(1311px) {
          padding-left: 80px;
        }

        @include breakpoint(1390px) {
          padding-left: 75px;
          padding-right: 40px;
        }

        div {
          margin-bottom: 15px;

          @include breakpoint(768px) {
            margin-bottom: 0;
          }
        }

        &:after {
          display: none;
          content: ' ';
          position: absolute;
          bottom: 0;
          width: 1px;
          height: 50px;
          top: 0px;
          right: 2%;
          margin: auto;
          background-color: #e1e7eb;

          @include breakpoint(800px) {
            right: 4%;
          }

          @include breakpoint(992px) {
            right: 2%;
          }

          @include breakpoint(768px) {
            display: block;
          }
        }

        .validation {
          display: inline-block;
          margin-right: 15px;

          @include breakpoint(1024px) {
            margin-right: 25px;
          }

          @include breakpoint(1311px) {
            margin-right: 35px;
          }

          b {
            font-size: 18px;
          }
        }

        .timespend, .timeto {
          display: inline-block;
          text-align: center;
          font-size: 14px;
          line-height: 14px;
          margin-right: 15px;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            letter-spacing: -0.02rem;
          }

          @include breakpoint(1024px) {
            margin-right: 25px;
          }

          @include breakpoint(1311px) {
            margin-right: 35px;
          }

          &.finish {
            opacity: 0.4;
          }

          div {
            display: block;
            color: $greyLightColor;
            line-height: 20px;
            font-size: 20px;
            margin-top: 8px;

            span {
              font-size: 16px;
            }

          }
        }

        .modules {
          width: 65px;
          height: 65px;
          border: 2px solid lighten($greyColor, 5%);
          border-radius: 500px;
          position: relative;
          color: $blueColor;
          margin-right: 25px;
          flex-shrink: 0;

          @include breakpoint(phablet) {
            margin-right: 20px;
          }

          @include breakpoint(tablet) {
            margin-right: 15px;
            margin-left: 5px;
          }

          div {
            display: inline-block;
            text-align: center;
            font-size: 16px;
            text-transform: uppercase;
            line-height: 15px;
            position: absolute;
            top: 15px;
            left: 0;
            right: 0;
            margin: auto;

            span {
              display: block;
              text-transform: none;
              font-size: 12px;
              color: $greyLightColor!important;
              opacity: 0.6;
            }
          }

          &.complete {
            border: 2px solid $greenColor;
            color: $greenColor;
          }

          &.quarter {
            border-right-color: $blueColor;
          }

          &.twoquarter {
            border-right-color: $blueColor;
            border-bottom-color: $blueColor;
          }

          &.threequarters {
            border-left-color: $blueColor;
            border-right-color: $blueColor;
            border-bottom-color: $blueColor;
          }
        }
      }

      &-practice {
        padding-left: 20px;

        @include breakpoint(765px) {
          padding-left: 15px;
        }

        @include breakpoint(800px) {
          padding-left: 10px;
        }

        @include breakpoint(920px) {
          padding-left: 30px;
        }

        @include breakpoint(992px) {
          padding-left: 15px;
        }

        @include breakpoint(992px) {
          width: 16%;
        }

        @include breakpoint(1240px) {
          width: 21%;
          padding-left: 20px;
        }

        @include breakpoint(xldesktop) {
          padding-left: 25px;
        }

        b {
          display: block;
          font-size: 18px;
        }

        &:after {
          display: none;
          content: ' ';
          position: absolute;
          bottom: 0;
          width: 1px;
          height: 50px;
          top: 0px;
          right: -1%;
          margin: auto;
          background-color: #e1e7eb;

          @include breakpoint(1160px) {
            display: block;
          }
        }

        .validInput {
          display: inline-block;
          input[type="radio"]:checked + label {
            color: #3dce62;
            &:before {
              border: 2px solid #3dce62;
            }

            &:after {
              background: #3dce62;
            }
          }
        }

        .unvalidInput {
          display: inline-block;
          input[type="radio"]:checked + label {
            color: #be2e2e;
            &:before {
              border: 2px solid #be2e2e;
            }

            &:after {
              background: #be2e2e;
            }
          }
        }

        .null {
          display: inline-block;

          input[type="radio"] + label {
            margin-right:0;
            &:before {
              display:none
            }

            &:after {
              display:none;
            }
          }
        }

        input[type="radio"] {
          display: none;
        }

        input[type="radio"] + label {
          font-size: 16px;
          line-height: 1;
          font-weight: 600;
          margin-right: 15px;
          font-family: "pangram", sans-serif;
          position: relative;
          cursor: pointer;

          &:before {
            content: ' ';
            display: inline-block;
            width: 17px;
            height: 17px;
            border-radius: 500px;
            border: 2px solid #1f73fd;
            padding: 0;
            margin-top: 0;
            margin-right: 10px;
            position: relative;
            top: 2px;
            cursor: pointer;
          }

          &:after {
            content: ' ';
            border-radius: 800px;
            width: 9px;
            height: 9px;
            background: #1f73fd;
            position: absolute;
            top: 4px;
            left: 2px;
            bottom: 0;
            margin: 2px;
            transform: scale(0.0);
            opacity: 0;
            transition: all 300ms ease-in;
          }
        }

        input[type="radio"]:checked + label {
          color: #1f73fd;

          &:after {
            transform: scale(1.0);
            opacity: 1.0;
            transition: all 300ms ease-in;
          }
        }
      }

      &-more {
        margin: 35px auto 5px;

        @include breakpoint(500px) {
          position: absolute;
          top: 40px;
          right: 10%;
          margin: 0;
        }

        @include breakpoint(992px) {
          top: 65%;
          left: 25px;
          right: inherit;
          padding-left: 60px;
        }

        @include breakpoint(1160px) {
          position: relative;
          top: 0px;
          left: inherit;
          padding-left: 6%;
        }

        @include breakpoint(1240px) {
          padding-left: 3%;
        }

        a {
          font-size: 16px;
          font-weight: bold;
          color: $bluedarkColor;
          letter-spacing: 0.13rem;
          transition: all $durationSmall $easeInOutCirc;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            letter-spacing: 0.10rem;
          }

          @include breakpoint(992px) {
            font-size: 14px;
          }

          &:hover {
            text-decoration: none;
            transition: all $durationSmall $easeInOutCirc;

            &:after {
              right: -25px;

              @include breakpoint(992px) {
                font-size: 13px;
                right: -75px;
              }

              @include breakpoint(1160px) {
                left: 160px;
              }

              @include breakpoint(1180px) {
                left: 170px;
              }

              @include breakpoint(1240px) {
                left: 130px;
              }
            }
          }

          &:after {
            content: ' ';
            width: 8px;
            height: 15px;
            display: flex;
            margin: auto;
            transition: all $duration ease-in-out;
            transform: rotate(0deg);
            position: absolute;
            top: 25%;
            right: -20px;
            background: url('/assets/front/images/fleche-bleue.svg') center center/ 8px 15px no-repeat;

            @include breakpoint(992px) {
              left: 155px;
            }

            @include breakpoint(1160px) {
              width: 12px;
              height: 23px;
              top: 15%;
              left: 155px;
              background: url('/assets/front/images/fleche-bleue.svg') center center/ 12px 23px no-repeat;
            }

            @include breakpoint(1180px) {
              left: 165px;
            }

            @include breakpoint(1240px) {
              top: 10%;
              left: 140px;
            }
          }
        }
      }
    }
  }