/* ==========================================================================
   Page / Training Home Page for Learner
   ========================================================================== */

  .trainhomecontainer {
    padding: 0;
    background: $greyBackground;
  }

  .traininghome {
    margin: 0 auto;

    .title {
      text-align: center;
      font-size: 28px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 30px;
      position: relative;
      z-index: 1;

      @include breakpoint(768px) {
        text-align: left;
      }
    }

    .subtitle {
      font-size: 20px;
      text-transform: uppercase;
      margin-bottom: 20px;
      position: relative;
      z-index: 1;
    }

    &-intro {
      display: flex;
      flex-direction: column;
      background-color: $whiteColor;

      @include breakpoint(768px) {
        flex-direction: row;
      }

      @include breakpoint(2000px) {
        justify-content: center;
      }

      .image {
        width: 100%;
        overflow: hidden;
        border-bottom: 5px solid $bluedarkColor;
        position: relative;
        background-size: cover;

        @include breakpoint(768px) {
          width: 40%;
          max-width: 1200px;
          min-height: 370px;
          border: 0;
        }

        @include breakpoint(980px) {
          width: 55%;

        }

        img {
          display: block;
          width: 100%;
          height: auto;

          @include breakpoint(768px) {
            width: 95%;
          }
        }
      }

      .contentText {
        padding: 40px 30px;
        position: relative;
        min-height: inherit;
        > div {
          position: relative;
          z-index: 1000;
        }

        @include breakpoint(phablet) {
          padding: 50px;
        }

        @include breakpoint(768px) {
          width: 60%;
          padding: 75px 30px 0;
          max-width: 620px;

          &:after {
            content: ' ';
            display: block;
            transform: skewX(-10deg);
            background-color: $whiteColor;
            border-left: 3px solid lighten($bluedarkColor, 10%);
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 0;
            top: 0;
            left: -7vw;
          }
        }

        @include breakpoint(980px) {
          width: 55%;
          padding: 70px 80px 35px 10px;
        }

        @include breakpoint(desktop) {
          width: 45%;
        }

        @include breakpoint(1440px) {
          padding: 60px 20px 40px;
        }

        ul {
          padding: 0;
          margin: 15px 0;
        }

        ul li {
          list-style-type: none;
          margin-bottom: 5px;
        }

        ul li:before {
          content: ' ';
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 500px;
          background: #00a0f6;
          margin-right: 10px;
        }

        ul li > ul {
          margin-left: 15px;
        }

        ul li > ul li:before {
          content: ' ';
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 500px;
          background: transparent;
          border: 1px solid #00a0f6;
          margin-right: 10px;
        }

        p {
          font-size: 16px;
          line-height: 1.4;
          margin-bottom: 10px;

          @include breakpoint(phablet) {
            width: 85%;
          }

          @include breakpoint(laptop) {
            width: 90%;
          }

          @include breakpoint(desktop) {
            width: 75%;
          }

          b {
            font-size: 18px;
          }
        }
      }
    }

    &-infos {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      max-width: 1500px;
      margin: auto;
      padding: 60px 5% 5px;

      @include breakpoint(phablet) {
        padding: 60px 0 25px 5px;
      }

      @include breakpoint(768px) {
        padding: 80px 5% 30px;
      }

      @include breakpoint(1060px) {
        flex-wrap: nowrap;
        padding: 90px 5% 50px;
      }

      @include breakpoint(1330px) {
        padding: 90px 130px 50px;
      }

      .subtitle {
        flex-basis: 100%;
        line-height: 1.5;
        text-align: center;
        margin-bottom: 30px;

        @include breakpoint(phablet) {
          margin-bottom: 50px;
        }

        @include breakpoint(tablet) {
          margin-bottom: 60px;
        }

        @include breakpoint(1060px) {
          max-width: 250px;
          padding-top: 10px;
          text-align: left;
          margin-bottom: 0;
        }
      }

      .step {
        max-width: 280px;
        position: relative;
        padding-left: 70px;
        margin: 0 10px 35px;

        @include breakpoint(tablet) {
          max-width: 300px;
        }

        &-number {
          display: table;
          width: 40px;
          height: 40px;
          background-color: $blueColor;
          border-radius: 1000px;
          font-size: 26px;
          font-weight: 500;
          color: $whiteColor;
          text-align: center;
          position: absolute;
          top: 25px;
          left: 0;

          span {
            display: block;
            line-height: 1.45;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            text-align: center;
          }
        }

        p {
          font-size: 14px;
          line-height: 1.5;
        }
      }
    }

    &-introbis {
      display: flex;
      flex-direction: column;
      margin: auto;
      padding: 50px 35px;
      max-width: 1400px;
      font-size: 15px;

      @include breakpoint(phablet) {
        padding: 60px 45px;
      }

      @include breakpoint(768px) {
        padding: 60px 85px;
      }

      @include breakpoint(laptop) {
        flex-direction: row;
        font-size: 17px;
        padding: 90px 85px;
      }

      img {
        margin: 0 0 15px;
        width: 100%;
        height: auto;
        border-radius: 10px;

        @include breakpoint(phablet) {
          width: auto;
          height: auto;
          margin: 0 0 20px;
        }

        @include breakpoint(laptop) {
          margin: 0 35px 0 0;
          max-width: 55%;
          height: auto;
        }
      }

      iframe {
        margin: auto;
        width: 100%;
        height: 165px;
        border-radius: 0;

        @include breakpoint(phablet) {
          height: 310px;
        }

        @include breakpoint(laptop) {
          margin: 0 0 0 55px;
          min-width: 45%;
          height: 314px;
          border-radius: 10px;
        }
      }

      p {
        margin: 20px;
      }
    }

    .btn-next {
      font-size: 14px;
      padding: 12px 60px 12px 30px;
      border-radius: 30px;
      background: url('/assets/front/images/icone-next.svg') 90% center / 20px 9px no-repeat;
      margin-bottom: 60px;

      @include breakpoint(1060px) {
        padding: 12px 70px 12px 40px;
      }

      &:hover {
        background: $bluedarkColor url('/assets/front/images/icone-next-blanc.svg') 93% center / 20px 9px no-repeat;
      }
    }
  }
