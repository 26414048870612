/* ==========================================================================
   Components / Excercice : Son statiques à écouter
   ========================================================================== */

  .audio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1400px;
    margin-top: 5px;
    margin-bottom: 35px;
    padding: 20px 25px;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      display: block;
      text-align: center;
    }

    @include breakpoint(phablet) {
      padding: 20px 45px;
    }

    @include breakpoint(desktop) {
      margin: 15px auto 35px;
    }

    &-elem {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 330px;
      margin: 10px 3px 20px;
      border-radius: 0 0 10px 10px;
      position: relative;
      overflow: hidden;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        display: block;
        margin: 10px auto 20px;
      }

      @include breakpoint(900px) {
        margin: 10px 15px 25px;

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          display: inline-block;
        }
      }

      @include breakpoint(1330px) {
        margin: 10px 20px 25px;
      }

      @include breakpoint(1400px) {
        margin: 10px 30px 25px;
      }

      .image {
        width: 100%;
        background: $greyColor;
        border-radius: 10px 10px 0 0;
        overflow: hidden;
        margin-bottom: 0;
        position: relative;
        cursor: pointer;

        &:before {
          content: ' ';
          display: block;
          width: 70px;
          height: 62px;
          background: url('/assets/front/images/picto-son.svg') center center/70px 62px no-repeat;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;

          @include breakpoint(tablet) {
            top: 0;
            width: 90px;
            height: 85px;
            background: url('/assets/front/images/picto-son.svg') center center/90px 82px no-repeat;
          }
        }

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          margin-bottom: 0;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            height: auto;
          }
        }
      }

      .audiocontrol {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        background: #fafafa;
        padding: 5px 3px;
        filter: brightness(0.95) hue-rotate(-10deg) contrast(105%);
        left: 0;
        bottom: 0;
        flex-grow: 1;

        label {
          display: block;
          text-align: left;
          font-family: $font-family-base;
          font-size: 17px;
          line-height: 1.1;
          font-weight: bold;
          color: $greyText;
          margin: 0 0 5px 15px;
          position: relative;
          top: 5px;
          z-index: 1;
        }
      }
    }
  }

  // Audio explication des exercices
  .audioexpli {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1400px;
    margin-top: 50px;
    margin-bottom: -10px;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      display: block;
      text-align: center;
    }

    @include breakpoint(phablet) {
      margin-top: 40px;
    }

    @include breakpoint(tablet) {
      margin: 30px 0 10px;
    }

    @include breakpoint(900px) {
      margin: 40px 0 30px;
    }

    @include breakpoint(laptop) {
      width: 260px;
      position: absolute;
      left: 40px;
      top: 18px;
      margin: 0;
    }

    @include breakpoint(desktop) {
      width: 290px;
      left: 50px;
    }

    @include breakpoint(xldesktop) {
      left: 10px;
    }

    &-elem {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 290px;
      border-radius: 80px;
      border: 1px solid $bluedarkColor;
      position: relative;
      overflow: hidden;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        display: block;
        margin: 10px auto 20px;
      }

      @include breakpoint(900px) {
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          display: inline-block;
        }
      }
    }

    .audiocontrol {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      left: 0;
      bottom: 0;
      flex-grow: 1;

      label {
        display: block;
        text-align: left;
        font-family: $font-family-base;
        font-size: 17px;
        line-height: 1.1;
        font-weight: bold;
        color: $greyText;
        margin: 0 0 5px 15px;
        position: relative;
        top: 5px;
        z-index: 1;
      }

      .audioControls {
        padding: 13px 20px 9px;

        &-playstop {
          background: url('/assets/front/images/icone-play.svg') center center/11px auto no-repeat;
        }

        &-duration {
          width: calc(100% - 100px);
          height: 7px;
          background: #cde7fd;
          border-radius: 20px;
          margin-top: 6px;

          .bar {
            height: 7px;
            background: $bluedarkColor;
            border-radius: 20px;
          }
        }

        &.play .audioControls-playstop {
          background: url('/assets/front/images/icone-pause.svg') center center/11px auto no-repeat;
        }
      }
    }
  }
