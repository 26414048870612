/* ==========================================================================
   Page / Le parcours Formation
   ========================================================================== */

  .course {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0;
    flex-grow: 1;
    background: $greyBackground;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      min-height: 100%;
    }

    &-infos {
      background-size: cover;
      background-position: center center;
      text-align: center;
      padding: 65px 20px 235px;
      position: relative;

      @include breakpoint(largedesktop) {
        padding: 75px 20px 235px;
      }

      &:before {
        content: ' ';
        display: block;
        width: 100%;
        height: 100%;
        //background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
      }

      .h1-title {
        font-size: 28px;
        font-weight: bold;
        color: $whiteColor;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
        position: relative;
        z-index: 1;
      }

      .h2-title {
        display: inline-block;
        font-family: $font-family-base;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
        font-size: 20px;
        font-weight: 600;
        text-transform: none;
        color: $whiteColor;
        position: relative;
        z-index: 1;
      }

      .infos {
        display: inline-block;
        position: relative;
        color: $whiteColor;
        font-weight: 600;

        span {
          font-family: $font-family-base;
          font-size: 14px;
          font-weight: 400;
          padding: 1px 7px 2px 6px;
          border: 1px solid $whiteColor;
          border-radius: 500px;
          margin-left: 5px;
          transition: all $duration $easeOutCubic;
          cursor: pointer;

          @include breakpoint(phablet) {
            margin-left: 10px;
          }

          &:hover {
            opacity: 0.6;
            transition: all $duration $easeOutCubic;

            & + .aide {
              opacity: 1.0;
              transform: translateX(0px);
              transition: all $duration $easeOutCubic;
            }
          }
        }

        .aide {
          opacity: 0;
          font-size: 14px;
          line-height: 1.4;
          width: 260px;
          text-align: left;
          font-weight: 300;
          padding: 15px;
          position: absolute;
          top: 46px;
          left: -205px;
          background-color: rgba(0, 0, 0, 0.8);
          transform: translateX(10px);
          transition: all $duration $easeOutCubic;
          z-index: 5;

          @include breakpoint(phablet) {
            left: -75px;
          }

          @include breakpoint(760px) {
            left: -95px;
          }

          @include breakpoint(tablet) {
            width: 220px;
            top: -25px;
            left: 55px;
          }

          @include breakpoint(boldpx) {
            width: 260px;
          }

          &:before {
            content: ' ';
            display: block;
            width: 12px;
            height: 15px;
            background: url('/assets/front/images/fleche-blockaide.svg') center center /11px 15px no-repeat;
            position: absolute;
            left: 215px;
            top: -13px;
            transform: rotate(90deg);

            @include breakpoint(phablet) {
              left: 110px;
            }

            @include breakpoint(tablet) {
              transform: rotate(0deg);
              left: -11px;
              top: 32px;
            }
          }
        }
      }

      p {
        font-size: 16px;
        color: $whiteColor;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
        position: relative;
        z-index: 0;
      }
    }

    &-back {
      font-size: 16px;
      font-weight: bold;
      color: $whiteColor;
      position: relative;
      right: 15px;
      bottom: 20px;
      z-index: 2;
      margin-bottom: 5px;

      @include breakpoint(largedesktop) {
        position: absolute;
        top: 35px;
        left: 5%;
        right: inherit;
      }

      @include breakpoint(xldesktop) {
        top: 65px;
      }

      a {
        color: $greyText;
        background: $whiteColor;
        border-radius: 80px;
        padding: 9px 20px 11px 15px;
        transition: all $durationSmall $easeOutCubic;

        &:before {
          content: "";
          display: inline-block;
          margin: 12px 10px 0;
          height: 10px;
          width: 10px;
          border-right: 2px solid $greyText;
          border-bottom: 2px solid $greyText;
          transform: rotateZ(135deg);
          position: relative;
          right: 0;
          transition: all $durationSmall $easeOutCubic;
        }

        &:hover {
          text-decoration: none;
          transition: all $durationSmall $easeOutCubic;

          &:before {
            position: relative;
            right: 2px;
            transition: all $durationSmall $easeOutCubic;
          }
        }
      }
    }

    &-modules {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 1400px;
      margin: auto auto -160px;
      position: relative;
      bottom: 200px;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        margin: auto;
      }

      @include breakpoint(768px) {
        bottom: 170px;
        margin: auto auto -100px;

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          margin: auto;
        }
      }
    }

    .module {
      width: 100%;
      margin: 0 30px 35px;
      padding-bottom: 10px;
      border-radius: 10px;
      background-color: $whiteColor;
      box-shadow: 0 0 10px 0 rgba(100,100,100,0.05);

      @include breakpoint(phablet) {
        width: 260px;
        margin: 0 15px 35px;
    }

      @include breakpoint(768px) {
        width: 275px;
        margin: 0 20px 35px;
      }

      @include breakpoint(desktop) {
        margin: 0 30px 35px;
      }

      &.validate {
        .module-state {
          background-color: darken($greenColor, 2%);

          &:after {
            background: url(/assets/front/images/icone-valide-module.svg) center center/30px 30px no-repeat;
          }

          .module-num, .module-title, .module-time {
            color: $whiteColor;
          }

          .module-time {
            &:before {
              background: url('/assets/front/images/icone-temps.svg') center center/20px 20px no-repeat;
            }
          }
        }

        .module-desc {
          color: $greyText!important;
        }
      }

      &.onprogress {
        .module-state {
          background-color: lighten($blueColor, 2%);

          &:after {
            background: url(/assets/front/images/icone-progress-module.svg) center center/30px 30px no-repeat;
          }

          .module-num, .module-title, .module-time {
            color: $whiteColor;
          }

          .module-time {
            &:before {
              background: url('/assets/front/images/icone-temps.svg') center center/20px 20px no-repeat;
            }
          }
        }

        .module-desc {
          color: $greyText!important;
        }
      }

      &.unvalidate {
        .module-state {
          background-color: darken($redColor, 3%);

          &:after {
            background: url(/assets/front/images/icone-invalid.svg) center center/30px 30px no-repeat;
          }

          .module-num, .module-title, .module-time {
            color: $whiteColor;
          }

          .module-time {
            &:before {
              background: url('/assets/front/imagesicone-temps.svg') center center/20px 20px no-repeat;
            }
          }
        }

        .module-desc {
          color: $greyText!important;
        }
      }

      &.tryagain {
        .module-state {
          background-color: darken($orangeColor, 5%);

          &:after {
            background: url(/assets/front/images/icone-alerte-module.svg) center center/30px 30px no-repeat;
          }

          .module-num, .module-title, .module-time {
            color: $whiteColor;
          }

          .module-time {
            &:before {
              background: url('/assets/front/images/icone-temps.svg') center center/20px 20px no-repeat;
            }
          }
        }

        .module-desc {
          color: $greyText!important;
        }
      }

      &-state {
        display: flex;
        flex-direction: column;
        padding: 25px 15px;
        background: darken($greyColor, 5%);
        border-radius: 10px 10px 0 0;
        text-align: center;
        position: relative;
        min-height: 160px;

        &:after {
          content: ' ';
          display: block;
          width: 30px;
          height: 30px;
          background: url(/assets/front/images/icone-lock-module.svg) center center/30px 30px no-repeat;
          position: absolute;
          margin: auto;
          right: 0;
          left: 0;
          bottom: -12px;
        }
      }

      &-num {
        color: darken($greyLightColor, 20%);;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.2;
        margin-bottom: 5px;
      }

      &-title {
        color: darken($greyLightColor, 20%);;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 23px;
        line-height: 1.1;
        flex-grow: 1;
      }

      &-time {
        color: darken($greyLightColor, 20%);;
        font-size: 14px;
        font-weight: bold;
        line-height: 1;
        position: relative;
        bottom: 10px;

        &:before {
          content: ' ';
          display: inline-block;
          width: 20px;
          height: 20px;
          background: url('/assets/front/images/icone-temps-module.svg') center center/20px 20px no-repeat;
          margin-right: 8px;
          position: relative;
          top: 5px;
        }
      }

      &-desc {
        font-size: 14px;
        line-height: 1.4;
        text-align: center;
        padding: 30px 35px 15px;
        color: $greyLightColor;
        margin-bottom: 10px;

        @include breakpoint(phablet) {
          min-height: 120px;
        }
      }

      &-link {
        display: block;
        text-align: center;
        color: $bluedarkColor;
        margin: auto;
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 0.13rem;
        margin-bottom: 12px;

        &:after {
          content: ' ';
          display: inline-block;
          width: 7px;
          height: 9px;
          margin-left: 10px;
          background: url(/assets/front/images/fleche-bleue.svg) center center/8px 9px no-repeat;
          position: relative;
          top: 1px;
          transition: all $duration $easeOutCubic;
        }

        &:hover {
          text-decoration: none;

          &:after {
            transform: translateX(3px);
            transition: all $duration $easeOutCubic;
          }
        }
      }

      .lock {
        display: block;
        margin: auto;
        text-align: center;
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 0.13rem;
        color: $greyText;
        margin-bottom: 20px;
      }

      .btn-next {
        margin-bottom: 10px;
      }
    }
  }