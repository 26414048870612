/* ==========================================================================
   Components / Navigation Pages
   ========================================================================== */

  .navPage {
    font-weight: 300;
    line-height: 1.2;
    color: $greyText;
    font-size: 16px;
    margin-bottom: 20px;

    @include breakpoint(tablet) {
      font-size: 14px;
    }

    ul li {
      list-style-type: none;
      display: inline-block;
      margin-right: 12px;

      &:last-child {
        font-weight: 500;

        &:after {
          display: none;
        }
      }

      &:after {
        content: ' ';
        display: inline-block;
        width: 4px;
        height: 8px;
        margin-left: 12px;
        background: url('/assets/front/images/icone-fleche.svg') center center/4px 8px no-repeat;
      }

      a {
        font-weight: 300;
        color: $greyText;

        &:hover {
          color: $blueColor;
          text-decoration: none;
          transition: all $durationSmall $easeInOutCirc;
        }
      }
    }
  }