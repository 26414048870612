
// Body
$body-bg: #f5f8fa;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Colors
$whiteColor     : #ffffff;
$darkColor      : #000000;
$greyColor      : #e4e4e4;
$greyBackground : #f0f3f5;
$blueColor      : #00a0f6;
$bluedarkColor  : #1f73fd;
$redColor       : #be2e2e;
$yellowColor    : #fae241;
$orangeColor    : #ff9f16;
$greenColor     : #3dce62;
$greyText       : #3d3d3d;
$greyLightColor : #999999;
$greyPushBg     : #e6e7e8;

// Typography
$font-family-base: "Pangram", sans-serif;
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 15px;
$line-height-base: 1.6;
$text-color: #636b6f;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

// Transitions
$durationSmall: .2s;
$duration: .6s;
$durationLong: 1.2s;
$easeInOutQuart : cubic-bezier(0.77, 0, 0.175, 1);
$easeOutCubic   : cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutCirc  : cubic-bezier(0.785, 0.135, 0.15, 0.86);
$easeInCirc     : cubic-bezier(0.6, 0.04, 0.98, 0.335);
