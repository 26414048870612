/* ==========================================================================
   Components / User Infos Manager Board
   ========================================================================== */

  .validation-state {
    font-size: 14px;
    white-space: nowrap;
    font-weight: bold;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      letter-spacing: -0.06rem;
    }
  }

  .valid {
    color: $greenColor;
    padding-left: 25px;
    background: url('/assets/front/images/icone-valide.svg') left center / 17px 17px no-repeat;
  }

  .progression {
    color: $blueColor;
    padding-left: 25px;
    background: url('/assets/front/images/icone-sablier.svg') left center / 14px 15px no-repeat;

  }

  .invalid {
    color: $redColor;
    padding-left: 25px;
    background: url('/assets/front/images/icone-invalid.svg') left center / 17px 17px no-repeat;
  }

  .none {
    color: $orangeColor;
    padding-left: 25px;
    background: url('/assets/front/images/icone-none.png') left center / 17px 17px no-repeat;
    font-size:14px
  }

  .date {
    font-weight: 500;
    font-size: 16px;
    color: $greyLightColor;
    white-space: nowrap;
    padding-left: 25px;
    background: url('/assets/front/images/picto-time.svg') left center / 18px 18px no-repeat;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      letter-spacing: -0.02rem;
    }

    @include breakpoint(760px) {
      font-size: 14px;
    }

    @include breakpoint(1100px) {
      font-size: 16px;
    }
  }