/* ==========================================================================
   Components / WYSIWYG
   ========================================================================== */

  .wysiwyg {
    font-size: 16px;
    line-height: 1.4;

    h1 {
      font-size: 18px;
      font-weight: bold;
      color: lighten($blueColor, 3%);
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    h2 {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 10px;
    }

    h3 {
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 10px;
      margin-top: 0;
      text-transform: uppercase;
    }

    p:not(:last-child) {
      margin-bottom: 20px;
    }

    ul {
      li {
        list-style-type: none;
        margin-bottom: 5px;

        > ul {
          margin-top: 5px;
          margin-left: 15px;

          li:before {
            content: ' ';
            display: inline-block;
            width: 7px;
            height: 7px;
            border-radius: 500px;
            background: transparent;
            border: 1px solid #00a0f6;
            margin-right: 10px;
            position: relative;
            bottom: 2px;
          }
        }

        &:before {
          content: ' ';
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 500px;
          background: $blueColor;
          margin-right: 10px;
        }
      }

      & + p {
        margin-top: 25px;
      }
    }
  }