/* ==========================================================================
   Base / Forms
   ========================================================================== */

  :-webkit-autofill {
    box-shadow: 0 0 0px 1000px $whiteColor inset;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input {
    display: block;
    width: 100%;
    padding: 12px 15px;
    font-size: 18px;
    margin-bottom: 15px;
    border: 1px solid lighten($greyLightColor, 10%);

    &:focus {
      outline-style: none;
      border: 1px solid darken($greyLightColor, 20%);
    }
  }

  label {
    font-family: $font-family-base;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 5px;
  }

  .forgetpswd {
    float: right;
    text-transform: uppercase;
    color: #767676;
    font-size: 11px;
    font-weight: 400;
    text-decoration: underline;
    transition: all $duration $easeInOutQuart;
    position: relative;
    right: 3px;

    &:hover {
      color: lighten($blueColor, 5%);
      transition: all $duration $easeInOutQuart;
    }
  }

  select {
    width: 100%;
    min-width: 200px;
    max-width: 330px;
    font-size: 13px;
    font-weight: 500;
    color: $bluedarkColor;
    letter-spacing: 0.13rem;
    border-radius: 20px;
    border: 1px solid $bluedarkColor;
    padding: 8px 25px 9px 20px;
    transition: all $duration $easeOutCubic;
    background: url('/assets/front/images/select-fleche.svg') 90% center/ 13px 7px no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus {
      outline-style: none;
    }
  }

  select::-ms-expand {
    display: none;
  }




