/* ==========================================================================
   Pages / Home Page (Login Page)
   ========================================================================== */

  .homelogin {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    min-height: 685px;
    padding-bottom: 55px;
    background-color: $greyBackground;

    @include breakpoint(phablet) {
      padding-bottom: 35px;
    }

    @include breakpoint(laptop) {
      padding-bottom: 0;
    }

    @media screen and (min-height: 710px) {
      min-height: 100vh;
    }

    &-bloc {
      display: table;
      width: 95%;
      max-width: 995px;
      margin: 10px auto;
      position: relative;

      @include breakpoint(phablet) {
        width: 90%;
        margin: 35px auto;
      }

      @include breakpoint(laptop) {
        bottom: 0;
        margin: auto;
      }
    }

    &-form {
      display: block;
      padding: 40px 35px;
      text-align: left;

      @include breakpoint(phablet) {
        padding: 50px;
      }

      @include breakpoint(tablet) {
        padding: 60px 70px;
      }

      @include breakpoint(laptop) {
        display: table-cell;
        width: 60%;
        padding: 60px 50px;
        border-bottom: 8px solid $blueColor;
      }

      @include breakpoint(desktop) {
        width: 61%;
        padding: 70px 74px 60px 85px;
      }

      .logo {
        max-width: 100%;
        margin-bottom: 20px;

        @include breakpoint(phablet) {
          margin-bottom: 35px;
        }

        @include breakpoint(desktop) {
          margin-bottom: 45px;
        }
      }

      .formContent {
        padding-left: 5px;
      }

      .form {
        label {
          font-weight: 400;
          font-size: 17px;
          color: #747474;
        }

        input {
          font-weight: 400;
          font-size: 15px;
          line-height: 1.4;
          color: $greyText;
          border: 1px solid #e4e4e4;
          padding: 15px 20px 15px 55px;
          border-radius: 0px;
          margin-bottom: 15px;
          transition: all $duration $easeInOutQuart;

          &:focus {
            border-color: $blueColor;
            transition: all $duration $easeInOutQuart;
          }
        }

        .emailField {
          position: relative;

          &:after {
            content: ' ';
            display: block;
            width: 20px;
            height: 20px;
            background: url('/assets/front/images/icone-identifiant.svg') center center/20px 20px no-repeat;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 20px;
            margin: auto 0;
          }
        }

        .passwordField {
          position: relative;

          &:after {
            content: ' ';
            display: block;
            width: 20px;
            height: 20px;
            background: url('/assets/front/images/icone-motpasse.svg') center center/20px 20px no-repeat;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 18px;
            margin: auto 0;
          }
        }

        :-webkit-autofill {
          box-shadow: 0 0 0 1000px $whiteColor inset;
        }

        ::placeholder {
          color: #c9c8c8;
          font-size: 14px;
          font-weight: 300;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            font-size: 15px;
          }
        }
      }
    }

    &-title {
      color: #7f7f7f;
      font-size: 18px;
      line-height: 1.1;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 30px;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        font-size: 17px;
        letter-spacing: -0.05rem;
      }

      span {
        color: #ba181d;
      }
    }

    &-sub-title {
      text-transform: uppercase;
      color: #1d1d1b;
      font-weight: 900;
    }

    &-access,&-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 100%;
      margin: 15px auto;

      a {
        &:hover {
          text-decoration: none;
        }
      }
      .choice-access,.choice-customer {
        display: flex;
        height: auto;
        flex-basis: 100%;
        margin: 0px 0 15px 0;
        background-color: #3D3D3D;
        padding: 10px 20px;
        align-items: center;
        border-radius: 15px;
        @include breakpoint(laptop) {
          flex-basis: 46%;
          margin: 0px 4% 15px 0;
        }
        //&:nth-child(4n) {
        //  margin-right: 0;
        //}
        &:hover {
          background-color: #1d1d1b;
        }
      }

      .icon-profil-customer {
        width: 85px;
        height: 85px;
        background-size:contain;
        background-repeat:no-repeat;
        display:inline-block;
        background-position: center;
      }

      .icon-profil-superviseur {
        background: url(/assets/front/images/icone-profil-superviseur.svg) center center/78px 78px no-repeat;
        width: 85px;
        height: 85px;
      }

      .icon-profil-manager {
        background: url(/assets/front/images/icone-profil-manager.svg) center center/78px 78px no-repeat;
        width: 85px;
        height: 85px;
      }

      .icon-profil-formateur {
        background: url(/assets/front/images/icone-profil-formateur.svg) center center/86px 68px no-repeat;
        width: 85px;
        height: 85px;
      }

      .icon-profil-apprenant {
        background: url(/assets/front/images/icone-profil-apprenant.svg) center center/62px 62px no-repeat;
        width: 85px;
        height: 85px;
      }

      .title-profil {
        padding: 0 25px;
        color: #ffffff;
        font-size: 18px;
        line-height: 1.1;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: none;
      }
    }

    &-intro {
      display: block;
      padding: 50px 44px 45px;
      background: $blueColor url('/assets/front/images/background-flamme-login.png') right bottom no-repeat;
      color: $whiteColor;
      position: relative;

      @include breakpoint(phablet) {
        padding: 60px 65px 35px;
      }

      @include breakpoint(tablet) {
        padding: 65px 73px 45px;
      }

      @include breakpoint(laptop) {
        display: table-cell;
        vertical-align: top;
        padding: 65px 50px;
      }

      @include breakpoint(desktop) {
        padding: 65px 60px;
      }

      .text {
        font-size: 14px;
        line-height: 1.4;
        margin-bottom: 45px;

        @include breakpoint(laptop) {
          margin-bottom: 0;
        }

        .h2-title span {
          color: #ba181d;
        }

        p {
          margin-bottom: 20px;
        }

        b {
          display: block;
          margin-top: 25px;
          font-size: 17px;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            font-size: 16px;
          }
        }
      }

      .contact {
        display: block;
        width: 100%;
        margin: auto;
        text-align: center;

        @include breakpoint(laptop) {
          position: absolute;
          bottom: 35px;
          left: 0;
        }

        a {
          @extend .btn-link;
          font-size: 13px;
          letter-spacing: 0;
          font-weight: 400;
          color: $whiteColor;

          &:hover {
            opacity: 0.8;
            color: $whiteColor;
          }

          &:before {
            display: inline-block;
            content: url('/assets/front/images/picto-lettre-blanche.svg');
            width: 19px;
            height: 13px;
            margin-right: 10px;
            position: relative;
            top: 2px;
          }
        }
      }
    }

    &-content {
      display: table;
      background: $whiteColor;
      box-shadow: -3px 5px 8px 0 rgba(0,0,0,0.1);
    }

    &-footer {
      color: #585858;
      letter-spacing: 0.08rem;
      text-transform: uppercase;
      position: relative;
      font-size: 12px;
      text-align: center;
      margin: auto;
      top: 25px;

      @include breakpoint(tablet) {
        text-align: right;
      }

      a {
        color: #585858;
        font-size: 12px;
        text-decoration: none;
        margin: 0 15px;
        transition: all $duration $easeInOutCirc;

        &:hover {
          color: lighten($blueColor, 5%);
          transition: all $duration $easeInOutCirc;
        }

        &:last-child {
          margin: 0 0 0 15px;
        }
      }
    }
  }