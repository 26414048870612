/* ==========================================================================
   Components / Excercice : Image map (cliquable)
   ========================================================================== */

  .imagemap {
    width: 85%;
    margin: 20px auto 25px;

    &.disabled {
      margin: 30px auto 65px;

      .imagemap-erase {
        display: none;
      }
      .imagemap-image {
        cursor: default;
        overflow: auto;
      }
    }

    @include breakpoint(768px) {
      width: 80%;
    }

    @include breakpoint(1200px) {
      width: 65%;
    }

    &-erase {
      font-size: 14px;
      font-weight: bold;
      color: lighten($blueColor, 5%);
      letter-spacing: 0.13rem;
      text-align: center;
      margin-bottom: 15px;
      cursor: pointer;
      transition: all $durationSmall $easeInOutCirc;

      @include breakpoint(phablet) {
        text-align: right;
      }

      &:hover {
        color: $bluedarkColor;
        transition: all $durationSmall $easeInOutCirc;

        &:after {
          background: url('/assets/front/images/picto-effacer-hover.svg') center center/20px 20px no-repeat;
          transition: all $durationSmall $easeInOutCirc;
        }
      }

      &:after {
        content: ' ';
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-left: 8px;
        background: url('/assets/front/images/picto-effacer.svg') center center/20px 20px no-repeat;
        position: relative;
        top: 5px;
        transition: all $durationSmall $easeInOutCirc;
      }
    }

    &-image {
      width: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 10px;
      //background: $darkColor;
      cursor: pointer;

      img {
        display: block;
        width: 100%;
        height: auto;
      }

      .pointer {
        display: block;
        width: 22px;
        height: 22px;
        margin-left: -11px;
        margin-top: -11px;
        border-radius: 100px;
        background: lighten($blueColor, 5%);
        position: absolute;

        &:before {
          display: none!important;
        }

        &:after {
          display: none!important;
        }

        @include breakpoint(phablet) {
          width: 32px;
          height: 32px;
          margin-left: -16px;
          margin-top: -16px;
        }

        @include breakpoint(768px) {
          &:before {
            display: block!important;
            line-height: 1.2;
            border-radius: 10px;
            padding: 10px;
            text-align: center;
            color: $whiteColor;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: bold;
            position: absolute;
            bottom: 50px;
            margin: auto;
          }

          &:after {
            display: block!important;
            content: ' ';
            width: 15px;
            height: 15px;
            transform: rotate(45deg);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 45px;
            margin: auto;
          }
        }

        @include breakpoint(laptop) {
          width: 42px;
          height: 42px;
          margin-left: -21px;
          margin-top: -21px;

          &:before {
            bottom: 60px;
          }

          &:after {
            bottom: 55px;
          }
        }

        &.selectValid {
          background: url("/assets/front/images/icone-selectbox-valid.svg") center center/contain no-repeat;

          &:before {
            content: 'Bien joué';
            background: $greenColor;
            width: 400%;
            left: -160%;
          }

          &:after {
            background: $greenColor;
          }
        }

        &.unselectValid {
          background: url("/assets/front/images/icone-selectbox-valid-v2.svg") center center/contain no-repeat;

          &:before {
            content: 'Bonne réponse';
            background: #eef2f3;
            width: 500%;
            color: $greenColor;
            left: -210%;
          }

          &:after {
            background: #eef2f3;
          }
        }

        &.selectError {
          background: url("/assets/front/images/icone-invalid.svg") center center/contain no-repeat;

          &:before {
            content: 'Mauvaise réponse';
            background: $redColor;
            width: 450%;
            left: -175%;
          }

          &:after {
            background: $redColor;
          }
        }
      }
    }

    &-result {
      text-align: center;
      width: 90%;
      margin: 25px auto;
    }
  }