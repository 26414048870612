/* ==========================================================================
   Layout / Footer
   ========================================================================== */

  .footer {
    display: table;
    width: 100%;
    text-align: right;
    background-color: $greyColor;
    padding: 10px 0;

    ul li {
      display: inline-block;
      font-family: $font-family-sans-serif;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;

      a {
        color: $greyText;
        transition: all $durationSmall linear;

        &:hover {
          color: $blueColor;
          transition: all $durationSmall linear;
          text-decoration: none;
        }
      }

      &:last-child {
        margin-right: 35px;

        &:after {
          display: none;
        }
      }

      &:after {
        content: ' ';
        display: inline-block;
        width: 1px;
        height: 10px;
        background-color: $greyText;
        margin: 0 15px;
      }
    }
  }