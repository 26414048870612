/* ==========================================================================
   Page / Learner HomePage
   ========================================================================== */

.learnerhome {
  padding: 0;

  @include breakpoint(tablet) {
    display: flex;
    flex-direction: row;
  }

  &-progress, .progressmobileonly {
    display: none;
    background-color: $greyText;
    box-shadow: 3px 0 8px 0 rgba(0,0,0,0.10);
    position: relative;
    z-index: 10;

    @include breakpoint(tablet) {
      width: 245px;
      padding-top: 15px;
    }

    @include breakpoint(desktop) {
      width: 285px;
    }

    &.progressmobileonly {
      display: block;

      @include breakpoint(tablet) {
        display: none;
      }
    }

    &.tabletmore {
      display: none;

      @include breakpoint(tablet) {
        display: block;
      }
    }

    .title {
      display: block;
      width: 100%;
      padding: 20px 30px 19px;
      border-bottom: 1px solid #565656;
      background: $greyText;
      color: $whiteColor;
      font-size: 18px;
      line-height: 1;
      text-transform: uppercase;
      margin: auto;
      position: relative;
      cursor: pointer;
      z-index: 1;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        font-size: 17px;
      }

      & + div {
        display: none;
        border-bottom: 1px solid #565656;
      }

      &.deroule {
        border: 0;

        &:after {
          transform: rotate(0deg);
          transition: all $durationSmall $easeInOutCirc;
        }
      }

      &:after {
        content: ' ';
        display: block;
        width: 15px;
        height: 9px;
        background: url('/assets/front/images/icone-fleche-blanc.svg') center center/15px 9px no-repeat;
        position: absolute;
        right: 22px;
        top: 28px;
        transform: rotate(-90deg);
        transition: all $durationSmall $easeInOutCirc;
      }

      @include breakpoint(tablet) {
        display: table;
        width: auto;
        padding: 15px;
        border: 0;
        text-align: center;
        margin: auto;
        cursor: default;

        & + div {
          display: block;
          border-bottom: 0;
        }

        &:after { display: none; }
      }
    }

    .level {
      padding-top: 40px;
      border-top: 1px solid $whiteColor;
      position: relative;
      text-align: center;
      bottom: 25px;
      z-index: 0;

      &-title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      &.initial { .level-title { color: $blueColor; } }
      &.intermediate {  .level-title { color: $yellowColor; } }
      &.advanced { .level-title { color: $orangeColor; } }
      &.expert { .level-title { color: #e62121; } }

      p {
        color: $whiteColor;
        font-size: 14px;
        line-height: 1;
      }
    }

    .badge {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 0 15px;
      position: relative;
      bottom: 10px;
      background: transparent;
      margin-bottom: 13px;

      @include breakpoint(tablet) {
        padding: 0 10px;
      }

      @include breakpoint(desktop) {
        padding: 0 15px;
      }

      li {
        list-style-type: none;
        display: inline-block;
        flex-basis: 25%;
        width: 45px;
        height: 70px;
        position: relative;
        margin-bottom: 15px;
        cursor: pointer;

        &.locked {
          .badge-bg {
            filter: contrast(95%) brightness(0.7);

            &:before {
              content: ' ';
              display: block;
              width: 19px;
              height: 28px;
              background: transparent url('/assets/front/images/icone-cadenas-blanc.svg') top center/ 19px 28px no-repeat;
              position: absolute;
              top: 28px;
              left: 0;
              right: 0;
              margin: auto;
              z-index: 2;

              @include breakpoint(tablet) {
                width: 17px;
                height: 26px;
                background: transparent url(/assets/front/images/icone-cadenas-blanc.svg) top center/17px 26px no-repeat;
                top: 26px;
              }

              @include breakpoint(desktop) {
                width: 19px;
                height: 28px;
                background: transparent url('/assets/front/images/icone-cadenas-blanc.svg') top center/ 19px 28px no-repeat;
                top: 28px;
              }

              @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { opacity: 0.4; }
            }

            &:after {
              content: ' ';
              display: block;
              width: 37px;
              height: 37px;
              border-radius: 500px;
              opacity: 0.65;
              background: $greyLightColor;
              position: absolute;
              top: 25px;
              left: 0;
              right: 0;
              margin: auto;
              z-index: 1;

              @include breakpoint(tablet) {
                width: 35px;
                height: 35px;
                top: 22px;
              }

              @include breakpoint(desktop) {
                width: 37px;
                height: 37px;
                top: 25px;
              }

              @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { opacity: 0.4; }
            }
          }
          .badge-name { filter:  grayscale(25%); }
          &.badge-first .badge-bg {  background-image: url('/assets/front/images/badge-premier-nb.svg');   }
          &.badge-fastest .badge-bg {  background-image: url('/assets/front/images/badge-rapidite-nb.svg');  }
          &.badge-perfect .badge-bg {  background-image: url('/assets/front/images/badge-parfait-nb.svg');  }
        }

        &:first-child, &:nth-child(5n) {
          .badge-name {
            left: 0;

            &:after {
              left: 20px;
              right: inherit;
            }
          }
        }

        &:hover {
          .badge-name {
            opacity: 1.0;
            transform: translateY(0);
            transition: all $duration $easeInOutQuart;
          }
        }
      }

      &-bg {
        width: 45px;
        height: 70px;
        margin: auto;
        background-color: transparent;
        background-position: top center;
        background-size: 45px 70px;
        background-repeat: no-repeat;

        @include breakpoint(tablet) {
          width: 40px;
          height: 65px;
          background-size: 40px 65px;
        }

        @include breakpoint(desktop) {
          width: 45px;
          height: 70px;
          background-size: 45px 70px;
        }
      }

      &-name {
        display: block;
        opacity: 0;
        transform: translateY(15px);
        width: 165px;
        position: absolute;
        top: -45%;
        left: -50%;
        color: $greyText;
        font-size: 11px;
        line-height: 1.2;
        font-weight: bold;
        text-align: center;
        white-space: pre-line;
        background: $whiteColor;
        border-radius: 5px;
        padding: 5px 20px;
        transition: all $duration $easeInOutQuart;
        z-index: 6;

        @include breakpoint(phablet) {
          left: -1%;
        }

        @include breakpoint(tablet) {
          left: -95%;
        }

        @include breakpoint(desktop) {
          left: -80%;
        }

        &:after {
          content: ' ';
          display: block;
          width: 22px;
          height: 10px;
          background: url('/assets/front/images/fleche-bas-toolitp.svg') center center/22px 10px no-repeat;
          position: absolute;
          left: 0;
          right: 0;
          bottom: -10px;
          margin: auto;
        }
      }

      &-num {
        display: block;
        width: 20px;
        height: 20px;
        background: $redColor;
        color: $whiteColor;
        font-weight: bold;
        font-size: 13px;
        border-radius: 500px;
        padding: 3px 2px;
        text-align: center;
        position: absolute;
        bottom: -5px;
        left: 0;
        right: 0;
        margin: auto;

        @include breakpoint(tablet) {
          bottom: 0;
        }

        @include breakpoint(desktop) {
          bottom: -5px;
        }
      }

      &-first .badge-bg {  background-image: url('/assets/front/images/badge-premier.svg'); @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { opacity: 0.4; }  }
      &-fastest .badge-bg {  background-image: url('/assets/front/images/badge-rapidite.svg'); @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { opacity: 0.4; } }
      &-perfect .badge-bg {  background-image: url('/assets/front/images/badge-parfait.svg');  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { opacity: 0.4; } }
      &-nature .badge-bg {  background-image: url('/assets/front/images/badge-nature.svg'); @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { opacity: 0.4; } }

      .cathidden {
        @include breakpoint(phablet) {
          display: inline;
        }

        @include breakpoint(tablet) {
          display: inherit;
        }
      }
    }

    .less {
      display: none;
      font-size: 12px;
      font-weight: 300;
      color: $whiteColor;
      opacity: 0.7;
      text-decoration: underline;
      text-align: center;
      position: relative;
      bottom: 20px;
      margin-bottom: 5px;
      transition: all $durationSmall $easeInOutCirc;
      cursor: pointer;

      @include breakpoint(tablet) {
        display: block;
      }

      &:hover {
        opacity: 1.0;
        transition: all $durationSmall $easeInOutCirc;

        &:after {
          top: 0px;
          transition: all $durationSmall $easeInOutCirc;
        }
      }

      &:after {
        content: ' ';
        display: inline-block;
        width: 17px;
        height: 12px;
        margin-left: 5px;
        transform: rotate(180deg);
        background: url('/assets/front/images/icone-fleche-blanche.svg') center center/ 10px 7px no-repeat;
        position: relative;
        top: 2px;
        transition: all $durationSmall $easeInOutCirc;
      }
    }

    .more {
      display: none;
      font-size: 12px;
      font-weight: 300;
      color: $whiteColor;
      opacity: 0.7;
      text-decoration: underline;
      text-align: center;
      position: relative;
      bottom: 20px;
      margin-bottom: 5px;
      transition: all $durationSmall $easeInOutCirc;
      cursor: pointer;

      @include breakpoint(tablet) {
        display: block;
      }

      &:hover {
        opacity: 1.0;
        transition: all $durationSmall $easeInOutCirc;

        &:after {
          top: 4px;
          transition: all $durationSmall $easeInOutCirc;
        }
      }

      &:after {
        content: ' ';
        display: inline-block;
        width: 17px;
        height: 12px;
        margin-left: 5px;
        background: url('/assets/front/images/icone-fleche-blanche.svg') center center/ 10px 7px no-repeat;
        position: relative;
        top: 2px;
        transition: all $durationSmall $easeInOutCirc;
      }
    }

    .messages {
      display: none;
      margin-bottom: 30px;

      @include breakpoint(tablet) {
        display: block;
      }

      a:hover {
        text-decoration: none;
      }

      li {
        list-style-type: none;
        padding: 15px 20px 7px 27px;
        border-bottom: 1px solid darken($greyBackground, 5%);
        transition: all $durationSmall $easeInOutCirc;

        @include breakpoint(800px) {
          padding: 15px 20px 7px 25px;
        }

        @include breakpoint(950px) {
          padding: 15px 20px 7px 27px;
        }

        &:hover {
          background-color: lighten($greyBackground, 3%);
          transition: all $durationSmall $easeInOutCirc;
        }
      }

      &-picto {
        display: inline-block;
        vertical-align: top;
        background: url('/assets/front/images/picto-messagerie.svg') center center/19px 13px no-repeat;
        width: 19px;
        height: 13px;
        margin-top: 15px;
        margin-right: 15px;
        position: relative;

        &.unread {
          &:after {
            display: block;
            content: ' ';
            width: 12px;
            height: 12px;
            background: $redColor;
            border-radius: 500px;
            border: 3px solid $whiteColor;
            position: absolute;
            top: 6px;
            left: -6px;
            margin: 0;

            @include breakpoint(tablet) {
              top: -5px;
            }
          }
        }
      }

      &-infos {
        font-size: 14px;
        max-width: 85%;
        display: inline-block;

        @include breakpoint(800px) {
          max-width: 130px;
          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            max-width: 110px;
          }
        }

        @include breakpoint(950px) {
          max-width: 140px;
          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            max-width: 130px;
          }
        }

        .expediteur {
          font-weight: 300;
          line-height: 0.7;
        }

        h3 {
          width: 100%;
          color: $greyText;
          text-decoration: none;
          line-height: 1.5;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          position: relative;
          bottom: 2px;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            font-weight: 300;
          }
        }
      }
    }

    .nounread {
      text-align: center;
      font-size: 13px;
      padding: 20px 0;
      margin-bottom: 20px;
      border-bottom: 1px solid darken($greyBackground, 5%);
    }

    .formers {
      display: block;
      width: 100%;
      max-width: 100%;
      overflow: hidden;
      position: relative;
      border-top: 1px solid $whiteColor;
      bottom: 25px;
      z-index: 0;

      &-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        left: 0;
        transition: all 1s $easeOutCubic;
        z-index: 0;
      }

      .former {
        width: 100vw;
        min-width: 100vw;
        color: $whiteColor;
        padding: 40px 40px 20px;
        text-align: center;

        @include breakpoint(tablet) {
          width: 245px;
          min-width: 245px;
        }

        @include breakpoint(desktop) {
          width: 285px;
          min-width: 285px;
        }

        &-img {
          width: 135px;
          height: 135px;
          border-radius: 500px;
          padding: 5px;
          margin: auto;
          border: 2px solid rgba(255, 255, 255, 0.5);
          margin-bottom: 10px;

          img {
            margin: auto;
            border-radius: 500px;
          }
        }

        &-name {
          font-weight: bold;
          font-size: 16px;
          line-height: 1.1;
          margin-bottom: 5px;
        }

        &-info {
          width: 90%;
          margin: auto;
          font-weight: 400;
          font-size: 13px;
          line-height: 1.5;
          opacity: 0.9;

          @include breakpoint(tablet) {
            width: 100%;
          }

          @include breakpoint(950px) {
            width: 90%;
          }
        }
      }

      .next {
        display: block;
        width: 15px;
        height: 12px;
        position: absolute;
        background: $whiteColor url(/assets/front/images/icone-fleche.svg) 6px 6px/14px 11px no-repeat;
        padding: 12px;
        border-radius: 800px;
        top: 0;
        bottom: 0;
        right: 20px;
        margin: auto;
        opacity: 0.6;
        transition: all $durationSmall $easeOutCubic;
        cursor: pointer;
      }
      .prev {
        display: none;
        width: 15px;
        height: 12px;
        position: absolute;
        background: $whiteColor url(/assets/front/images/icone-fleche.svg) 6px center/14px 11px no-repeat;
        padding: 12px;
        border-radius: 800px;
        transform: rotate(180deg);
        top: 0;
        bottom: 0;
        left: 20px;
        margin: auto;
        opacity: 0.6;
        transition: all $durationSmall $easeOutCubic;
        cursor: pointer;
      }

      .next:hover, .prev:hover {
        opacity: 1.0;
        transition: all $durationSmall $easeOutCubic;
      }
    }

    .infos {
      color: $whiteColor;
      text-align: center;
      font-size: 14px;
      line-height: 1.4;
      padding: 0 40px 20px;
      position: relative;
      bottom: 20px;
      //padding: 0 40px 60px;

      @include breakpoint(tablet) {
        padding: 0 25px 60px;
      }

      @include breakpoint(1040px) {
        padding: 0 40px 60px;
      }
    }

    .contact {
      text-align: center;
      margin-top: 25px;
      padding-bottom: 30px;
      position: relative;
      bottom: 20px;

      @include breakpoint(tablet) {
        margin-top: 0;
      }

      span {
        margin-bottom: 15px;
        font-size: 13px;

        @include breakpoint(tablet) {
          display: block;
        }
      }

      a {
        @extend .btn-link;
        color: $whiteColor;

        &:hover {
          opacity: 0.6;
          color: $whiteColor;
        }

        &:before {
          display: inline-block;
          content: url('/assets/front/images/picto-lettre-blanche.svg');
          width: 19px;
          height: 13px;
          margin-right: 10px;
          position: relative;
          top: 3px;
        }
      }
    }
  }

  &-training {
    display: flex;
    flex-direction: column;

    @include breakpoint(tablet) {
      flex-basis: calc(100% - 245px);
    }

    @include breakpoint(desktop) {
      flex-basis: calc(100% - 285px);
    }

    .header {
      &-text {
        background: $blueColor;
        color: $whiteColor;

        @include breakpoint(tablet) {
          flex-basis: 45%;
          padding: 70px 20px 70px 50px;
        }

        @include breakpoint(desktop) {
          flex-basis: 50%;
          padding: 90px 60px;
        }

        @include breakpoint(xldesktop) {
          padding: 90px 100px;
        }

        .h1-title {
          color: $whiteColor;
        }

        &:after {
          background: $blueColor;
        }
      }

      &-image {
        @include breakpoint(tablet) {
          flex-basis: 55%;
        }

        @include breakpoint(desktop) {
          flex-basis: 50%;
        }
      }
    }

    .contentTraining {
      flex-grow: 1;
      //background: $whiteColor url('/assets/front/images/background-flamme.svg') right bottom/auto 90% no-repeat;
      padding: 50px 35px;

      @include breakpoint(phablet) {
        padding: 50px 45px 50px 50px;
      }

      @include breakpoint(tablet) {
        padding: 50px 35px 50px 40px;
      }

      @include breakpoint(950px) {
        padding: 50px 35px 50px 55px;
      }

      @include breakpoint(1305px) {
        padding: 50px 65px;
      }
    }

    .title {
      color: $greyText;
      font-size: 25px;
      font-weight: bold;
      text-transform: uppercase;
      padding: 5px;
      margin-bottom: 15px;
    }

    .trainingApp {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-bottom: 15px;

      @include breakpoint(phablet) {
        flex-direction: row;
        margin-bottom: 30px;
      }

      &-elem {
        display: flex;
        flex-direction: column;
        background-color: $whiteColor;
        border-radius: 10px;
        margin-bottom: 30px;
        position: relative;
        text-align: center;
        box-shadow: 0 0 40px 15px rgba(0,0,0,0.07);
        min-height: 305px;

        @include breakpoint(phablet) {
          flex-basis: 46%;
        }

        @include breakpoint(1100px) {
          flex-basis: 31%;
        }

        @include breakpoint(1200px) {
          flex-basis: 30%;
        }

        @include breakpoint(1600px) {
          flex-basis: 20%;
        }

        &:not(:nth-child(2n)) {
          @include breakpoint(phablet) {
            margin-right: 30px;
          }

          @include breakpoint(1100px) {
            margin-right: 0px;
          }
        }

        &:not(:nth-child(3n)) {
          @include breakpoint(1100px) {
            margin-right: 25px;
          }

          @include breakpoint(1200px) {
            margin-right: 35px;
          }

          @include breakpoint(1305px) {
            margin-right: 45px;
          }

          @include breakpoint(1600px) {
            margin-right: 0;
          }
        }

        &:not(:nth-child(4n)) {
          @include breakpoint(1600px) {
            margin-right: 45px;
          }
        }

        &.ongoing {
          .trainingApp-state {
            background: $blueColor;
          }

          .trainingApp-img {
            opacity: 1.0;
            position: relative;

            &:after {
              display: none;
            }

            &:before {
              content: ' ';
              display: block;
              width: 37px;
              height: 37px;
              background: url('/assets/front/images/icone-liste.svg') center center / 37px 37px no-repeat;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              margin: auto;
              z-index: 5;
            }

            img {
              filter: grayscale(0%);
              opacity: 0.75;
            }
          }
        }

        &.ongoingfirst {
          .trainingApp-state {
            background: $blueColor;
          }

          .trainingApp-img {
            opacity: 1.0;
            position: relative;

            &:after {
              display: none;
            }

            &:before {
              content: ' ';
              display: block;
              width: 45px;
              height: 45px;
              background: url('/assets/front/images/icone-go.svg') center center / 45px 45px no-repeat;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              margin: auto;
              z-index: 5;
            }

            img {
              filter: grayscale(0%);
              opacity: 0.75;
            }
          }
        }

        &.validatewithtime, &.validatenotime {
          .trainingApp-state {
            background: $greenColor;
          }

          .trainingApp-img {
            opacity: 0.9;

            &:after {
              display: none;
            }

            &:before {
              content: ' ';
              width: 37px;
              height: 37px;
              background: url('/assets/front/images/icone-validepouce.svg') center center / 37px 37px no-repeat;
            }

            img {
              filter: grayscale(0%);
              opacity: 0.75;
            }
          }
        }

        &.validatenotime {
          img {
            filter: grayscale(80%)!important;
            opacity: 0.5!important;
          }
        }

        &.unvalidatenotime {
          .trainingApp-state {
            background: $redColor;
          }

          .trainingApp-img {
            opacity: 0.9;

            &:after {
              display: none;
            }

            &:before {
              content: ' ';
              width: 37px;
              height: 37px;
              background: url('/assets/front/images/icone-invalid-blanc.svg') center center / 37px 37px no-repeat;
            }

            img {
              filter: grayscale(80%);
              opacity: 0.5;
            }
          }
        }

        &.available {
          .trainingApp-state {
            background: $greyLightColor url('/assets/front/images/icone-cadenas-deverouille.svg') 20px 5px / 12px 18px no-repeat;
          }

          .trainingApp-img {
            opacity: 0.8;

            img {
              opacity: 0.8;
            }
          }
        }

        &.teaser {
          .trainingApp-state {
            background: darken($greyBackground, 10%);
          }

          .trainingApp-img {
            img {
              filter: grayscale(0%);
              opacity: 1.0;
            }

            &:before {
              width: 55px;
              height: 50px;
              background: url('/assets/front/images/icone-progression.svg') center center / 55px 50px no-repeat;
            }
          }

          .btn-next {
            color: $greyLightColor;
            border-color: $greyLightColor;
            background: url("/assets/front/images/icone-next-grey.svg") 85% center/20px 9px no-repeat;
          }
        }

        a:hover { text-decoration: none; }
      }

      &-img {
        width: 100%;
        height: 150px;
        background-color: $darkColor;
        opacity: 0.9;
        border-radius: 10px 10px 0 0;
        position: relative;
        overflow: hidden;

        @include breakpoint(phablet) {
          height: 120px;
        }

        &:before {
          content: ' ';
          display: block;
          width: 37px;
          height: 37px;
          background: url('/assets/front/images/icone-temps.svg') center center / 37px 37px no-repeat;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          z-index: 5;
        }

        &:after {
          content: ' ';
          display: block;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.3);
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          margin: auto;
          min-height: 150px;
          filter: grayscale(80%);
          opacity: 0.5;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            width: 100%;
            height: auto;
          }

          @include breakpoint(phablet) {
            min-height: 120px;
          }
        }
      }

      &-state {
        display: table;
        align-self: center;
        font-size: 13px;
        color: $whiteColor;
        letter-spacing: 0.075rem;
        width: auto;
        padding: 4px 30px 5px;
        border-radius: 100px;
        position: relative;
        left: 0;
        right: 0;
        top: -15px;
        margin: auto;
        background: $redColor;
      }

      &-title {
        margin-top: 10px;
        font-weight: bold;
        font-size: 18px;
        line-height: 1.2;
        padding: 3px 15%;

        @include breakpoint(1375px) {
          padding: 3px 26%;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            padding: 3px 23%;
          }
        }

        @include breakpoint(1400px) {
          padding: 3px 22%;
        }
      }

      &-level {
        flex-grow: 1;
        font-size: 14px;
        color: $greyLightColor;
        margin-bottom: 30px;
      }

      button[disabled] {
        align-self: center;
        opacity: 0.3;
        background: url('/assets/front/images/icone-next.svg') 85% center / 20px 9px no-repeat;

        &:hover {
          color: $bluedarkColor;
        }
      }
    }

    .information {
      display: table;
      background-color: $whiteColor;
      border-radius: 10px;
      margin-bottom: 30px;
      padding: 0;
      position: relative;
      max-width: 1350px;
      box-shadow: 0 0 40px 15px rgba(0,0,0,0.07);

      @include breakpoint(phablet) {
        width: 97%;
      }

      @include breakpoint(laptop) {
        width: 96%;
      }

      @include breakpoint(1100px) {
        width: 97.5%;
      }

      @include breakpoint(1300px) {
        width: 99%;
      }

      &-img {
        border-radius: 10px 10px 0 0;
        position: relative;
        overflow: hidden;

        @include breakpoint(phablet) {
          width: 35%;
          display: table-cell;
          vertical-align: top;
          border-radius: 10px 0 0 10px;
        }

        img {
          display: block;
          width: 100%;
          height: auto;
          min-height: 100%;

          @include breakpoint(phablet) {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            margin: auto;
            top: -2000px;
            left: -2000px;
            right: -2000px;
            bottom: -2000px;
          }
        }
      }

      &-content {
        padding: 30px 30px 40px;
        margin-right: 5%;

        @include breakpoint(phablet) {
          padding: 40px 30px;
          display: table-cell;
          vertical-align: middle;
          width: 65%;
          margin-right: 0;
        }

        @include breakpoint(950px) {
          padding: 50px;
        }

        @include breakpoint(desktop) {
          padding: 50px 80px;
        }

        @include breakpoint(largedesktop) {
          padding: 80px;
        }

        p {
          font-size: 18px;
          line-height: 1.2;
        }
      }
    }
  }
}
