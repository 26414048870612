/* ==========================================================================
   Components / Excercice : Drag & Drop
   ========================================================================== */

  .dragdrop {
    margin-top: 25px;
    margin-bottom: 55px;
    padding: 0 25px;
    text-align: center;
    position: relative;

    @include breakpoint(760px) {
      display: flex;
      justify-content: center;
    }

    @include breakpoint(desktop) {
      margin: 35px auto 55px;
    }

    @include breakpoint(1300px) {
      margin: 55px auto 55px;
    }

    &.noimage {
      .dragdrop-answer {
        @include breakpoint(tablet) {
          width: 45%;
          margin-right: 0;
        }

        @include breakpoint(830px) {
          width: 40%;
          margin-right: 5%;
        }

        @include breakpoint(1240px) {
          width: 325px;
          margin-right: 12%;
          margin-left: 1%;
        }

        @include breakpoint(1400px) {
          margin-right: 20%;
        }
      }

      .dragdrop-contain {
        .number {
          position: absolute;
          top: calc(50% - 15px);
          left: -12px;
          width: 30px;
          height: 30px;
          font-size: 16px;
          padding: 2px 0;

          @include breakpoint(phablet) {
            top: calc(50% - 27px);
          }
        }

        @include breakpoint(tablet) {
          width: 55%;
          margin-left: -25px;
          margin-right: 0;

          .number {
            position: relative;
            top: 0;
            left: 35px;
            width: 38px;
            height: 30px;
          }
        }

        @include breakpoint(760px) {
          margin-left: 9%;
          width: 55%;
        }

        @include breakpoint(830px) {
          width: 50%;
          margin-left: 0;
          margin-right: 0;

          .number {
            left: 5px;
            padding: 2px 11px;
          }
        }

        @include breakpoint(930px) {
          width: 48%;

          .number {
            position: relative;
            left: 0;
            width: 40px;
            height: 40px;
            font-size: 18px;
            padding: 6px 14px;
          }
        }

        @include breakpoint(1240px) {
          width: 510px;
        }
      }

      .dragelem {
        border-radius: 5px;
        cursor: move;
        width: 100%;
        max-width: 100%;

        @include breakpoint(1200px) {
          max-width: 430px;
          min-width: 430px;
        }

        label {
          cursor: move;
          text-align: left;
          padding: 10px 20px;

          span {
            display: block;
            line-height: 1.3;
            margin-right: 15%;

            @include breakpoint(1200px) {
              margin-right: 10%;
            }
          }
        }
      }
      .dragelemcontain {
        border-radius: 5px;
        height: 60px;
        width: 100%;
        max-width: 100%;
        margin-bottom: 25px;

        @include breakpoint(phablet) {
          height: 50px;
        }

        @include breakpoint(1200px) {
          margin-bottom: 25px;
          max-width: 430px;
          min-width: 430px;
        }
      }
    }

    &-answer {
      width: 100%;
      margin-bottom: 25px;
      border-bottom: 2px solid $blueColor;

      @include breakpoint(760px) {
        width: 55%;
        border: 0px;
        margin-right: -5%;
      }

      @include breakpoint(830px) {
        width: 325px;
        margin-right: 2%;
      }

      @include breakpoint(930px) {
        margin-right: 6%;
      }

      @include breakpoint(1130px) {
        margin-right: 10%;
      }
    }

    &-contain {
      width: 100%;
      position: relative;

      @include breakpoint(760px) {
        width: 65%;
      }

      @include breakpoint(830px) {
        width: 400px;

      }

      @include breakpoint(desktop) {
        margin-left: 2%;
      }
    }

    .dragelem {
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 1px 2px 5px 1px rgba(0,0,0,0.2);
      margin-bottom: 25px;
      position: relative;
      z-index: 2;
      cursor: move;

      @include breakpoint(830px) {
        max-width: 325px;
        min-width: 325px;
      }

      &.nolabel {
        .image {
          min-height: 210px;
        }

        label {
          position: absolute;
          background: transparent;
          bottom: 0;
          z-index: 2;
        }
      }

      .image {
        width: 100%;
        height: 170px;
        margin-bottom: 0;

        img {
          display: block;
          width: 100%;
          height: 100%;
          min-height: 170px;
          border-radius: 0;
          object-fit: cover;
          margin-bottom: 0;
        }
      }

      label {
        display: block;
        width: 100%;
        min-height: 40px;
        padding: 12px 10px;
        padding-right: 50px;
        font-family: $font-family-base;
        font-size: 16px;
        line-height: 1.1;
        font-weight: bold;
        color: $greyText;
        background: $greyBackground;
        text-align: center;
        margin-bottom: 0;
        position: relative;

        &:after {
          content: ' ';
          display:block;
          width: 48px;
          height: 100%;
          background: lighten($blueColor, 5%) url('/assets/front/images/picto-drag.svg') center center/25px 25px no-repeat;
          position: absolute;
          right: 0;
          top: 0;
        }
      }

      &.selectedItem {
        &:before {
          content: ' ';
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 10px;
          box-shadow: inset 0 0 0 5px $blueColor;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }
      }
    }

    .dragdropcontain {
      position: relative;

      @include breakpoint(phablet) {
        display: flex;
        align-items: baseline;
      }

      @include breakpoint(1300px) {
        position: static;
      }

      .number {
        display: table;
        width: 40px;
        height: 40px;
        font-size: 18px;
        font-weight: bold;
        color: $greyText;
        text-align: center;
        padding: 6px 0;
        border-radius: 500px;
        background: darken($greyColor, 3%);
        margin-right: 25px;
        position: relative;
        bottom: 70px;
        left: 75px;
        z-index: 5;

        @include breakpoint(mobileonly) {
          position: absolute;
          top: 15px;
          left: 15px;
          bottom: inherit;
        }

        @include breakpoint(phablet) {
          padding: 6px 13px 6px 14px;
        }

        @include breakpoint(830px) {
          position: inherit;
          left: 0;
          top: 0;
        }
      }
    }

    .dragelemcontain {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 210px;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;
      color: lighten($greyLightColor, 5%);
      border-radius: 10px;
      border: 2px dashed $greyColor;
      margin-bottom: 25px;
      position: relative;

      @include breakpoint(830px) {
        max-width: 325px;
        min-width: 325px;
      }

      &.hovered {
        border: 2px dashed $blueColor;
      }
    }

    & + .btn-restart {
      position: relative;
      bottom: 25px;

      @include breakpoint(phablet) {
        bottom: 55px;
      }

      @include breakpoint(760px) {
        bottom: 40px;
      }

      @include breakpoint(laptop) {
        bottom: 40px;
      }

      @include breakpoint(1300px) {
        display: none;
      }
    }
  }

  // Affichage des résultats
  .dragdropresult {
    padding: 30px 25px;

    @include breakpoint(phablet) {
      width: 80%;
      margin: auto;
    }

    @include breakpoint(768px) {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 50px 45px;
    }

    .h3-title {
      font-weight: bold;
      color: $greenColor;
    }

    &-answer {
      text-align: center;
      border-bottom: 2px solid $blueColor;
      margin-bottom: 25px;

      @include breakpoint(768px) {
        flex-basis: 310px;
        border: 0;
        margin-right: 30px;
      }

      @include breakpoint(860px) {
        flex-basis: 325px;
        margin-right: 35px;

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          flex-basis: 560px;
        }
      }

      @include breakpoint(largedesktop) {
        margin-left: 85px;
      }

      @include breakpoint(xldesktop) {
        margin-left: 8%;
        margin-right: 75px;

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          margin-left: 15%;
        }
      }

      .h3-title {
        margin-bottom: 15px;
      }
    }

    &-responses {
      text-align: center;

      @include breakpoint(768px) {
        text-align: left;
        flex-basis: 310px;
      }

      @include breakpoint(860px) {
        flex-basis: 325px;
        margin-right: 25px;
      }

      @include breakpoint(laptop) {
        text-align: left;
        position: relative;
        left: 7%;
      }

      @include breakpoint(largedesktop) {
        flex-basis: 53%;
        left: 3%;

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          flex-basis: 75%;
        }
      }

      @include breakpoint(xldesktop) {
        left: 0;
      }

      .h3-title {
        margin-bottom: 15px;

        @include breakpoint(768px) {
          margin-left: 110px;
        }
      }

      .elemreponse {
        @include breakpoint(largedesktop) {
          display: flex;
          align-items: center;
        }
      }

      .detailsresponse {
        margin: 0 10px;
        font-size: 16px;
        line-height: 1.4;
        padding-bottom: 30px;

        @include breakpoint(768px) {
          flex-basis: 45%;
          margin-left: 25px;
        }

        @include breakpoint(largedesktop) {
          flex-basis: 35%;
        }

        @include breakpoint(xldesktop) {
          flex-basis: 45%;
        }
      }
    }

    .dragelem {
      width: 100%;
      border-radius: 10px;
      margin-bottom: 25px;
      position: relative;
      z-index: 2;

      @include breakpoint(860px) {
        max-width: 325px;
        min-width: 325px;
      }

      &:before {
        content: attr(data-id);
        display: block;
        width: 40px;
        height: 40px;
        font-size: 18px;
        font-weight: bold;
        color: $greyText;
        text-align: center;
        padding: 6px 0;
        border-radius: 500px;
        background: darken($greyColor, 3%);
        position: absolute;
        top: 15px;
        left: 10px;
        margin: auto;
        z-index: 5;

        @include breakpoint(laptop) {
          position: absolute;
          top: 0;
          bottom: 0;
          left: -65px;
          margin: auto;
        }
      }

      .image {
        width: 100%;
        height: 170px;
        margin-bottom: 0;
        border-radius: 10px 10px 0 0;
        overflow: hidden;

        @include breakpoint(phablet) {
          height: 200px;
        }

        @include breakpoint(768px) {
          height: 170px;
        }

        img {
          display: block;
          width: 100%;
          height: 100%;
          min-height: 170px;
          border-radius: 0;
          object-fit: cover;
          margin-bottom: 0;
        }
      }

      label {
        display: block;
        width: 100%;
        padding: 12px 10px;
        font-family: $font-family-base;
        font-size: 16px;
        line-height: 1.1;
        font-weight: bold;
        color: $greyText;
        background: $greyBackground;
        text-align: center;
        margin-bottom: 0;
        position: relative;
        border-radius: 0 0 10px 10px;
      }

      &.nolabel {
        .image {
          min-height: 210px;
          border-radius: 10px;
        }

        label {
          position: absolute;
          background: transparent;
          bottom: 0;
          z-index: 2;
        }
      }
    }

    .response {
      label {
        background: $greenColor;
        color: $whiteColor;
        padding: 12px 10px;
        line-height: 1.1;
      }
      &:before {
        content: attr(data-id);
        display: block;
        width: 40px;
        height: 40px;
        font-size: 18px;
        font-weight: bold;
        color: $greyText;
        text-align: center;
        padding: 6px 0;
        border-radius: 500px;
        background: darken($greyColor, 3%);
        position: absolute;
        top: 15px;
        left: 10px;
        margin: auto;
        z-index: 5;

        @include breakpoint(laptop) {
          position: absolute;
          top: 0;
          bottom: 0;
          left: -65px;
          margin: auto;
        }

        @include breakpoint(largedesktop) {
          display: none;
        }
      }
    }

    .selectValid {
      &:after {
        content: ' ';
        display: block;
        width: 42px;
        height: 42px;
        border-radius: 100px;
        position: absolute;
        top: 10px;
        right: 10px;
        background: url(/assets/front/images/icone-selectbox-valid.svg) center center/42px 42px no-repeat;
      }
    }

    .selectError {
      &:after {
        content: ' ';
        display: block;
        width: 42px;
        height: 42px;
        border-radius: 100px;
        position: absolute;
        top: 10px;
        right: 10px;
        background: url("/assets/front/images/icone-invalid.svg") center center/42px 42px no-repeat;
      }
    }

    &.noimage {
      padding: 10px 25px;

      @include breakpoint(phablet) {
        padding: 30px 25px;
      }

      @include breakpoint(1200px) {
        width: 85%;
      }

      .dragdropresult-answer {

        @include breakpoint(860px) {
          margin-right: 35px;
        }

        @include breakpoint(largedesktop) {
          margin-left: 60px;
          margin-right: 75px;
        }

        @include breakpoint(xldesktop) {
          margin-left: 8%;
          margin-right: 115px;
        }
      }

      .dragdropresult-responses {
        @include breakpoint(860px) {
          margin-right: 0;
        }

        @include breakpoint(laptop) {
          text-align: left;
          position: relative;
          left: 7%;
        }

        @include breakpoint(largedesktop) {
          flex-basis: 53%;
          left: 3%;
        }

        @include breakpoint(xldesktop) {
          left: 0;
        }
      }

      .dragdrop-answer {
        @include breakpoint(phablet) {
          width: 45%;
          margin-right: 0;
        }

        @include breakpoint(830px) {
          width: 40%;
          margin-right: 5%;
        }

        @include breakpoint(1240px) {
          width: 325px;
          margin-right: 12%;
          margin-left: 1%;
        }

        @include breakpoint(1400px) {
          margin-right: 20%;
        }
      }

      .dragdrop-contain {
        .number {
          position: absolute;
          top: 5px;
          left: 7px;
          width: 30px;
          height: 30px;
          font-size: 16px;
          padding: 2px 0;
        }

        @include breakpoint(phablet) {
          width: 55%;
          margin-left: -25px;
          margin-right: 0;

          .number {
            position: relative;
            top: 0;
            left: 60px;
          }
        }

        @include breakpoint(830px) {
          width: 50%;
          margin-left: 0;
          margin-right: 0;

          .number {
            left: 45px;
          }
        }

        @include breakpoint(930px) {
          width: 48%;

          .number {
            position: relative;
            left: 0;
            width: 40px;
            height: 40px;
            font-size: 18px;
            padding: 6px 0;
          }
        }

        @include breakpoint(1240px) {
          width: 510px;
        }
      }

      .dragelem {
        border-radius: 5px;
        cursor: move;
        width: 100%;
        max-width: 100%;

        @include breakpoint(phablet) {
          padding-left: 55px;
        }

        @include breakpoint(768px) {
          padding-left: 0;
        }

        @include breakpoint(1200px) {
          max-width: 430px;
          min-width: 430px;
        }

        label {
          cursor: move;
          text-align: left;
          padding: 10px 45px 10px 50px;
          border-radius: 5px;

          @include breakpoint(phablet) {
            padding: 10px 45px 10px 20px;
          }

          span {
            display: block;
            line-height: 1.3;
          }
        }

        &:before {
          display: block;
          top: 0;
          bottom: 0;
          left: 5px;
          width: 35px;
          height: 35px;
          font-size: 16px;
          padding: 5px 0;

          @include breakpoint(phablet) {
            left: 0;
          }

          @include breakpoint(768px) {
            display: none;
            width: 40px;
            height: 40px;
            font-size: 18px;
            left: -65px;
            padding: 6px 0;
          }

          @include breakpoint(1020px) {
            display: block;
          }
        }
      }

      .dragelemcontain {
        border-radius: 5px;
        height: 60px;
        width: 100%;
        max-width: 100%;
        margin-bottom: 25px;

        @include breakpoint(phablet) {
          height: 80px;
        }

        @include breakpoint(1200px) {
          height: 50px;
          margin-bottom: 25px;
          max-width: 430px;
          min-width: 430px;
        }
      }

      .dragdropresult-responses .elemreponse {
        display: block;
      }

      .dragdropresult-responses .h3-title {
        margin-left: 0;
        text-align: center;
        max-width: 430px;
      }

      .selectValid:after {
        width: 25px;
        height: 25px;
        top: 0;
        bottom: 0;
        right: 13px;
        margin: auto;
        background: url(/assets/front/images/icone-selectbox-valid.svg) center center/25px 25px no-repeat;
      }

      .selectError:after {
        width: 25px;
        height: 25px;
        top: 0;
        bottom: 0;
        right: 13px;
        margin: auto;
        background: url(/assets/front/images/icone-invalid.svg) center center/25px 25px no-repeat;
      }

      .response {
        & + .detailsresponse {
          font-size: 14px;
          line-height: 1.2;
          text-align: left;
          position: relative;
          max-width: 440px;
          bottom: 15px;
          margin: 0;
          padding: 0;
        }

        &:before {
          display: block;
          top: 0;
          bottom: 0;
          left: 5px;
          width: 35px;
          height: 35px;
          font-size: 16px;
          padding: 5px 0;

          @include breakpoint(phablet) {
            left: 0;
          }

          @include breakpoint(768px) {
            display: none;
            width: 40px;
            height: 40px;
            font-size: 18px;
            left: -65px;
            padding: 6px 0;
          }

          @include breakpoint(1020px) {
            display: block;
          }
        }

        label {
          padding: 10px 15px 10px 50px;

          @include breakpoint(phablet) {
            padding: 10px 20px;
          }
        }
      }
    }
  }