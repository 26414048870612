
// Fonts
@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,700,800,900");
@import url("https://fonts.googleapis.com/css?family=Titillium+Web:400,700");

// Base
@import "base/variables";
@import "base/mixins";
@import "base/base";
@import "base/fonts";
@import "base/titles";
@import "base/form";
@import "base/button";

// Components
@import "components/navpage";
@import "components/pagenav";
@import "components/userinfos";
@import "components/wysiwyg";
@import "components/multiple-choice";
@import "components/multiple-noimg";
@import "components/dragdrop";
@import "components/imagemap";
@import "components/clickcontent";
@import "components/audio";
@import "components/truefalse";
@import "components/modaltry";
@import "components/resulteval";

// Layout
@import "layout/navigation";
@import "layout/header";
@import "layout/training";
@import "layout/excercice";
@import "layout/footer";

// Pages
@import "pages/home";
@import "pages/training-details";
@import "pages/user-details";
@import "pages/learner-home";
@import "pages/training-home";
@import "pages/course";
@import "pages/messagerie";

// Bootstrap
@import "~bootstrap/dist/css/bootstrap.min.css";
