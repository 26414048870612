/* ==========================================================================
   Components / Pages Tabs Navigation
   ========================================================================== */

  .pagenav {
    display: block;
    text-align: center;
    padding: 25px 0 0px;

    ul li {
      display: inline-block;
      font-size: 18px;
      line-height: 15px;
      list-style-type: none;
      padding: 0 6px 0 2px;
      border-right: 1px solid lighten($greyLightColor, 10%);

      &.current {
        font-weight: bold;
      }

      &.prev {
        display: block;
        border: 0px;
        margin-right: 25px;
        margin-bottom: 15px;

        @include breakpoint(phablet) {
          display: inline-block;
          margin-bottom: 0px;
        }

        &:before {
          content: ' ';
          display: inline-block;
          background: url('/assets/front/images/icone-fleche.svg') center center/10px 15px no-repeat;
          width: 15px;
          height: 15px;
          margin-right: 12px;
          position: relative;
          top: 2px;
          right: 0;
          opacity: 0.5;
          transform: rotate(180deg);
          transition: all $durationSmall $easeInOutCirc;
        }

        &:hover {
          &:before {
            right: 3px;
            transition: all $durationSmall $easeInOutCirc;
          }
        }
      }

      &.next {
        display: block;
        border: 0px;
        margin-left: 25px;
        margin-top: 15px;

        @include breakpoint(phablet) {
          display: inline-block;
          margin-top: 0px;
        }

        &:after {
          content: ' ';
          display: inline-block;
          background: url('/assets/front/images/icone-fleche.svg') center center/10px 15px no-repeat;
          width: 15px;
          height: 15px;
          margin-left: 12px;
          position: relative;
          top: 2px;
          left: 0;
          opacity: 0.5;
          transition: all $durationSmall $easeInOutCirc;
        }

        &:hover {
          &:after {
            left: 3px;
            transition: all $durationSmall $easeInOutCirc;
          }
        }
      }

      a {
        color: lighten($greyLightColor, 10%);
        transition: all $durationSmall $easeInOutCirc;

        &:hover {
          color: $blueColor;
          text-decoration: none;
          transition: all $durationSmall $easeInOutCirc;
        }
      }
    }
  }