/* ==========================================================================
   Page / Messagerie Apprenant
   ========================================================================== */

  .messagerie {
    .wrapper {
      display: block;
    }

    .pagetitle {
      display: block;
      position: relative;
      padding-left: 25px;
      padding-top: 7px;
      margin: 12px 10px 25px;

      @include breakpoint(phablet) {
        display: inline-block;
        margin: 12px 10px 45px;
        padding-left: 65px;
      }

      .title {
        font-family: $font-family-base;
        color: $greyText;
        font-size: 28px;
        font-weight: bold;
        text-transform: uppercase;
        position: relative;
        margin-left: 40px;

        &:after {
          display: block;
          content: ' ';
          position: absolute;
          bottom: 0;
          width: 1px;
          height: 65px;
          top: 2%;
          margin: auto;
          background-color: darken($greyBackground, 5%);
          left: -25px;

          @include breakpoint(phablet) {
            height: 75px;
            left: -40px;
          }
        }
      }

      .subtitle {
        margin-top: 5px;
        margin-left: 44px;
        text-transform: uppercase;
        font-size: 15px;
      }
    }

    .backArrow {
      display: inline-block;
      position: absolute;
      top: 15px;
      left: 0;
      width: 16px;
      height: 16px;
      transform: rotateZ(135deg);
      border-right: 3px solid $greyText;
      border-bottom: 3px solid $greyText;
      text-indent: -9999px;
      transition: all $duration $easeInOutCirc;

      &:hover {
        left: -5px;
        transition: all $duration $easeInOutCirc;
      }
    }

    .newmessage {
      display: block;
      text-align: center;
      margin: auto auto 45px;
      position: relative;
      top: 15px;

      @include breakpoint(phablet) {
        display: inline-block;
        float: right;
        margin: 0 25px 25px;
      }

      .btn {
        padding: 15px 25px;
        border-radius: 30px;
      }
    }

    .sendMessages {
      width: 95%;
      margin: auto;

      .row {
        max-width: 100%;
        margin: auto auto 25px;

        textarea {
          height: 200px;
        }
      }

      .confirm {
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 35px;
      }
    }

    .messages {
      display: table;
      width: 100%;
      margin: auto;
      max-width: 1300px;

      a {
        color: $greyText;
      }

      a:hover {
        text-decoration: none;
      }

      &-elem {
        padding: 30px 15px 20px 25px;
        background: $whiteColor url('/assets/front/images/icone-message.svg') 30px 35px/34px 31px no-repeat;
        border-radius: 10px;
        border: 1px solid #dddddd;
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.09);
        margin-bottom: 20px;
        position: relative;
        transition: all $durationSmall $easeInOutCirc;

        @include breakpoint(phablet) {
          padding: 30px 15px 20px 35px;
        }

        @include breakpoint(768px) {
          display: flex;
          flex-wrap: wrap;
          background: $whiteColor url('/assets/front/images/icone-message.svg') 40px 35px/34px 31px no-repeat;
          padding: 30px 25px 30px 55px;
        }

        @include breakpoint(laptop) {
          align-items: center;
          justify-content: space-between;
          padding: 25px 25px 25px 45px;
        }

        @include breakpoint(desktop) {
          flex-wrap: nowrap;
          justify-content: baseline;
        }

        &:hover {
          border: 1px solid lighten($blueColor,8%);
          transition: all $durationSmall $easeInOutCirc;

          &:after {
            @include breakpoint(largedesktop) {
              right: 2.5%;
            }
          }
        }

        &:after {
          content: url('/assets/front/images/fleche-bleue.svg');
          width: 12px;
          height: 23px;
          display: flex;
          transform: rotate(90deg);
          transform-origin: center;
          margin: auto;
          transition: all $duration ease-in-out;

          @include breakpoint(768px) {
            transform: rotate(0deg);
            top: 0;
            bottom: 0;
            right: 5%;
            position: absolute;
          }

          @include breakpoint(desktop) {
            right: 3%;
          }
        }

        &.unread {
          background-image: url('/assets/front/images/icone-message-unread.svg');
          background-position: 24px 35px;
          background-size: 45px 26px;

          @include breakpoint(768px) {
            background-position: 35px 35px;
          }
        }
      }

      &-sender {
        margin-bottom: 20px;
        padding-left: 60px;

        @include breakpoint(768px) {
          flex-basis: 100%;
        }

        @include breakpoint(laptop) {
          flex-basis: 45%;
        }

        @include breakpoint(desktop) {
          flex-basis: 35%;
          margin-bottom: 0;
        }

        @include breakpoint(xldesktop) {
          flex-basis: 40%;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            flex-basis: 30%;
          }
        }

        .former {
          font-size: 20px;
          line-height: 1.2;
          text-transform: uppercase;
          margin-bottom: 3px;
        }

        .trainingname {
          font-size: 15px;
          color: lighten($blueColor, 10%);
        }
      }

      &-date {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        color: $greyLightColor;
        margin-bottom: 15px;

        @include breakpoint(phablet) {
          text-align: left;
          padding-left: 60px;
          margin-bottom: 10px;
        }

        @include breakpoint(768px) {
          flex-basis: 45%;
        }

        @include breakpoint(desktop) {
          flex-basis: 25%;
          padding-left: 0;
          margin-bottom: 0;
        }

        @include breakpoint(xldesktop) {
          flex-basis: 29%;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            flex-basis: 25%;
          }
        }

        b {
          color: $greyText;
        }

        span {
          color: lighten($blueColor, 10%);
        }
      }

      &-infos {
        text-align: center;
        font-size: 14px;
        line-height: 1.3;
        position: relative;
        margin-bottom: 20px;

        @include breakpoint(phablet) {
          text-align: left;
          padding-left: 60px;
          margin-bottom: 35px;
        }

        @include breakpoint(768px) {
          flex-basis: 45%;
          margin-bottom: 5px;
        }

        @include breakpoint(desktop) {
          flex-basis: 30%;
          min-height: 36px;
          padding-left: 0;
          margin-bottom: 0;
        }

        @include breakpoint(xldesktop) {
          flex-basis: 25%;
        }

        span {
          display: block;
          font-weight: bold;
          color: lighten($blueColor, 10%);

          @include breakpoint(phablet) {
            display: inline-block;
          }

          @include breakpoint(largedesktop) {
            display: block;
          }
        }

        &:after {
          display: none;
          content: ' ';
          position: absolute;
          width: 1px;
          height: 50px;
          top: -5px;
          right: 1%;
          margin: auto;
          background-color: darken($greyBackground, 5%);

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            right: 0;
          }

          @include breakpoint(desktop) {
            display: block;
            right: 20%;

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
              right: 50px;
            }
          }

          @include breakpoint(largedesktop) {
            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
              right: 80px;
            }
          }

          @include breakpoint(xldesktop) {
            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
              right: 40px;
            }
          }
        }
      }
    }

    .messagesArchive {
      margin: auto;
      width: 95%;
      max-width: 1150px;
      border-radius: 10px;
      background: $whiteColor;
      padding: 25px 20px 0;
      height: 530px;
      overflow-y: auto;
      margin-bottom: 40px;

      @include breakpoint(768px) {
        padding: 45px 40px 0;
      }

      &-message {
        display: flex;
        margin-bottom: 45px;

        &.learner {
          @include breakpoint(768px) {
            margin-left: 68px;
          }

          @include breakpoint(laptop) {
            margin-left: 108px;
          }

          .messagesArchive-content {
            flex-basis: 100%;

            @include breakpoint(768px) {
              flex-basis: 90%;
              margin-right: 30px;
            }

            @include breakpoint(laptop) {
              flex-basis: 83%;
            }

            .message {
              box-shadow: 0 0 0 transparent;
              color: $whiteColor;
              background: lighten($blueColor, 5%);
            }

            .infos {
              text-align: right;
            }
          }

          .messagesArchive-photo {
            margin: 5px 0;
          }
        }
      }

      &-photo {
        display: none;
        flex-basis: 78px;
        border-radius: 800px;
        width: 78px;
        height: 78px;
        margin: 5px 30px 5px 0;

        @include breakpoint(768px) {
          display: block;
        }
      }

      &-content {
        flex-basis: 100%;
        font-family: 'Titillium Web', sans-serif;

        @include breakpoint(768px) {
          flex-basis: 85%;
        }

        @include breakpoint(laptop) {
          flex-basis: 75%;
        }

        .message {
          width: 100%;
          font-size: 15px;
          line-height: 1.4;
          background: $whiteColor;
          border-radius: 10px;
          border: 1px solid #dddddd;
          box-shadow: 0 0 12px 0 rgba(0,0,0,0.08);
          padding: 20px 25px;
          margin: auto auto 10px;
        }

        .infos {
          font-size: 14px;
        }
      }
    }

    .answerMessages {
      width: 95%;
      text-align: center;
      margin: auto;
      max-width: 730px;

      @include breakpoint(768px) {
        width: 85%;
      }
    }

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      font-style: italic;
      color: $greyLightColor;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      font-style: italic;
      color: $greyLightColor;
    }
    :-ms-input-placeholder { /* IE 10+ */
      font-style: italic;
      color: $greyLightColor;
    }
    :-moz-placeholder { /* Firefox 18- */
      font-style: italic;
      color: $greyLightColor;
    }

    label {
      display: block;
      font-family: $font-family-base;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    textarea {
      width: 100%;
      min-width: auto;
      height: 105px;
      background: $whiteColor;
      border-radius: 10px;
      border: 1px solid #dddddd;
      box-shadow: 0 0 8px 0 rgba(0,0,0,0.03);
      padding: 15px 20px;
      margin: auto auto 20px;

      @include breakpoint(phablet) {
        max-width: 100%;
      }

      &:focus {
        outline-style: none;
        border: 1px solid lighten($blueColor,8%);
        transition: all $durationSmall $easeInOutCirc;
      }
    }

    select {
      width: auto;
      min-width: auto;
      max-width: 100%;
      padding: 15px 60px 15px 20px;
      font-size: 16px;
      font-weight: 400;
      color: $greyText;
      height: auto;
      letter-spacing: 0;
      border: 1px solid #dddddd;
      background-image: url('/assets/front/images/picto-arrow.svg');
      background-repeat: no-repeat;
      background-position: 95% 53%;
      background-size: 18px 9px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  }
