@font-face {
  font-family: 'Pangram';
  src: url('/assets/front/fonts/Pangram-Light.eot');
  src: local('Pangram-Light'),
  url('/assets/front/fonts/Pangram-Light.eot?#iefix') format('embedded-opentype'),
  url('/assets/front/fonts/Pangram-Light.woff') format('woff'),
  url('/assets/front/fonts/Pangram-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Pangram';
  src: url('/assets/front/fonts/Pangram-Medium.eot');
  src: local('Pangram-Medium'),
  url('/assets/front/fonts/Pangram-Medium.eot?#iefix') format('embedded-opentype'),
  url('/assets/front/fonts/Pangram-Medium.woff') format('woff'),
  url('/assets/front/fonts/Pangram-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Pangram';
  src: url('/assets/front/fonts/Pangram-ExtraLight.eot');
  src: local('Pangram-ExtraLight'),
  url('/assets/front/fonts/Pangram-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('/assets/front/fonts/Pangram-ExtraLight.woff') format('woff'),
  url('/assets/front/fonts/Pangram-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Pangram';
  src: url('/assets/front/fonts/Pangram-Black.eot');
  src: local('Pangram-Black'),
  url('/assets/front/fonts/Pangram-Black.eot?#iefix') format('embedded-opentype'),
  url('/assets/front/fonts/Pangram-Black.woff') format('woff'),
  url('/assets/front/fonts/Pangram-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Pangram';
  src: url('/assets/front/fonts/Pangram-Bold.eot');
  src: local('Pangram-Bold'),
  url('/assets/front/fonts/Pangram-Bold.eot?#iefix') format('embedded-opentype'),
  url('/assets/front/fonts/Pangram-Bold.woff') format('woff'),
  url('/assets/front/fonts/Pangram-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Pangram';
  src: url('/assets/front/fonts/Pangram-Regular.eot');
  src: local('Pangram-Regular'),
  url('/assets/front/fonts/Pangram-Regular.eot?#iefix') format('embedded-opentype'),
  url('/assets/front/fonts/Pangram-Regular.woff') format('woff'),
  url('/assets/front/fonts/Pangram-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Pangram';
  src: url('/assets/front/fonts/Pangram-ExtraBold.eot');
  src: local('Pangram-ExtraBold'),
  url('/assets/front/fonts/Pangram-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('/assets/front/fonts/Pangram-ExtraBold.woff') format('woff'),
  url('/assets/front/fonts/Pangram-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}
