/* ==========================================================================
   Components / Modale Essais restants
   ========================================================================== */

  .modaltry {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: fixed;
    text-align: center;
    color: $whiteColor;
    top: 0;
    left: 0;
    background-color: rgba(43, 46, 59, 0.98);
    z-index: 10;

    &-title {
      font-size: 30px;
    }

    &-subtitle {
      font-size: 24px;
      margin-bottom: 18px;
    }

    &-try {
      font-size: 20px;
      margin-bottom: 30px;
    }
  }