/* ==========================================================================
   Components / Résultats d'évaluation
   ========================================================================== */

  .resultseval {
    .maintitle {
      text-transform: none;
      margin-bottom: 30px;
    }
    .libelle {
      display: none!important;
      margin-bottom: 20px!important;

      @include breakpoint(885px) {
        display: flex!important;
      }

      .title {
        font-weight: 400!important;
      }
    }

    &-results {
      display: table;
      width: 90%;
      margin: 30px auto 15px;
      max-width: 680px;
      border-radius: 10px;
      border: 1px solid $darkColor;
      padding: 15px 25px 30px;
      text-align: center;

      @include breakpoint(phablet) {
        width: 85%;
        padding: 25px 25px 30px;
      }

      @include breakpoint(tablet) {
        margin: 80px auto 15px;
        padding: 25px 75px 30px;
      }

      &.validResult {
        .resultseval-title { color: $greenColor; &:after, &:before {color: $greenColor; }}
        .resultseval-subtitle { color: $greenColor; }
        p span { color: $greenColor; }
      }

      &.unvalid {
        .resultseval-title { &:after, &:before {color: $redColor; }}
        .resultseval-subtitle { color: $redColor; }
        p span { color: $redColor; }
      }
    }

    &-title {
      font-size: 28px;
      line-height: 0.6;
      text-transform: uppercase;
      font-weight: 500;
      margin-bottom: 35px;

      &:before {
        content: '•';
        font-size: 55px;
        position: relative;
        top: 6px;
        margin-right: 15px;
      }

      &:after {
        content: '•';
        font-size: 55px;
        position: relative;
        top: 6px;
        margin-left: 15px;
      }
    }

    &-subtitle {
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 20px;
    }

    p {
      display: block;
      font-size: 16px;
      line-height: 1.3;
      margin-bottom: 10px;

      &.last {
        margin-bottom: 25px;
      }
    }

    .btn-restart {
      margin: 35px auto 0!important;
    }
  }

