/* ==========================================================================
   Layout / Exercice
   ========================================================================== */


  .exercice {
    display: table;
    width: 100%;
    max-width: 1400px;
    margin: auto;
    padding: 0;
    position: relative;

    @include breakpoint(phablet) {
      padding: 10px 20px 0;
    }

    @include breakpoint(768px) {
      padding: 20px 30px;
    }

    @include breakpoint(laptop) {
      padding: 20px 50px;
    }

    @include breakpoint(xldesktop) {
      padding: 20px 10px;
    }

    &-toptitle {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      margin-top: 35px;
      margin-bottom: 5px;

      @include breakpoint(768px) {
        font-size: 20px;
      }

      @include breakpoint(tablet) {
        margin-top: 20px;
      }

      @include breakpoint(laptop) {
        margin-top: 0;
      }

      @include breakpoint(desktop) {
        font-size: 18px;
      }
    }

    &-objectif {
      font-size: 16px;
      line-height: 1.2;
      text-align: center;

      @include breakpoint(768px) {
        font-size: 18px;
      }

      @include breakpoint(desktop) {
        font-size: 16px;
      }
    }

    &-leave {
      color: $bluedarkColor;
      font-weight: bold;
      font-size: 18px;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      padding-left: 25px;
      margin: auto;
      text-align: center;
      transition: all $durationSmall $easeInOutCirc;

      @include breakpoint(768px) {
        top: -5px;
        font-size: 23px;
      }

      @include breakpoint(laptop) {
        top: 20px;
        right: 50px;
        left: inherit;
        padding-left: 0;
      }

      @include breakpoint(desktop) {
        font-size: 18px;
        top: 23px;
      }

      @include breakpoint(xldesktop) {
        right: 5px;
      }

      &:hover {
        color: $blueColor;
        text-decoration: none;
        transition: all $durationSmall $easeInOutCirc;

        &:after {
          opacity: 0.8;
          transition: all $durationSmall $easeInOutCirc;
        }
      }

      &:after {
        content: ' ';
        display: inline-block;
        width: 25px;
        height: 25px;
        margin-left: 8px;
        background: url('/assets/front/images/icone-quitter.svg') center center/ 25px 25px no-repeat;
        position: relative;
        top: 6px;
        transition: all $durationSmall $easeInOutCirc;

        @include breakpoint(768px) {
          width: 30px; height: 30px;
          background: url('/assets/front/images/icone-quitter.svg') center center/ 30px 30px no-repeat;
        }

        @include breakpoint(desktop) {
          width: 25px; height: 25px;
          background: url('/assets/front/images/icone-quitter.svg') center center/ 25px 25px no-repeat;
        }
      }
    }

    &-content {
      display: table;
      width: 100%;
      background: $whiteColor;
      border-radius: 10px;
      padding-bottom: 15px;
      margin-top: 60px;
      margin-bottom: 40px;

      @include breakpoint(768px) {
        position: relative;
      }

      @include breakpoint(laptop) {
        margin-top: 65px;
      }

      .infomobile {
        display: block;
        padding: 0 25px;
        font-size: 13px;
        text-align: left;
        line-height: 1.55;
        margin-bottom: 15px;

        @include breakpoint(phablet) {
          padding: 0 35px;
          font-size: 15px;
        }

        @include breakpoint(760px) {
          padding: 0 65px;
        }

        @include breakpoint(900px) {
          padding: 0 50px;
        }

        @include breakpoint(laptop) {
          width: 80%;
          margin: auto;
        }

        strong {
          color: $blueColor;
        }

        i {
          opacity: 0.7;
        }

        @include breakpoint(1300px) {
          display: none;
        }
      }
    }

    &-nav {
      display: flex;
      justify-content: space-between;
      margin-bottom: 35px;

      @include breakpoint(tablet) {
        margin-bottom: 45px;
      }

      li {
        flex: 1 auto;
        list-style-type: none;
        background: $greyColor;
        position: relative;
        height: 10px;
        text-align: center;

        &:first-child {
          border-radius: 10px 0 0 0;

          &:before {
            border-radius: 10px 0 0 0;
          }
        }

        @include breakpoint(mobileonly) {
          &:nth-child(5) {
            border-radius: 0 10px 0 0;

            span {
              display: none;
            }

            &:after {
              content: ' ...';
              font-size: 18px;
              font-weight: bold;
              color: #cbcbcb;
              position: absolute;
              top: -40px;
            }
          }
          &:nth-child(5) ~ li {
            display: none;
          }
        }

        @include breakpoint(phablet) {
          &:nth-child(8) {
            border-radius: 0 10px 0 0;

            span {
              display: none;
            }

            &:after {
              content: ' ...';
              font-size: 18px;
              font-weight: bold;
              color: #cbcbcb;
              position: absolute;
              top: -40px;
            }
          }
          &:nth-child(8) ~ li {
            display: none;
          }
        }

        @include breakpoint(laptop) {
          &:nth-child(8) {
            border-radius: 0;

            span {
              display: inherit;
            }

            &:after {
              display: none;
            }
          }

          &:nth-child(8) ~ li {
            display: block;
          }
        }

        &:last-child {
          border-radius: 0 10px 0 0;

          &.lastcurrentstep, &.validcurrentstep, &.errorcurrentstep {
            background: $blueColor;
          }
        }

        &.validstep {
          background: $blueColor;

          span {
            color: $greenColor;

            &:after {
              content: ' ';
              display: inline-block;
              width: 18px;
              height: 15px;
              margin-left: 5px;
              background: url('/assets/front/images/picto-valide.svg') center center/ 18px 15px no-repeat;
              position: relative;
              top: 2px;
            }
          }
        }
        &.validcurrentstep {
          span {
            color: $whiteColor;
            width: 30px;
            height: 37px;
            padding-top: 2px;
            background: url('/assets/front/images/bg-curseur-vert.svg') center center/ 30px 37px no-repeat;
            top: -42px;
            left: 0;
            right: 0;
            margin: auto;
          }

          &:before {
            content: ' ';
            display: block;
            width: 50%;
            height: 10px;
            background: $blueColor;
            position: absolute;
            left: 0;
          }

          & + .unvalidstep, & + .validstep, & + .lastcurrentstep {
            &:before {
              content: ' ';
              display: block;
              width: 50%;
              height: 10px;
              background: $blueColor;
              position: absolute;
              top: 0;
              left: -50%;
            }
          }
        }
        &.errorcurrentstep {
          span {
            color: $whiteColor;
            width: 30px;
            height: 37px;
            padding-top: 2px;
            background: url('/assets/front/images/bg-curseur-rouge.svg') center center/ 30px 37px no-repeat;
            top: -42px;
            left: 0;
            right: 0;
            margin: auto;
          }

          &:before {
            content: ' ';
            display: block;
            width: 50%;
            height: 10px;
            background: $blueColor;
            position: absolute;
            left: 0;
          }

          & + .unvalidstep, & + .validstep, & + .lastcurrentstep {
            &:before {
              content: ' ';
              display: block;
              width: 50%;
              height: 10px;
              background: $blueColor;
              position: absolute;
              top: 0;
              left: -50%;
            }
          }
        }
        &.stepass {
          background: $blueColor;

          span {
            color: $blueColor;

            &:after {
              content: ' ';
              display: inline-block;
              width: 18px;
              height: 15px;
              margin-left: 5px;
              background: url('/assets/front/images/picto-valide-bleu.svg') center center/ 18px 15px no-repeat;
              position: relative;
              top: 2px;
            }
          }
        }
        &.unvalidstep {
          background: $blueColor;

          span {
            color: $redColor;

            &:after {
              content: ' ';
              display: inline-block;
              width: 18px;
              height: 15px;
              margin-left: 5px;
              background: url('/assets/front/images/icone-invalid.svg') center center/ 18px 15px no-repeat;
              position: relative;
              top: 2px;
            }
          }
        }
        &.current {
          span {
            color: $whiteColor;
            width: 30px;
            height: 37px;
            padding-top: 2px;
            background: url('/assets/front/images/bg-curseur.svg') center center/ 30px 37px no-repeat;
            top: -42px;
            left: 0;
            right: 0;
            margin: auto;
          }

          &:before {
            content: ' ';
            display: block;
            width: 50%;
            height: 10px;
            background: $blueColor;
            position: absolute;
            left: 0;
          }

          &.hasNextElem {
            &:before {
              width: 100%;
            }
          }
        }
        &.lastcurrentstep {
          span {
            color: $blueColor;
            width: 30px;
            height: 37px;
            padding-top: 2px;
            background: url('/assets/front/images/bg-curseur-pointille.svg') center center/ 30px 37px no-repeat;
            top: -42px;
            left: 0;
            right: 0;
            margin: auto;
          }

          &:after {
            content: ' ';
            display: block;
            width: 50%;
            height: 10px;
            background: $blueColor;
            position: absolute;
            top: 0;
            left: 0;
          }
        }

        span {
          font-size: 18px;
          font-weight: bold;
          color: darken($greyColor, 10%);
          position: absolute;
          top: -40px;
          left: 0;
          right: 0;
        }
      }
    }

    &-loader {
      display: block;
      margin: auto;
      text-align: center;
      padding: 18px 15px;
      font-weight: 400;
      line-height: 0.9;
      color: $blueColor;
      position: fixed;
      top: 250px;
      right: 0;
      font-size: 25px;
      width: 75px;
      height: 75px;
      background: #ffffff url('/assets/front/images/loader.gif') center center/cover no-repeat;
      box-shadow: 2px 0 10px 0 rgba(100, 100, 100, 0.2);
      z-index: 1;

      @include breakpoint(768px) {
        padding: 21px 20px 20px;
        font-size: 30px;
        width: 85px;
        height: 85px;
        position: absolute;
        right: 25px;
        top: 30px;
        box-shadow: 0 0 0 transparent;
      }

      &.warning {
        color: $orangeColor;
        background: #ffffff url('/assets/front/images/loader-alerte.gif') center center/cover no-repeat;
      }

      &.stop {
        color: $orangeColor;
        background: #ffffff url('/assets/front/images/loader-complete.gif') center center/cover no-repeat;
      }

      .time {
        sup {
          font-size: 18px;
          margin-left: -8px;
        }
      }

      .mesure {
        display: block;
        font-size: 18px;
      }
    }

    &-title {
      display: block;
      font-size: 25px;
      font-weight: 500;
      text-align: center;
      padding: 0 20px;

      @include breakpoint(700px) {
        padding: 0;
        font-size: 28px;
        max-width: 65%;
        margin: auto;
      }

      @include breakpoint(tablet) {
        max-width: 55%;
      }
    }

    &-subtitle {
      display: block;
      max-width: 80%;
      margin: auto;
      font-size: 16px;
      line-height: 1.3;
      text-align: center;
      margin-top: 15px;
      padding: 0 10px;

      @include breakpoint(tablet) {
        padding: 0;
        max-width: 55%;
      }
    }

    &-illusimagefull {
      display: block;
      background: $darkColor;
      border-radius: 10px;
      width: 88%;
      //max-height: 150px;
      margin: 25px auto 25px;
      overflow: hidden;

      @include breakpoint(phablet) {
        width: 81%;
        //max-height: 280px;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.98;

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          width: 100%;
          height: auto;
          min-height: 100%;
        }
      }
    }

    &-illusimage {
      display: block;
      width: 88%;
      margin: 25px auto 25px;
      overflow: hidden;

      @include breakpoint(laptop) {
        max-width: 665px;
      }

      @include breakpoint(desktop) {
        display: inline-block;
        vertical-align: top;
        max-width: 55%;
        margin-left: 5%;
        margin-right: 30px;
        margin-top: 40px;
        margin-bottom: 85px;
      }

      @include breakpoint(1400px) {
        max-width: 665px;
        margin-left: 7%;
      }

      @include breakpoint(1600px) {
        margin-left: 10%;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
        border-radius: 10px;
      }

      .exercice-resultMultipleChoice {
        bottom: 0;
        margin: 15px 0;
        left: 0;
        width: 100%;
        padding: 0;
        text-align: left;
      }

      .audio {
        max-width: 800px;
        margin: auto;
        padding: 0;

        &-elem {
          max-width: inherit;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            max-width: 95%;
          }
        }

        .image {
          border-radius: 10px 10px 0 0;

          img {
            border-radius: 10px 10px 0 0;
          }
        }
      }
    }

    &-resultMultipleChoice {
      display: block;
      text-align: center;
      line-height: 1.3;
      padding: 0 35px;
      margin: 10px auto 30px;
      position: relative;
      bottom: 20px;

      @include breakpoint(laptop) {
        bottom: 30px;
      }
    }

    &-nextImg {
      display: block;
      vertical-align: top;
      width: 80%;
      text-align: center;
      margin: auto auto 20px;

      @include breakpoint(phablet) {
        width: 90%;
      }

      @include breakpoint(768px) {
        width: 95%;
      }

      @include breakpoint(desktop) {
        display: inline-block;
        text-align: left;
        width: 35%;
        margin-top: 20px;

        .exercice-subtitle {
          text-align: left;
          margin-left: 10px;
          max-width: 80%;
        }
      }

      .multiplechoicenoimg {
        margin: 20px auto 50px;

        @include breakpoint(desktop) {
          justify-content: flex-start;

          &-elem {
            flex-basis: 44%;
            margin: 5px 10px;

            @include breakpoint(1300px) {
              flex-basis: 42%;
              margin: 0 15px 15px;
            }
          }
        }
      }

      & + .exercice-resultMultipleChoice {
        max-width: 90%;
        margin: auto auto 50px;

        @include breakpoint(desktop) {
          max-width: 100%;
          margin: 0;
          text-align: left;
          position: relative;
          left: 70px;
          bottom: 75px;
        }

        @include breakpoint(xldesktop) {
          left: 105px;
        }
      }
    }

    &-fullText {
      @include breakpoint(tablet) {
        width: 90%;
        margin: auto;
      }

      .exercice-bloc {
        padding: 10px 20px;

        @include breakpoint(768px) {
          padding: 30px 55px;
        }

        @include breakpoint(laptop) {
          padding: 40px 65px 20px;
        }

        @include breakpoint(xldesktop) {
          padding: 40px 115px 20px;
        }
      }

      .exercice-secondbloc {
        padding: 10px 25px;

        @include breakpoint(768px) {
          padding: 0px 55px 40px;
        }

        @include breakpoint(laptop) {
          padding: 10px 65px 20px;
        }

        @include breakpoint(1500px) {
          padding: 10px 115px 20px;
        }
      }
    }

    &-bloc, &-secondbloc {
      display: block;
      padding: 10px 20px;

      @include breakpoint(750px) {
        padding: 35px 55px;
      }

      @include breakpoint(laptop) {
        display: flex;
        flex-direction: row;
        padding: 35px 65px;
      }

      @include breakpoint(xldesktop) {
        padding: 35px 115px;
      }

      .image {
        flex-basis: 100%;
        overflow: hidden;
        position: relative;
        background-position: center center;
        background-size: cover;

        @include breakpoint(laptop) {
          flex-basis: 50%;
        }

        img {
          width: 100%;
          height: auto;
          border-radius: 10px;
          -ms-interpolation-mode: bicubic;
          filter:progid:DXImageTransform.Microsoft.Alpha(opacity=0);
        }

        & + .text {
          padding: 25px 5px 0;
        }
      }

      .text {
        padding: 25px 5px 0;

        @include breakpoint(tablet) {
          padding: 35px 0 0;
        }

        @include breakpoint(laptop) {
          flex-basis: 50%;
          margin-left: 46px;
          border-top: 2px solid $blueColor;
        }

        @include breakpoint(desktop) {
          p {
            margin-right: 20%;

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
              margin-right: 10%;
            }
          }
        }

        &.fullwidth {
          padding: 25px 5px 0;
          flex-basis: 93%;
          margin: auto;
          text-align: left;

          @include breakpoint(tablet) {
            padding: 35px 5px 0;
          }

          p {
            margin-right: 0;
            margin-left: 0;
          }
        }
      }

      .video {
        max-width: 845px;
        margin: 0 auto;
        position: relative;
        cursor: pointer;

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          min-width: 500px;
        }

        &:after {
          content: ' ';
          display: none;
          width: 65px;
          height: 65px;
          background: url('/assets/front/images/picto-play-video.svg') center center/65px 65px no-repeat;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          z-index: 5;

          @include breakpoint(tablet) {
            top: 0;
            width: 80px;
            height: 80px;
            background: url('/assets/front/images/picto-play-video.svg') center center/80px 80px no-repeat;
          }
        }

        @-moz-document url-prefix() {
          &:after {
            display: none!important;
          }
        }

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          &:after {
            display: none;
          }
        }

        &.isPlay:after {
          display: none;
        }

        video {
          width: 100%;
          border-radius: 10px;
        }

        & + .text {
          padding: 5px 5px 0;

          @include breakpoint(laptop) {
            padding: 25px 5px 0;
          }
        }

        & + .audio {
          @include breakpoint(768px) {
            min-height: inherit;
            margin: 0 25px 20px;

            .audio-elem .image {
              min-height: inherit;
            }
          }
        }
      }

      .audio {
        flex-basis: 100%;
        border-radius: 10px;
        overflow: hidden;
        min-height: 150px;
        position: relative;
        background-position: center center;
        background-size: cover;
        margin: 0;
        padding: 0;

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          min-height: auto;
        }

        @include breakpoint(phablet) {
          min-height: 330px;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            min-height: auto;
          }
        }

        &-elem {
          max-width: inherit;
          margin: 0;

          .image {
            margin: 0;
            border-radius: 0;
            flex-basis: 100%;

            img {
              width: 100%;
              height: 100%;
              border-radius: 0;
            }
          }
        }

        & + .text {
          padding: 25px 5px 0;

          @include breakpoint(laptop) {
            flex-basis: 65%;
          }
        }
      }

      &.onlyImage {
        .image {
          @include breakpoint(laptop) {
            flex-basis: 100%;
          }
        }
      }
    }

    &-secondbloc {
      padding: 20px 25px;

      @include breakpoint(750px) {
        padding: 0 55px 35px;
      }

      @include breakpoint(laptop) {
        flex-direction: row;
        padding: 0 65px 35px;
      }

      @include breakpoint(1500px) {
        padding: 0 115px 35px;
      }

      .text {
        margin-left: 5px;
        margin-right: 5px;
        padding: 35px 0 10px;
        border-top: 2px solid $blueColor;

        @include breakpoint(768px) {
          margin-left: 10px;
          margin-right: 10px;
        }

        @include breakpoint(laptop) {
          margin-right: 45px;
          margin-left: 5px;
          padding: 25px 0 0;
        }

        p, ul {
          margin-right: 0;
        }
      }

      .image {
        margin: 0 10px;

        @include breakpoint(768px) {
          margin: 0 15px;
        }

        @include breakpoint(laptop) {
          margin: 0;
        }
      }
    }

    .onlyAudio {
      .audio {
        max-width: 800px;
        margin: auto;
      }
    }

    &-push {
      display: block;
      margin: 0 25px 40px;
      padding: 25px 25px;
      border-radius: 10px;
      background: $greyPushBg;

      @include breakpoint(phablet) {
        padding: 25px 30px;
        margin: 0 30px 40px;
      }

      @include breakpoint(768px) {
        margin: 0 80px 40px;
      }

      @include breakpoint(laptop) {
        margin: 20px 100px 50px;
      }

      @include breakpoint(largedesktop) {
        margin: 20px 160px 50px;
      }

      h2 {
        font-size: 16px;
      }
    }

    &-help {
      display: none;
      background-color: lighten($blueColor, 3%);
      color: $whiteColor;
      font-size: 14px;
      padding: 15px 15px 15px 20px;
      position: fixed;
      top: 380px;
      z-index: 3;
      box-shadow: 1px 5px 8px 1px rgba(0,0,0,0.1);
      transition: top 0.3s;

      @include breakpoint(desktop) {
        display: table;
        left: 0;
      }

      a {
        display: block;
        color: $whiteColor;
        font-weight: bold;
        margin-bottom: 10px;
      }

      &:after {
        content: ' ';
        display: block;
        width: 35px;
        height: 30px;
        margin: auto;
        background: url('/assets/front/images/icone-contact.svg') center center/ 35px auto no-repeat;
      }
    }

    &-buttons {
      width: 80%;
      margin: auto;
      position: relative;
      z-index: 1;

      @include breakpoint(phablet) {
        .btn-prev-big { float: left; }
        .btn-next-big { float: right; }
      }
    }

    .alert {
      max-width: 85%;
      margin: 25px auto 0;

      @include breakpoint(laptop) {
        max-width: 80%;
      }

      ul {
        margin-left: 20px;
      }
    }

    .alertNoJs {
      text-align: center;
      position: relative;
      bottom: 35px;
      font-weight: 500;
      color: $redColor;
      line-height: 1.3;
      padding: 0 35px;
    }

    .audioControls {
      display: block;
      width: 100%;
      padding: 10px 12px;

      &-playstop {
        border: 0;
        width: 16px;
        height: 15px;
        display: inline-block;
        vertical-align: top;
        background: url('/assets/front/images/picto-play.svg') center center/ contain no-repeat;
        margin-top: 2px;
        margin-right: 3px;

        &:focus {
          outline-style: none;
        }
      }

      &.play {
        .audioControls-playstop { background: url('/assets/front/images/picto-pause.svg') center center/ contain no-repeat; }
      }

      &-time {
        display: inline-block;
        font-size: 12px;
        vertical-align: top;
      }

      &-duration {
        display: inline-block;
        width: calc(100% - 115px);
        vertical-align: top;
        background: #eaeaea;
        height: 4px;
        margin-top: 10px;
        margin-left: 10px;
        position: relative;

        .bar {
          display: block;
          border-radius: 50px;
          background: $blueColor;
          width: 0;
          height: 4px;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }

    audio {
      display: none;
    }


    /* MAJ juillet 2020 */
    .container-timer-button {
      background:white;
      position: fixed;
      bottom: 0px;
      z-index:99;
      width: 100%;
      //max-width: calc(1400px - 30px);
      display:flex;
      margin: 0 auto;
      left: 0;
      right: 0;
      align-items: center;
      padding: 0;
      justify-content: center;
      flex-wrap: wrap;
      border-top: solid 3px $blueColor;

      @media (min-width: 890px) {
        bottom: 0;
        padding: 0 100px;
        height:75px;
        background:white;
        display:block;

      }

      .exercice-loader {
        position: relative;
        background: none;
        flex:auto;
        min-height: 50px;
        height: auto;
        right: auto;
        padding: 0;
        margin: 10px auto;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFF;
        font-size: 14px;
        flex-wrap: wrap;
        flex-direction: column;
        max-width: 100%;
        top: -10px;
        width: 100%;
        box-shadow: none;
        order:-1;
        @media (min-width: 890px) {
          top: auto;
          order:0;
          max-width: calc(100% - 480px);
          margin:0 auto;
        }
        .mesure {
          font-size: 14px;
          position: absolute;
          right: 10px;
          top: 28px;
          transition: all .4s;
        }
        &.warning .my-bar {
          background: $orangeColor;
        }
        &.alert .my-bar {
          background: #EC2E2E;
        }
      }
      .progress-bar-loader {
        width: 100%;
        height: 30px;
        background: #e5e5e5;

        @media (min-width: 890px) {
          background: #FFF;
        }
        position: absolute;
        z-index: -1;
        top: 0;
        min-height: 50px;
        border-radius: 16px;
        overflow: hidden;
        .my-timer {
          content: "";
          background: url('/assets/front/images/icone-timer.svg') no-repeat;
          width: 30px;
          height: 33px;
          position: absolute;
          display: block;
          top: 9px;
          left: 9px;
          transition: all .4s;
        }
      }
      .my-bar {
        min-height: 50px;
        border-radius: 16px;
        background: #00a0f6;
        position: relative;
        .time {
          position: absolute;
          right: 10px;
          top: 16px;
          transition: all .4s;
        }
      }

      .exercice-buttons {
        margin: 0;
       // flex-direction: column;
        display: flex;
        z-index: 4;
        justify-content:flex-end;
        flex-wrap: wrap;
        position: relative;
        width: 90%;
        padding:10px 0;
        margin:0 auto;
        @media (min-width: 890px) {
          flex-wrap: nowrap;
          //position: absolute;
          width: 80%;
          flex-direction: row;
          max-width: calc(1300px - 30px);
        }
        button {
          float: none;
          margin: 0;
        }
        .btn-next-big {
          margin-left: auto;
          padding-right: 60px;
          font-size:12px;
          @media (min-width: 470px) {
            padding-right: 90px;
            font-size:14px;
          }
        }
        .btn-prev-big {
          margin-left: 0;
          margin-bottom: 0;
          padding-left: 60px;
          font-size:12px;
          @media (min-width: 470px) {
            padding-left: 80px;
            font-size:14px;
          }
          @media (min-width: 890px) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
