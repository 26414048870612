/* ==========================================================================
   Components / Excercice : Choix multiple sans image
   ========================================================================== */

  .multiplechoicenoimg {
    max-width: 1000px;
    margin: 20px auto 50px;

    @include breakpoint(phablet) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 20px auto 50px;
    }

    @include breakpoint(760px) {
      width: 85%;
    }

    @include breakpoint(tablet) {
      margin: 20px auto 50px;
    }

    @include breakpoint(laptop) {
      width: 100%;
      margin: 30px 30px 50px;
    }

    @include breakpoint(desktop) {
      margin: 40px auto 50px;
    }

    &-elem {
      display: block;
      margin-bottom: 15px;

      @include breakpoint(phablet) {
        display: inherit;
        margin: 5px;
        flex-basis: 220px;
      }

      @include breakpoint(768px) {
        flex-basis: 200px;
      }

      @include breakpoint(laptop) {
        margin: 10px;
      }

      label {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 100%;
        font-family: $font-family-base;
        font-size: 16px;
        line-height: 1.2;
        color: $greyText;
        text-align: center;
        padding: 15px 15px;
        border-radius: 10px;
        border: 1px solid $blueColor;
        transition: all $durationSmall $easeInOutQuart;
        cursor: pointer;

        @include breakpoint(xldesktop) {
          &:hover {
            background: $blueColor;
            color: $whiteColor;
            transition: all $durationSmall $easeInOutQuart;
          }
        }

        p {
          font-size: 14px;
          font-weight: 300;
          font-style: italic;
          margin-top: 5px;
          opacity: 0.95;
        }
      }

      input[type="checkbox"]:checked + label {
        background: $blueColor;
        color: $whiteColor;
        transition: all $durationSmall $easeInOutQuart;
      }

      input[type="checkbox"] {
        display: none;
      }
    }

    // Etat désactivé après envoi des réponses
    .disabled {
      label:hover {
        background: transparent;
        color: $greyText;
        cursor: default;
      }
    }

    // Etat selon les réponses de l'utilisateur
    .selectValid {
       label {
         background: $greenColor;
         color: $whiteColor;
         border: 1px solid $greenColor;

         &:hover {
           background: $greenColor;
           color: $whiteColor;
           border: 1px solid $greenColor;
         }
       }
     }
    .selectError {
      label {
        background: $redColor;
        color: $whiteColor;
        border: 1px solid $redColor;

        &:hover {
          background: $redColor;
          color: $whiteColor;
          border: 1px solid $redColor;
        }
      }
    }
    .unselectValid {
      animation-name: bounceIn;
      animation-duration: 1s;
      animation-fill-mode: both;

      label {
        border: 3px solid $greenColor;
      }
    }
  }