/* ==========================================================================
   Base / Buttons
   ========================================================================== */

  .btn {
    font-size: 13px;
    font-weight: 500;
    color: $bluedarkColor;
    letter-spacing: 0.13rem;
    border-radius: 20px;
    border: 1px solid $bluedarkColor;
    padding: 8px 20px 9px;
    transition: all $duration $easeOutCubic;
    white-space: normal;

    &:hover {
      text-decoration: none;
      color: $whiteColor;
      background: $bluedarkColor;
      transition: all $duration $easeOutCubic;
    }

    &:focus {
      outline-style: none;
    }

    &-download {
      letter-spacing: 0.05rem;
      padding: 8px 45px 9px 10px;
      border-radius: 10px;
      background: url('/assets/front/images/picto-telechargement.svg') 93% center / 19px 17px no-repeat;

      @include breakpoint(phablet) {
        padding: 8px 55px 9px 20px;
        letter-spacing: 0.13rem;
        border-radius: 20px;
      }

      &:hover {
        background: $bluedarkColor url('/assets/front/images/picto-telechargement-blanc.svg') 93% center / 19px 17px no-repeat;
      }
    }
  }

  .btn-next {
    @extend .btn;
    display: table;
    font-size: 12px;
    margin: auto;
    padding: 8px 70px 9px 20px;
    margin-bottom: 30px;
    cursor: pointer;
    background: url('/assets/front/images/icone-next.svg') 85% center / 20px 9px no-repeat;

    &:focus {
      outline-style: none;
    }

    &:hover {
      text-decoration: none;
      background: $bluedarkColor url('/assets/front/images/icone-next-blanc.svg') 90% center / 20px 9px no-repeat;
    }

    &.btn-not-last {
      margin-bottom: 15px;
    }
  }

  .btn-submit {
    @extend .btn;
    display: table;
    color: $whiteColor;
    background: lighten($blueColor, 5%);
    font-size: 14px;
    font-weight: bold;
    margin: 65px auto 0;
    border-radius: 30px;
    border: 0;
    padding: 12px 40px;
    cursor: pointer;

    @include breakpoint(phablet) {
      margin: 35px 2px 0;
    }

    &:hover {
      background-color: darken($blueColor, 5%);
      transition: all $duration $easeInOutQuart;
    }

    &:focus {
      outline-style: none;
    }
  }

  .btn-link {
    color: $bluedarkColor;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.15rem;
    transition: all $duration $easeInOutQuart;

    &:hover {
      text-decoration: none;
      color: darken($bluedarkColor, 15%);
      transition: all $duration $easeInOutQuart;
    }
  }

  .btn-next-big {
    @extend .btn;
    display: table;
    font-size: 14px;
    font-weight: bold;
    margin: auto;
    border-radius: 30px;
    padding: 12px 90px 12px 40px;
    margin-bottom: 30px;
    cursor: pointer;
    background: url('/assets/front/images/icone-next.svg') 80% center / 24px 13px no-repeat;

    &:focus {
      outline-style: none;
    }

    &:hover {
      text-decoration: none;
      background: $bluedarkColor url('/assets/front/images/icone-next-blanc.svg') 85% center / 24px 11px no-repeat;
    }
  }

  .btn-prev-big {
    @extend .btn;
    display: table;
    font-size: 14px;
    font-weight: bold;
    margin: auto;
    border-radius: 30px;
    padding: 12px 40px 12px 80px;
    margin-bottom: 30px;
    cursor: pointer;
    background: url('/assets/front/images/icone-prev.svg') 15% center / 24px 13px no-repeat;

    &:focus {
      outline-style: none;
    }

    &:hover {
      text-decoration: none;
      background: $bluedarkColor url('/assets/front/images/icone-prev-blanc.svg') 10% center / 24px 11px no-repeat;
    }
  }

  .validChoice {
    @extend .btn;
    display: table;
    font-size: 14px;
    font-weight: bold;
    margin: auto;
    color: $whiteColor;
    background: $bluedarkColor;
    border: 0;
    border-radius: 30px;
    padding: 15px 25px;
    margin-bottom: 30px;
    cursor: pointer;

    @include breakpoint(phablet) {
      float: right;
    }

    &:focus {
      outline-style: none;
    }

    &:hover {
      text-decoration: none;
      background: $blueColor;
      border: 0;
    }
  }

  .btn-try {
    @extend .btn;
    padding: 12px 55px;
    border-radius: 30px;
    color: $whiteColor;
    border: 1px solid $whiteColor;
    font-size: 14px;
    background: transparent;

    &:hover {
      border: 1px solid $bluedarkColor;
    }
  }

  .btn-restart {
    @extend .btn;
    display: table;
    font-size: 14px;
    font-weight: bold;
    margin: auto;
    color: $whiteColor;
    background: $bluedarkColor url('/assets/front/images/icone-rejouer.svg') 90% 45% no-repeat;
    border: 0;
    border-radius: 30px;
    padding: 15px 70px 15px 35px;
    margin-bottom: 0;
    cursor: pointer;

    &:focus {
      outline-style: none;
    }

    &:hover {
      text-decoration: none;
      background: $blueColor url('/assets/front/images/icone-rejouer.svg') 90% 45% no-repeat;
      border: 0;
    }
  }

.btn-continue {
  @extend .btn;
  display: table;
  font-size: 14px;
  font-weight: bold;
  margin: auto;
  color: $whiteColor;
  background: $bluedarkColor url('/assets/front/images/icone-next-blanc.svg') 90% 45% no-repeat;
  border: 0;
  border-radius: 30px;
  padding: 15px 70px 15px 35px;
  margin-bottom: 0;
  cursor: pointer;

  &:focus {
    outline-style: none;
  }

  &:hover {
    text-decoration: none;
    background: $blueColor url('/assets/front/images/icone-next-blanc.svg') 90% 45% no-repeat;
    border: 0;
  }
}