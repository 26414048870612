/* ==========================================================================
   Base / Mixins
   ========================================================================== */

  /*********************
  BREAKPOINTS
  *********************/

  @mixin breakpoint($point) {
    @if $point == xldesktop {
      @media (min-width: 100em) { @content ; }
    }@else if $point == largedesktop {
      @media (min-width: 80em) { @content ; }
    }@else if $point == desktop {
      @media (min-width: 70em) { @content ; }
    }
    @else if $point == laptop {
      @media (min-width: 64em) { @content ; }
    }
    @else if $point == tablet {
      @media (min-width: 50em) { @content ; }
    }
    @else if $point == phablet {
      @media (min-width: 37.5em)  { @content ; }
    }
    @else if $point == mobileonly {
      @media (max-width: 37.4em)  { @content ; }
    }
    @else if $point == smallMobile {
      @media (max-width: 3.3em)  { @content ; }
    }@else {
      @media (min-width: $point)  { @content ; }
    }
  }