/* ==========================================================================
   Layout / Navigation
   ========================================================================== */

.navigation {
  min-height: 65px;
  position: relative;
  z-index: 3;
  padding: 5px 0;
  box-shadow: 0 5px 15px 0px rgba(0,0,0,0.1);

  @include breakpoint(phablet) {
    min-height: 75px;
  }

  .logo {
    max-width: 40%;
    max-height: 40px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      max-height: 40px;
    }

    @include breakpoint(smallMobile) {
      max-width: 40%;
    }

    @include breakpoint(mobileonly) {
      margin: auto 10px;
    }

    @include breakpoint(phablet) {
      max-width: 35%;
      max-height: 40px;
      margin: auto 20px;
    }

    @include breakpoint(tablet) {
      max-width: 265px;
      max-height: 55px;
      margin: auto 0 auto 20px;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        max-height: 65px;
      }
    }

    @include breakpoint(laptop) {
      margin: auto 0 auto 40px;
    }
  }

  .menu  {
    display:flex;
    align-items:center;
    height: 32px;
    margin-right: 20px;
    font-size: 12px;
    font-weight: 800;
    padding: 28px 0 25px;
    float: right;
    letter-spacing: 0.06rem;

    @include breakpoint(phablet) {
      margin-right: 30px;
    }

    @include breakpoint(laptop) {
      margin-right: 30px;
      margin-top: 4px;
    }

    @include breakpoint(desktop) {
      margin-right: 40px;
    }

    li {
      list-style-type: none;
      padding: 2px 10px 3px;
      position: relative;
      height: 38px;

      @include breakpoint(phablet) {
        padding: 2px 15px 3px;
      }

      @include breakpoint(tablet) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 4px 25px 3px;
        height: auto;
      }

      @include breakpoint(laptop) {
        padding: 4px 30px 3px;
      }

      &:after {
        content: ' ';
        width: 1px;
        height: 30px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        background-color: lighten($greyLightColor, 20%);

        @include breakpoint(tablet) {
          height: 25px;
        }
      }

      a {
        color: $greyText;
      }

      &.message {
        padding: 2px 23px 3px 25px;

        @include breakpoint(tablet) {
          padding: 2px 30px 3px 35px;
        }

        &:before {
          content: ' ';
          width: 28px;
          height: 25px;
          background: url("/assets/front/images/picto-messagerie.svg") center center / 28px 20px no-repeat;
          position: absolute;
          left: 0;
          top: 6px;
          opacity: 0.5;

          @include breakpoint(tablet) {
            content: url("/assets/front/images/picto-messagerie.svg");
            width: 19px;
            height: 19px;
            background: transparent;
            opacity: 1.0;
            position: absolute;
            top: 4px;
          }
        }

        &:after {
          display: none;
        }

        a {
          display: none;

          @include breakpoint(tablet) {
            display: block;
          }
        }
      }

      &.unread {
        position: relative;
        padding: 2px 13px 3px 35px;

        @include breakpoint(tablet) {
          padding: 2px 30px 3px 15px;

          &:before {
            display: none;
          }
        }

        a {
          color: $redColor;
        }

        &:after {
          display: block;
          content: ' ';
          width: 12px;
          height: 12px;
          background: $redColor;
          border-radius: 500px;
          border: 3px solid $whiteColor;
          position: absolute;
          top: 6px;
          left: -6px;
          margin: 0;

          @include breakpoint(tablet) {
            top: 0px;
          }
        }
      }

      &.user {
        display: none;
        line-height: 15px;

        @include breakpoint(tablet) {
          display: block;
        }

        &:before {
          content: ' ';
          width: 1px;
          height: 30px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          background-color: lighten($greyLightColor, 20%);

          @include breakpoint(tablet) {
            height: 25px;
          }
        }

        .avatar {
          display: inline-block;
          width: 30px;
          float: left;
          margin-right: 20px;

          img {
            width: 35px;
            height: 35px;
            border-radius: 50%;
          }
        }

        .username {
          display: inline-block;
          margin-top: 2px;
        }

        .company  {
          display: none;
          opacity: 0.5;
          font-weight: 600;

          @include breakpoint(tablet) {
            display: block;
          }
        }

        .name {
          display: none;

          @include breakpoint(tablet) {
            display: block;
          }

          span {
            display: inline-block;
            text-transform: uppercase;
          }
        }
      }

      &.infos {
        display: none;
        opacity: 0.6;
        font-weight: 400;
        font-style: italic;
        letter-spacing: 0;
        font-size: 14px;
        padding: 0 0 3px 30px;

        @include breakpoint(885px) {
          display: inherit;
        }

        &:after {
          display: none;
        }
      }

      &.back-training {
        padding: 5px 0 3px 30px;

        &:after {
          display: none;
        }

        @include breakpoint(885px) {
          padding: 0 30px 3px;

          &:after {
            display: block;
          }
        }

        a {
          color: $greyText;
          font-size: 14px;
          font-weight: bold;
          height: 26px;
          transition: all .2s $easeInOutCirc;

          @include breakpoint(885px) {
            height: 30px;
          }

          &:before {
            content: " ";
            display: inline-block;
            margin: 12px 10px 0;
            height: 8px;
            width: 8px;
            border-right: 2px solid  $greyText;
            border-bottom: 2px solid  $greyText;
            -webkit-transform: rotateZ(135deg);
            -ms-transform: rotate(135deg);
            transform: rotateZ(135deg);
            position: relative;
            right: 0;
            transition: all .2s $easeInOutCirc;
          }

          &:hover {
            color: $bluedarkColor;
            text-decoration: none;
            transition: all .2s $easeInOutCirc;

            &:before {
              right: 3px;
              border-right: 2px solid  $bluedarkColor;
              border-bottom: 2px solid  $bluedarkColor;
              transition: all .2s $easeInOutCirc;
            }
          }
        }
      }

      &.deconnect {
        padding: 2px 30px 3px 10px;

        @include breakpoint(tablet) {
          padding: 2px 35px 3px 30px;

          &:before {
            display: none;
          }
        }

        a {
          display: none;

          @include breakpoint(tablet) {
            display: block;
          }
        }

        &:before {
          content: ' ';
          width: 1px;
          height: 30px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          background-color: #ccc;
        }

        &:after {
          content: ' ';
          background: transparent url("/assets/front/images/picto-deconnexion.svg") center center/ 20px 20px no-repeat;
          display: inline-block;
          top: 10px;
          bottom: inherit;
          width: 20px;
          height: 20px;

          @include breakpoint(tablet) {
            top: 3px;
          }
        }
      }
    }

    .avatar {
      display: none;
      border-radius: 500px;

      @include breakpoint(tablet) {
        display: inline-block;
      }
    }
  }


  .accessaccount {
    display: flex;
    align-items: center;
    height: 32px;

    font-size: 12px;
    padding: 32px 0 31px;
    float: right;
    letter-spacing: 0.06rem;
    ul {
      margin-right: 30px;
      color: #1f73fd;
      letter-spacing: 0.13rem;
      border-radius: 20px;
      border: 1px solid #1f73fd;
      transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
      background: url(/assets/front/images/select-fleche.svg) 90% center/13px 7px no-repeat;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      height: 39px;
      width: 220px;
      list-style: none;
      -webkit-appearance: none;

      &#customer-account {
        width: 350px;
        @include breakpoint(mobileonly){
          width: 75px;
        }
      }

      @include breakpoint(mobileonly){
        width: 75px;
      }
    }
    ul li {
      z-index: 2;
      padding: 3px 18px 12px;
      .img {
        width: 30px;
        height: 30px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
      span.inside-label {
        position: relative;
        top: -10px;
        padding-left: 5px;
      }
    }
    ul li:not(.init) {
      padding: 8px 5px 3px 20px;
      float: left;
      width: 100%;
      display: none;
      background: #fff;
      top: 6px;
      position: relative;
      cursor: pointer;
      @include breakpoint(mobileonly) {
        width: 200px;
      }
      .img {
        width: 34px;
        height:34px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
      span.inside-label {
        position: relative;
        top: -11px;
        padding-left: 5px;
      }
    }

    ul li:not(.init):hover, ul li.selected:not(.init) {
      background: #f1f1f1;
    }
    li.init {
      cursor: pointer;
      span.inside-label{
        @include breakpoint(mobileonly){
        display:none;
        }
      }

    }

    a#submit { z-index: 1; }
  }
}


