/* ==========================================================================
   Components / Training Manager Item
   ========================================================================== */
.color-blue {
  color:$blueColor;
}
.color-orange {
  color:$orangeColor;
}
.color-green {
  color:$greenColor;
}

.transparent {
  opacity:0.3;
}

  .training {
    flex-grow: 1;
    padding: 30px 15px 50px;
    background-color: $greyBackground;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      min-height: 100%;
    }

    &-elem {
      display: table;
      width: 100%;
      max-width: 1350px;
      margin: auto;
      padding: 20px 15px;
      background-color: $whiteColor;
      border-radius: 10px;
      border: 1px solid #dddddd;
      box-shadow: 0 0 10px 0 rgba(0,0,0,0.09);
      margin-bottom: 20px;
      position: relative;
      transition: all $duration $easeInCirc;

      @include breakpoint(tablet) {
        padding: 30px 20px;
      }

      @include breakpoint(desktop) {
        padding: 18px 17px 5px;
      }

      &:after {
        content: url('/assets/front/images/fleche-bleue.svg');
        width: 12px;
        height: 23px;
        display: flex;
        transform: rotate(90deg);
        transform-origin: center;
        margin: auto;
        transition: all $duration ease-in-out;

        @include breakpoint(desktop) {
          transform: rotate(0deg);
          position: absolute;
          top: 25%;
          right: 5%;
        }

        @media (min-width: 1200px) {
          display: none;
        }

        @media (min-width: 1280px) {
          display: block;
        }

        @include breakpoint(largedesktop) {
          top: 0;
          bottom: 0;
          right: 2%;
        }
      }

      &:hover {
        border: 1px solid rgba(0,160,246,0.4);
        transition: all $durationSmall $easeInCirc;

        &:after {
          @include breakpoint(desktop) {
            transition: all $duration ease-in-out;
            right: 4%;
          }

          @include breakpoint(largedesktop) {
            right: 1.6%;
          }
        }
      }
    }

    &-block {
      display: flex;
      align-items: center;
      padding-bottom: 15px;
      margin-bottom: 10px;
      position: relative;

      @include breakpoint(desktop) {
        margin-top: 10px;
      }

      @include breakpoint(largedesktop) {
        &.specialWidth {
          width: 22%;
        }
      }

      &:after {
        display: block;
        content: ' ';
        width: 50%;
        height: 1px;
        background-color: $greyBackground;
        position: absolute;
        bottom: 0;
        right: 16%;

        @include breakpoint(phablet) {
          width: 70%;
          right: 12%;
        }

        @include breakpoint(768px) {
          width: 1px;
          height: 50px;
          top: -16px;
          right: 5%;
          margin: auto;
          background-color: darken($greyBackground, 5%);
        }

        @include breakpoint(largedesktop) {
          right: 6%;
        }
      }

      &:last-child {
        &:after {
          display: none;

          @include breakpoint(largedesktop) {
            display: block;
          }
        }
      }
    }

    &-intro {
      margin-bottom: 25px;

      @include breakpoint(tablet) {
        margin-bottom: 20px;
      }

      @include breakpoint(largedesktop) {
       // width: 29%;

        &:after {
          display: block;
          content: ' ';
          position: absolute;
          bottom: 0;
          width: 1px;
          height: 50px;
          top: 9%;
          right: 5%;
          margin: auto;
          background-color: darken($greyBackground, 5%);
        }
      }
    }

    &-type {
      font-size: 15px;
      color: lighten($blueColor, 10%);
      margin-bottom: 15px;
    }

    &-name {
      width: 95%;
      color: $greyText;
      text-transform: uppercase;
      font-size: 20px;
      margin-bottom: 5px;
      padding-right: 25px;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;

      @include breakpoint(laptop) {
        margin-bottom: 10px;
      }
    }

    &-more {
      display: inline-block;
      color: $greyText;
      font-size: 14px;
      font-weight: 300;
      position: relative;
      line-height: 14px;
      transition: all $duration $easeInOutCirc;

      @include breakpoint(768px) {
        float: right;
        bottom: 25px;
        right: 25px;
      }

      @include breakpoint(desktop) {
        float: inherit;
        bottom: 0;
        right: 0;
      }

      &:hover {
        color: $blueColor;
        transition: all $duration $easeInOutCirc;

        &:after {
          margin-left: 30px;
          transition: all $duration $easeInOutCirc;
        }
      }

      &:before {
        display: block;
        content: ' ';
        width: 100%;
        height: 1px;
        background-color: $greyText;
        opacity: 0.3;
        position: relative;
        top: 15px;
      }

      /*&:after {
        display: inline-block;
        content: url('/assets/front/images/icone-fleche.svg');
        width: 6px;
        height: 11px;
        position: absolute;
        margin-left: 25px;
        transition: all $duration $easeInOutCirc;
      }*/
    }

    &-days {
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      width: 65px;
      height: 65px;
      border: 2px solid $greenColor;
      border-radius: 500px;
      position: relative;
      color: $greenColor;
      margin-right: 20px;
      flex-shrink: 0;

      @include breakpoint(tablet) {
        margin-right: 15px;
        margin-left: 5px;
      }

      div {
        display: inline-block;
        text-align: center;
        font-size: 19px;
        text-transform: uppercase;
        line-height: 18px;
        //margin: auto;

        &.suffixe {
          font-size: 20px;
        }

        span {
          display: block;
          text-transform: none;
          font-size: 12px;
          color: $greyText!important;
        }
      }

      &.finished {
        color: #717171;
        border: 2px solid #717171;
      }
    }
    &-learners-details {
      margin-left:25px;
    }



    &-details {
      font-weight: bold;
      font-size: 13px;
      color: $greyLightColor;

      .h3-title {
        margin-bottom: 5px;
        font-size:16px;
      }

      b {
        color: $greenColor;
      }

      div {
        line-height: 1.2;
      }

      &.finished b {
        color: #717171;
      }
    }

    &-infos {
      margin-bottom: 0px;

      @include breakpoint(tablet) {
        margin-bottom: 5px;
      }

      @media (min-width: 768px) and (max-width: 880px) {
        margin-bottom: 35px;
      }

      @include breakpoint(largedesktop) {
        //width: 21%;
        position: relative;
        right: 15px;

        &:after {
          display: block;
          content: ' ';
          position: absolute;
          width: 1px;
          height: 50px;
          top: -16px;
          right: 1%;
          margin: auto;
          background-color: darken($greyBackground, 5%);
        }
      }

      .training-days {
        border: 2px solid lighten($greyColor, 5%);
        color: $blueColor;

        &.complete {
          border: 2px solid $greenColor;
          color: $greenColor;
        }

        &.quarter {
          border-right-color: $blueColor;
        }

        &.twoquarter {
          border-right-color: $blueColor;
          border-bottom-color: $blueColor;
        }

        &.threequarters {
          border-left-color: $blueColor;
          border-right-color: $blueColor;
          border-bottom-color: $blueColor;
        }

        &.fourquarters {
          border: 2px solid $greenColor;
          color: $greenColor;
        }


        div {
          font-size: 16px;
          font-weight: 400;
          top: 35%;
        }
      }

      .training-details {
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: $greyText;
        }
      }
    }
  }

  .alertDays {
    .training-days {
      color: $redColor;
      border: 2px solid $redColor;

      span {
        color: $redColor!important;
      }
    }

    .training-details b {
      color: $redColor;
    }
  }

  .advertDays {
    .training-days {
      color: $blueColor;
      border: 2px solid $blueColor;
    }

    .training-details b {
      color: $blueColor;
    }
  }

.grouped-actions-container {
margin : 10px auto;
  .bg-success {
    background:forestgreen;
  }
  a {color:#ffffff; border-color:#ffffff}
  #progressbar div {margin:0!important}
  select:after {
    content:"&or;"
  }
}


.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);

  .modal-content {
    width:100%
  }

  .modal-title {
    font-size: 24px;
  }

  .modal-header,.modal-body,.modal-footer {
    text-align:center
  }

}