/* ==========================================================================
   Page / Training User Details Manager
   ========================================================================== */

  .trainuser {
    display: table;
    width: 100%;
    max-width: 1350px;
    margin: auto;
    padding: 25px 0px;
    background-color: $whiteColor;
    border-radius: 10px;
    border: 1px solid #dddddd;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.09);
    margin-bottom: 20px;
    position: relative;
    transition: all $duration $easeInCirc;

    @include breakpoint(phablet) {
      padding: 30px 0px;
    }

    @include breakpoint(tablet) {
      padding: 35px 0px;
    }

    @include breakpoint(desktop) {
      padding: 45px 0px;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        width: 100%;
      }
    }

    .backArrow {
      display: block;
      position: absolute;
      top: 40px;
      left: 25px;
      width: 16px;
      height: 16px;
      transform: rotateZ(135deg);
      border-right: 3px solid $greyText;
      border-bottom: 3px solid $greyText;
      text-indent: -9999px;
      transition: all $duration $easeInOutCirc;

      &:hover {
        left: 30px;
        transition: all $duration $easeInOutCirc;
      }

      @include breakpoint(phablet) {
        top: 50px;
        left: 35px;
      }

      @include breakpoint(1200px) {
        top: 65px;
      }
    }

    &-infos {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 50px;
    }

    &-name {
      font-size: 22px;
      font-weight: 300;
      line-height: 25px;
      padding: 12px 20px 12px 60px;
      position: relative;
      margin-left: 75px;
      margin-bottom: 30px;
      background: url('/assets/front/images/picto-apprenant.svg') top left/ 45px 45px no-repeat;

      @include breakpoint(phablet) {
        font-size: 30px;
        line-height: 30px;
        padding: 12px 0 12px 60px;
        margin-left: 95px;
        margin-bottom: 20px;
      }

      @include breakpoint(991px) {
        width: 18%;
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 0;
        padding: 3px 0 3px 60px;
      }

      @include breakpoint(1140px) {
        width: 17%;
      }

      @include breakpoint(1200px) {
        margin-left: 125px;
      }

      &:before {
        display: block;
        content: ' ';
        position: absolute;
        bottom: 0;
        width: 1px;
        height: 85%;
        top: 0;
        left: -20px;
        margin: auto;
        background-color: #e1e7eb;

        @include breakpoint(phablet) {
          left: -30px;
          height: 100%;
        }

        @include breakpoint(phablet) {
          top: 80px;
          height: 115px;
        }

        @include breakpoint(991px) {
          top: -5px;
          left: -12%;
          height: 80px;
        }

        @include breakpoint(1200px) {
          left: -20%;
        }
      }

      &:after {
        display: block;
        content: ' ';
        position: absolute;
        bottom: 0;
        width: 1px;
        height: 50px;
        top: 0;
        right: -5%;
        margin: auto;
        background-color: #e1e7eb;

        @include breakpoint(1200px) {
          right: -15%;
        }
      }

      b {  @include breakpoint(991px) { display: block;  } }

      &.late {
        &:before {
          position: absolute;
          left: 1px;
          top: -35px;
          width: 15px;
          height: 20px;
          content: url('/assets/front/images/icone-alerte.svg');
          background: transparent;

          @include breakpoint(phablet) {
            top: -45px;
          }

          @include breakpoint(990px) {
            top: -25px;
          }
        }
      }

      .alert {
        display: table;
        margin-top: 5px;
        font-size: 13px;
        line-height: 10px;
        font-weight: bold;
        color: $whiteColor;
        background: $redColor;
        border-radius: 15px;
        padding: 2px 12px 3px;

        @include breakpoint(1160px) {
          position: absolute;
        }
      }
    }

    &-details {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding-left: 40px;
      padding-right: 40px;

      @include breakpoint(410px) {
        padding-left: 60px;
        padding-right: 60px;
      }

      @include breakpoint(530px) {
        padding-left: 60px;
        padding-right: 20px;
      }

      @include breakpoint(phablet) {
        padding-left: 95px;
        padding-right: 20px;
      }

      @include breakpoint(768px) {
        flex-wrap: nowrap;
        padding-right: 0;
      }

      @include breakpoint(991px) {
        padding-left: 50px;
      }

      @include breakpoint(1200px) {
        padding-left: 80px;
        padding-right: 2%;
      }

      @include breakpoint(1300px) {
        padding-left: 100px;
        padding-right: 45px;
      }

      div {
        margin-bottom: 10px;

        @include breakpoint(768px) {
          margin-bottom: 0;
        }
      }

      &:after {
        display: none;
        content: ' ';
        position: absolute;
        bottom: 0;
        width: 1px;
        height: 50px;
        top: 0px;
        right: 2%;
        margin: auto;
        background-color: #e1e7eb;

        @include breakpoint(760px) {
          right: -6%;
        }

        @include breakpoint(800px) {
          right: -4.5%;
        }

        @include breakpoint(991px) {
          right: -8%;
        }

        @include breakpoint(768px) {
          display: block;
        }

        @include breakpoint(992px) {
          right: -2%;
        }

        @include breakpoint(1200px) {
          right: -0.5%;
        }
      }

      .validation {
        display: inline-block;

        b {
          font-size: 18px;
        }
      }

      .timespend, .timeto {
        display: inline-block;
        text-align: center;
        font-size: 14px;
        line-height: 14px;

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          letter-spacing: -0.02rem;
        }

        &.finish {
          opacity: 0.4;
        }

        div {
          display: block;
          color: $greyLightColor;
          line-height: 20px;
          font-size: 20px;
          margin-top: 8px;

          span {
            font-size: 16px;
          }

        }
      }

      .modules {
        width: 65px;
        height: 65px;
        border: 2px solid lighten($greyColor, 5%);
        border-radius: 500px;
        position: relative;
        color: $blueColor;
        margin-right: 5px;
        flex-shrink: 0;
        bottom: 3px;

        @include breakpoint(phablet) {
          margin-right: 25px;
        }

        @include breakpoint(720px) {
          margin-right: 30px;
        }

        @include breakpoint(750px) {
          margin-right: 0px;
        }

        @include breakpoint(780px) {
          margin-right: 15px;
          margin-left: 5px;
        }

        div {
          display: inline-block;
          text-align: center;
          font-size: 16px;
          text-transform: uppercase;
          line-height: 15px;
          position: absolute;
          top: 15px;
          left: 0;
          right: 0;
          margin: auto;

          span {
            display: block;
            text-transform: none;
            font-size: 12px;
            color: $greyLightColor!important;
            opacity: 0.6;
          }
        }

        &.complete {
          border: 2px solid $greenColor;
          color: $greenColor;
        }

        &.quarter {
          border-right-color: $blueColor;
        }

        &.twoquarter {
          border-right-color: $blueColor;
          border-bottom-color: $blueColor;
        }

        &.threequarters {
          border-left-color: $blueColor;
          border-right-color: $blueColor;
          border-bottom-color: $blueColor;
        }
      }
    }

    &-practice {
      padding-left: 43px;

      @include breakpoint(410px) {
        padding-left: 60px;
      }

      @include breakpoint(phablet) {
        padding-left: 95px;
      }

      @include breakpoint(768px) {
        padding-left: 60px;
      }

      @include breakpoint(800px) {
        padding-left: 55px;
      }

      @include breakpoint(830px) {
        padding-left: 80px;
      }

      @include breakpoint(991px) {
        padding-left: 40px;
      }

      @include breakpoint(1140px) {
        padding-left: 60px;
      }

      @include breakpoint(1200px) {
        padding-left: 40px;
      }

      @include breakpoint(1300px) {
        padding-left: 50px;
      }

      b {
        display: block;
        font-size: 18px;
      }

      .validInput {
        display: inline-block;
        input[type="radio"]:checked + label {
          color: #3dce62;
          &:before {
            border: 2px solid #3dce62;
          }

          &:after {
            background: #3dce62;
          }
        }
      }

      .unvalidInput {
        display: inline-block;

        @include breakpoint(1200px) {


          label {
            white-space: nowrap;
          }
        }

        input[type="radio"]:checked + label {
          color: #be2e2e;
          &:before {
            border: 2px solid #be2e2e;
          }

          &:after {
            background: #be2e2e;
          }
        }
      }
      .null {
        display: inline-block;

        @include breakpoint(1200px) {


          label {
            white-space: nowrap;
          }
        }

        input[type="radio"] + label {
          margin-right:0;
          &:before {
            display:none
          }

          &:after {
            display:none;
          }
        }
      }


      input[type="radio"] {
        display: none;
      }

      input[type="radio"] + label {
        font-size: 16px;
        line-height: 1;
        font-weight: 600;
        margin-right: 15px;
        font-family: "pangram", sans-serif;
        position: relative;
        cursor: pointer;

        &:before {
          content: ' ';
          display: inline-block;
          width: 17px;
          height: 17px;
          border-radius: 500px;
          border: 2px solid #1f73fd;
          padding: 0;
          margin-top: 0;
          margin-right: 10px;
          position: relative;
          top: 2px;
          cursor: pointer;
        }

        &:after {
          content: ' ';
          border-radius: 800px;
          width: 9px;
          height: 9px;
          background: #1f73fd;
          position: absolute;
          top: 4px;
          left: 2px;
          bottom: 0;
          margin: 2px;
          transform: scale(0.0);
          opacity: 0;
          transition: all 300ms ease-in;
        }
      }

      input[type="radio"]:checked + label {
        color: #1f73fd;

        &:after {
          transform: scale(1.0);
          opacity: 1.0;
          transition: all 300ms ease-in;
        }
      }
    }

    &-report {
      display: table;
      width: 100%;
      border-top: 1px solid darken($greyBackground, 8%);

      .resultseval{
        .trainuser-times{
          padding: 5px 10px 55px 100px;
        }

      }
    }

    &-modules {
      display: none;
      padding: 55px 50px 55px;
      position: relative;

      @include breakpoint(phablet) {
        display: block;
      }

      @include breakpoint(tablet) {
        padding: 55px 60px;
      }

      @include breakpoint(laptop) {
        padding: 55px 80px;
      }

      @include breakpoint(desktop) {
        padding: 55px 100px;
      }

      .modules {
        display: block;
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        height: 100px;
        position: relative;

        .slide-next, .slide-prev {
          display: block;
          position: absolute;
          right: 0;
          top: -8px;
          width: 40px;
          height: 100%;
          background: $whiteColor;
          z-index: 15;
          cursor: pointer;

          span {
            display: block;
            position: absolute;
            top: 65px;
            right: 0;
            width: 20px;
            height: 20px;
            background: #ffffff url(/assets/front/images/icone-fleche.svg) 12px center/8px 13px no-repeat;
            border: 2px solid $greyLightColor;
            border-radius: 500px;
            padding: 15px;
            opacity: 0.5;
            transition: all $duration $easeInOutCirc;

            &:hover {
              opacity: 0.8;
              border: 2px solid $blueColor;
              transition: all $duration $easeInOutCirc;
            }
          }
        }

        .slide-prev {
          display: none;
          left: 0;
          right: inherit;

          span {
            left: 0;
            right: inherit;
            transform: rotate(180deg);
          }
        }

        &-wrapper {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          position: absolute;
          left: 0px;
          z-index: 0;
          transition: all $durationLong $easeOutCubic;
          background: url('/assets/front/images/bg-tirets.jpg') 0px 85% repeat-x;
        }

        &-elem {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          width: 35%;
          min-height: 100%;
          align-self: stretch;
          min-width: 230px;
          flex: 1 auto;
          color: $greyLightColor;
          text-align: left;
          position: relative;
          margin-top: 10px;

          &:first-child {
            align-items: flex-start;
            width: 19%;
            min-width: 150px;

            .title {
              text-align: left;
              margin: 0 0 20px;
            }

            &.unfinish {
              &:after {
                display: none;
              }
            }
          }

          &:last-child {
            align-items: flex-end;
            width: 19%;
            min-width: 150px;

            .title {
              text-align: right;
              margin: 0 0 20px;
            }

            .state {
              left: 0;
            }
          }

          .title {
            display: block;
            font-size: 16px;
            text-align: center;
            max-width: 150px;
            max-height: 35px;
            margin: 0 auto 5px;
          }

          .state {
            display: inline-block;
            width: 30px;
            height: 30px;
            background: url('/assets/front/images/icone-lock.svg') center center/30px 30px no-repeat;
            left: 0;
            position: relative;
            z-index: 1;
          }
        }

        .validate {
          color: $greenColor;

          &:before {
            content: ' ';
            width: 150%;
            height: 3px;
            position: absolute;
            left: 5px;
            bottom: 14px;
            background: $greenColor;
            z-index: 0;
          }

          .state {
            background: url('/assets/front/images/icone-selectbox-valid.svg') center center/25px 25px no-repeat;
            width: 25px;
            height: 25px;
            bottom: 3px;
          }
        }

        .unvalidate {
          color: $redColor;

          &:after {
            content: ' ';
            width: 150%;
            height: 3px;
            position: absolute;
            left: 5px;
            bottom: 14px;
            background: $greenColor;
            z-index: 0;
          }

          .state {
            background: url('/assets/front/images/icone-invalid.svg') center center/25px 25px no-repeat;
            width: 25px;
            height: 25px;
            bottom: 3px;
          }
        }

        .unfinish {
          color: $blueColor;
          font-weight: bold;
          background: transparent;
          padding: 0;

          &:before {
            content: ' ';
            width: 50%;
            height: 3px;
            position: absolute;
            left: 5px;
            bottom: 14px;
            background: $greenColor;
            z-index: 0;
          }

          .state {
            width: 35px;
            height: 35px;
            background: url('/assets/front/images/icone-progress.svg') center center/35px 35px no-repeat;
          }
        }
      }
    }

    &-times {
      padding: 45px 45px 35px 30px;
      position: relative;

      @include breakpoint(phablet) {
        display: block;
        padding: 15px 50px 35px;
      }

      @include breakpoint(768px) {
        padding: 25px 60px;
      }

      @include breakpoint(laptop) {
        padding: 55px 80px;
      }

      @include breakpoint(desktop) {
        padding: 55px 100px;
      }

      .timesrow {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;

        @include breakpoint(885px) {
          flex-direction: row;
          align-items: flex-end;
          margin-bottom: 35px;
        }

        .title {
          font-size: 18px;

          @include breakpoint(1100px) {
            white-space: nowrap;
          }
        }

        @media (max-width: 885px) {
          &:first-child {
            margin-bottom: 15px;

            .timepast:before { display: none; }
            .timeref:before { display: none; }

            .avancement {
              margin-bottom: 0;
            }
          }
        }

        &.validate {
          .avancement-title {  opacity: 1.0;  font-weight: 400; }
          .avancement-state {  background-color: $greenColor;  }
          .avancement-picto { background: url('/assets/front/images/picto-valide.svg') center center/21px 15px no-repeat; width: 21px;  height: 15px; right: -18px; top:20px}
          .timepast span { color: $greenColor; opacity: 1.0; }
          &.submodule .avancement-title, &.submodule .timepast { font-weight: 400; opacity: 1.0; }
        }

        &.unvalidate {
          .avancement-title {  opacity: 1.0;  font-weight: 400; }
          .avancement-state {  background-color: $redColor;  }
          .avancement-picto {  background: url(/assets/front/images/icone-invalid-blanc.svg) center center/38px 32px no-repeat; color: $redColor; font-size: 18px; width: 38px;  height: 32px;  top: 20px; right: -39px; padding-top: 2px; padding-left: 63px; text-indent: -99999px; @include breakpoint(1100px) {text-indent: 0px;} }
          .timepast span { color: $redColor; opacity: 1.0; }
          &.submodule .avancement-title, &.submodule .timepast { font-weight: 400; opacity: 1.0; }
        }

        &.unfinish {
          .avancement-title {  opacity: 1.0;  font-weight: 400; }
          .avancement-picto { background: url('/assets/front/images/icone-sablier.svg') center center/14px 15px no-repeat; width: 14px;  height: 15px; right: -15px; }
          .timepast span { color: lighten($blueColor, 5%); opacity: 1.0; }
          .avancement-state span {  display: block!important;  }
          .avancement-state:before {
            content: attr(data-module);
            display: block;
            position: absolute;
            left: 15px;
            bottom: -3px;
            font-size: 14px;
            letter-spacing: 0.01rem;
            color: $whiteColor;
            z-index: 1;
          }
          &.submodule .avancement-title, &.submodule .timepast { font-weight: 400; opacity: 1.0; }
        }

        &.quarter { .avancement-state:after {  width: 25%;  } }
        &.twoquarter { .avancement-state:after {  width: 50%;  } }
        &.threequarter { .avancement-state:after {  width: 75%;  } }
        &.complete { .avancement-state:after {  width: 100%;  } }
      }

      .avancement {
        flex-basis: 100%;
        position: relative;
        margin-bottom: 20px;

        @include breakpoint(885px) {
          flex-basis: 60%;
          margin-bottom: 0;
        }

        @include breakpoint(1024px) {
          margin-right: 5%;
        }

        @include breakpoint(1100px) {
          flex-basis: 50%;
          margin-right: 2%;
        }

        .title {
          font-weight: bold;
        }

        &-title {
          opacity: 0.7;
          font-weight: 300;
          font-size: 16px;
          padding-left: 3px;
          margin-bottom: 10px;
          line-height: 18px;
        }

        &-state {
          width: 90%;
          height: 16px;
          border-radius: 10px;
          background-color: lighten($greyColor, 3%);
          position: relative;

          span {
            display: none;
            border-radius: 10px;
            width: 5%;
            height: 15px;
            background-color: $blueColor;
            position: absolute;
            z-index: 0;
          }
        }

        &-picto {
          display: block;
          width: 15px;
          height: 22px;
          position: absolute;
          right: -15px;
          bottom: 0;
          background: url('/assets/front/images/icone-cadenas.svg') center center/15px 22px no-repeat;
        }

        .responses {
          margin-left:30px;
          ul{
            list-style-position: inside;
          }
          .label{

            &-1{
              color:$greenColor;
              font-weight:bold;
            }
            &-0{
              color:$redColor;
              font-weight:bold;
            }
          }
          .result {

            img{
              width:25%;
              vertical-align: middle;
              margin-bottom: 5px;
              margin-right: 5px;
            }

            &.result-image {
              list-style: none;
            }

            &.result-1 {
              .picto {
                background: url('/assets/front/images/picto-valide.svg') center center/21px 15px no-repeat;
                width: 21px;
                height: 15px;
                display:inline-block;
              }
            }
            &.result-0 {
              .picto {
                display:inline-block;
                background: url(/assets/front/images/icone-invalid-simple.svg) center center/38px 32px no-repeat;
                width: 21px;
                height: 15px;
              }
            }
          }
        }

      }

      .timepast, .timeref {
        font-size: 16px;
        line-height: 15px;
        flex-basis: 100%;
        margin-bottom: 10px;

        @include breakpoint(885px) {
          font-size: 18px;
          flex-basis: 20%;
          text-align: center;
          margin-bottom: 0;
        }

        @include breakpoint(1100px) {
          flex-basis: 35%;
        }

        .title {
          display: none;

          @include breakpoint(885px) {
            display: block;
          }
        }

        span {
          opacity: 0.7;
        }

        .difference {
          opacity: 1.0;
          display: inline-block;
          font-size: 11px;
          margin-right: -35px;
          margin-left: 5px;
          margin-bottom: -3px;
          position: relative;
          bottom: 10px;

          &.more {  color: #ff0042;  }
          &.less { color: $greenColor; }
        }
      }

      .timepast {
        @media (max-width: 885px) {
          &:before {
            content: 'Temps passé : ';
          }
        }

        .title {
          position: relative;
          left: 0;

          @include breakpoint(1100px) {
            left: 45px;
          }

          @include breakpoint(1280px) {
            left: 0;
          }
        }
      }

      .timeref {
        flex-basis: 100%;

        @media (max-width: 885px) {
          &:before {
            content: attr(data-text);
          }
        }

        @include breakpoint(885px) {
          flex-basis: 15%;
        }
      }

      .submodule {
        margin-bottom: 15px;

        @include breakpoint(phablet) {
          margin-left: 25px;
        }

        @include breakpoint(tablet) {
          margin-left: 0;
        }

        @include breakpoint(885px) {
          align-items: center;
          margin-bottom: 45px;
        }

        &.firstElem {
          margin-top: 10px;

          @include breakpoint(885px) {
            margin-top: 50px;
          }
        }

        .avancement {
          display: flex;
          flex-direction: column;

          @include breakpoint(885px) {
            flex-direction: row;
            align-items: center;
          }

          &-title {
            font-weight: 300;
            opacity: 0.7;
            line-height: 18px;
            padding-left: 45px;
            margin-bottom: 15px;

            @include breakpoint(885px) {
              margin-bottom: 0;
              max-width: 253px;
              padding-right: 40px;
              flex-basis: 28%;
            }

            @include breakpoint(1380px) {
              padding-right: 60px;
              flex-basis: 45%;
            }

            &:before {
              content: ' ';
              display: inline-block;
              width: 30px;
              height: 1px;
              position: absolute;
              left: 0;
              top: 15px;
              background: darken($greyBackground, 10%);
            }
          }

          &-state {
            @include breakpoint(tablet) {
              flex-basis: 45%;
            }
          }

          &-picto {
            margin: auto;

            @include breakpoint(tablet) {
              top: 0;
              bottom: 0;
            }
          }
        }
        .timepast {
          opacity: 0.6;
        }
      }
    }

    &-result {
      display: flex;
      flex-direction: column;
      padding: 0px 30px 35px;
      position: relative;
      border-bottom: 1px solid darken($greyBackground, 8%);

      @include breakpoint(phablet) {
        padding: 0px 50px 35px;
      }

      @include breakpoint(768px) {
        padding: 0px 60px 45px;
        flex-direction: row;
        align-items: center;
      }

      @include breakpoint(laptop) {
        padding: 0px 80px 45px;
      }

      @include breakpoint(desktop) {
        padding: 0px 100px 45px;
      }

      .resultbloc {
        width: 100%;
        margin-right: 45px;
        color: $whiteColor;
        border-radius: 10px;
        text-align: center;
        padding: 10px 0px;
        margin-bottom: 25px;
        background-color: $blueColor;

        @include breakpoint(768px) {
          min-width: 290px;
          max-width: 290px;
          flex-grow: 0;
          vertical-align: top;
          margin-bottom: 0px;
        }

        .title {
          font-size: 18px;
          font-weight: bold;
        }

        .result {
          font-size: 17px;
          font-weight: bold;
        }

        .picto {
          width: 36px;
          height: 36px;
          margin: 10px auto 5px;
          background: url('/assets/front/images/icone-progress-blanc.svg') center center/36px 36px no-repeat;
        }

        &.acquired {
          background-color: $greenColor;
          .picto { background: url('/assets/front/images/icone-acquis.svg') center center/36px 36px no-repeat; }
        }

        &.unacquired {
          background-color: $redColor;
          .picto { background: url('/assets/front/images/icone-invalid-blanc.svg') center center/36px 36px no-repeat; }
        }
      }

      .infos {
        padding: 0px 5px;

        @include breakpoint(768px) {
          padding: 0;
          flex-basis: 85%;
        }

        p {
          line-height: 20px;
          font-size: 14px;
          margin-top: 5px;

          @include breakpoint(desktop) {
            width: 85%;
          }
        }

        ul {
          color: #333;
          margin: 0;
          padding: 0px 17px;
        }
      }
    }

    &-former {
      padding: 45px 45px 0;
      position: relative;

      @include breakpoint(phablet) {
        padding: 45px 50px 0;
      }

      @include breakpoint(768px) {
        padding: 25px 60px 0;
      }

      @include breakpoint(laptop) {
        padding: 55px 80px 0;
      }

      @include breakpoint(desktop) {
        padding: 55px 100px 0;
      }

      .maintitle {
        text-align: center;
        margin-bottom: 30px;

        @include breakpoint(768px) {
          text-align: left;
        }
      }

      .infosFormer {
        position: relative;

        @include breakpoint(970px) {
          display: flex;
        }
      }

      .former {
        display: block;
        text-align: center;
        margin-bottom: 50px;

        @include breakpoint(768px) {
          display: inline-block;
          float: left;
          padding-left: 15px;
          padding-right: 10%;
          margin-bottom: 0;
        }

        @include breakpoint(980px) {
          padding-left: 35px;
          padding-right: 5%;
          float: inherit;
        }

        @include breakpoint(1210px) {
          padding-right: 12%;
        }

        span {
          font-size: 14px;
        }

        img {
          display: block;
          border-radius: 800px;
          max-width: 85px;
          width: auto;
          height: auto;
          margin: 5px auto;
        }
      }

      .detailspractice {
        display: block;
        text-align: center;
        margin-bottom: 30px;

        @include breakpoint(768px) {
          width: 80%;
          text-align: left;
          margin-right: 75px;
          position: relative;
          margin-top: 40px;
          margin-bottom: 15px;
        }

        @include breakpoint(970px) {
          width: auto;
          max-width: 315px;
        }

        @include breakpoint(1020px) {
          max-width: 180px;
        }

        @include breakpoint(1240px) {
          max-width: 315px;
        }

        .h3-title {
          margin-bottom: 5px;
        }

        p {
          font-size: 14px;
          line-height: 1.2;
        }
      }

      .practiceform {
        display: block;
        text-align: center;
        margin-bottom: 30px;

        @include breakpoint(768px) {
          text-align: left;
          margin-bottom: 0;
          margin-top: 40px;
          position: relative;
          padding-left: 160px;
        }

        @include breakpoint(970px) {
          padding-left: 0;
        }

        p {
          color: $greyLightColor;
          font-weight: bold;

          b {
            color: $greyText;
          }
        }
      }

      .state {
        display: block;
        font-size: 17px;
        font-weight: bold;
        text-align: center;

        @include breakpoint(768px) {
          display: inline-block;
          margin-right: 0%;
          position: absolute;
          right: 25px;
          top: 25px;
        }

        @include breakpoint(970px) {
          display: inline-block;
          margin-right: 0%;
          position: absolute;
          right: 0;
          top: 20px;
        }

        @include breakpoint(1350px) {
          right: 20px;
        }

        @include breakpoint(1500px) {
          right: 48px;
        }

        &.tocome {
          color: $blueColor;
          line-height: 1.1;

          &:before {
            content: ' ';
            display: block;
            width: 35px;
            height: 35px;
            margin: 5px auto;
            background: url('/assets/front/images/icone-progress.svg') center center/35px 35px no-repeat;
          }
        }

        &.complete {
          color: $greenColor;

          &:before {
            content: ' ';
            display: block;
            width: 35px;
            height: 35px;
            margin: 5px auto;
            background: url("/assets/front/images/icone-valide.svg") center center/35px 35px no-repeat;
          }
        }

        &.unacquired {
          color: $redColor;

          &:before {
            content: ' ';
            display: block;
            width: 35px;
            height: 35px;
            margin: 5px auto;
            background: url("/assets/front/images/icone-invalid.svg") left center/35px 35px no-repeat;
          }
        }
      }
    }
  }

.resultseval{
  .trainuser-times{
    .timesrow {
      align-items: center;
      border-radius: 15px;
      padding:15px;
      margin-bottom:15px;

      .avancement-picto {
        position: relative;
        background-color: $whiteColor;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        right: inherit;
        left: 0;
        top: calc(50% - 16px);
        padding: 0;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 15px;
      }

      &:nth-child(even) {
        background: $greyColor
      }
      &:nth-child(odd) {
        background: $greyColor

      }
      &.libelle{
        background: $whiteColor
      }
    }
  }

  @include breakpoint(885px) {
    .trainuser-times{
      .avancement {
        padding-left: 60px;

        .avancement-picto {
          position: absolute;
        }
      }
    }
  }
}