/* ==========================================================================
   Components / Excercice : Choix multiple avec image
   ========================================================================== */

  .multiplechoice {
    display: block;
    max-width: 1400px;
    margin: auto;
    padding: 20px 25px;

    @include breakpoint(768px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: row;
      align-items: stretch;
      padding: 40px 30px;
    }

    @include breakpoint(laptop) {
      padding: 40px 65px;
    }

    @include breakpoint(1200px) {
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        max-width: 1200px;
      }
    }

    @include breakpoint(1350px) {
      padding: 40px 0;
    }

    @include breakpoint(1500px) {
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        max-width: 1400px;
      }
    }

    &-elem {
      width: 100%;
      margin: 0 0 20px;
      position: relative;

      @include breakpoint(phablet) {
        width: 90%;
        margin: auto auto 30px;
      }

      @include breakpoint(768px) {
        flex-basis: 45%;
        min-height: 100%;
        margin: 0 15px 40px;
      }

      @include breakpoint(1100px) {
        flex-basis: 30%;
      }

      @include breakpoint(1350px) {
        flex-basis: 345px;
        margin: 0 20px 50px;
      }

      label {
        border-radius: 10px;
        width: 100%;
        min-height: 100%;
        margin: 0;
        font-size: 14px;
        color: $whiteColor;
        font-family: $font-family-base;
        background-color: $darkColor;
        text-align: center;
        position: relative;
        cursor: pointer;

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          height: 100%;
        }

        @include breakpoint(768px) {
          display: flex;
          flex-direction: column;
        }

        &:before {
          content: 'Sélectionner';
          //display: table;
          width: 175px;
          opacity: 0;
          font-size: 14px;
          font-weight: 500;
          margin: auto;
          font-family: $font-family-base;
          color: $whiteColor;
          letter-spacing: 0.13rem;
          background: $blueColor;
          border: 0;
          border-radius: 30px;
          padding: 15px 35px;
          margin-bottom: 30px;
          position: absolute;
          top: 35%;
          left: 0;
          right: 0;
          z-index: 1;
          transition: all $duration $easeInOutCirc;
          cursor: pointer;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            left: 25%;
          }
        }

        &:after {
          content: ' ';
          display: block;
          width: 42px;
          height: 42px;
          border: 2px solid $whiteColor;
          border-radius: 100px;
          position: absolute;
          top: 10px;
          right: 15px;
          transition: all $duration $easeInOutCirc;
        }

        &:hover {
          &:before {
            opacity: 1.0;
            transition: all $duration $easeInOutCirc;
          }
          img {
            opacity: 0.5;
            transition: all $duration $easeInOutCirc;
          }
        }

        img {
          display: block;
          max-height: 175px;
          min-height: 175px;
          border-radius: 10px 10px 0 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          transition: all $duration $easeInOutCirc;

          @include breakpoint(desktop) {
            max-height: 195px;
            min-height: 195px;
          }

          @include breakpoint(1100px) {
            max-height: 175px;
            min-height: 175px;
          }

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            height: 100%;
            width: auto;
            min-width: 100%;

            @include breakpoint(phablet) {
              //max-width: 100%;
              min-height: 100%;
              height: auto;
            }

            @include breakpoint(desktop) {
              //min-height: auto;
            }
          }
        }

        div {
          width: 100%;
          min-height: 40px;
          display: block;
          text-align: center;
          font-weight: bold;
          font-size: 16px;
          line-height: 1.2;
          font-family: $font-family-base;
          color: $greyText;
          border-radius:  0 0 10px 10px;
          background: darken($greyBackground, 1%);
          padding: 10px 0;
          margin: 0;
          transition: all $duration $easeInOutQuart;
          z-index: 1;

          @include breakpoint(768px) {
            flex-grow: 1;
          }

          p {
            font-size: 14px;
            line-height: 1.4;
            padding: 3px 20px;
            font-weight: 400;

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
              font-weight: 300;
            }
          }
        }

        &.nolabel {
          min-height: 215px;
          height: 100%;
          border-radius: 10px;
          overflow: hidden;

          &:before {
            top: 35%!important;
          }

          img {
            //display: table;
            max-height: 100%;
            min-height: 100%;
            position: absolute;
            bottom: 0;
          }
        }
      }

      input[type="checkbox"]:checked + label {
        color: $blueColor;

        &:before {
          content: 'Désélectionner';
          width: 195px;
          background: $whiteColor;
          color: $blueColor;
          transition: all $duration $easeInOutCirc;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            left: 23%;
          }
        }

        &:after {
          border: 0;
          background: url('/assets/front/images/icone-selectbox.svg') center center/42px 42px no-repeat;
          border-color: $blueColor;
          transition: all $duration $easeInOutCirc;
        }

        img {
          opacity: 0.5;
          transition: all $duration $easeInOutCirc;
        }

        span {
          background: $blueColor;
          color: $whiteColor;
          transition: all $duration $easeInOutQuart;
        }
      }

      input[type="checkbox"] {
        display: none;
      }
    }

    // Etat désactivé après envoi des réponses
    .disabled {
      label {
        &:before {
          display: none;
          width: 100%;
          top: 4rem;
          left: 0;
          font-weight: bold;
          font-size: 22px;
          line-height: 1.3;
          letter-spacing: 0;
          background: transparent;
          text-transform: uppercase;
          opacity: 1.0!important;
          cursor: default;
        }

        img {
          opacity: 0.4 !important;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            height: 100%;
            width: auto;
            min-width: 100%;

            @include breakpoint(phablet) {
              max-width: 100%;
            }

            @include breakpoint(desktop) {
              min-height: auto;
            }
          }
        }
      }

      &:hover {
        label {
          cursor: default;

          &:before { display: none; }
          img { opacity: 1.0; }
        }
      }
    }

    // Etat selon les réponses de l'utilisateur
    .selectValid {
      &:hover{
        label:before {
          display: block;
        }
      }

      label{
        div {
          background-color: $greenColor;
          color: $whiteColor;
        }

        &:before {
          display: block;
          content: 'Bien joué !';
          color: $greenColor;
        }

        &:after {
          background: url('/assets/front/images/icone-selectbox-valid.svg') center center/42px 42px no-repeat;
          border: 0;
        }
      }
    }

    .selectError {
      &:hover{
        label:before {
          display: block;
        }
      }

      label{
        div {
          background-color: darken($redColor, 2%);
          color: $whiteColor;
        }

        &:before {
          display: block;
          content: 'Mauvaise réponse';
          color: lighten($redColor, 10%);
        }

        &:after {
          background: url('/assets/front/images/icone-invalid.svg') center center/42px 42px no-repeat;
          border: 0;
        }
      }
    }

    .unselectValid {
      animation-name: bounceIn;
      animation-duration: 1s;
      animation-fill-mode: both;

      &:hover{
        label:before {
          display: block;
        }
      }

      label{
        div {
          color: $greenColor;
        }

        &:before {
          display: block;
          top: 3.5rem;
          content: 'C’était une bonne réponse';
          color: $whiteColor;

          @include breakpoint(tablet) {
            width: 80%;

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
              width: 85%;
            }
          }
        }

        &:after {
          background: url('/assets/front/images/icone-selectbox-valid-v2.svg') center center/42px 42px no-repeat;
          border: 0;
        }
      }
    }
  }