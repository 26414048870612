/* ==========================================================================
   Base / Titles
   ========================================================================== */

  .h1-title {
    font-family: $font-family-base;
    font-size: 25px;
    color: $blueColor;
    text-transform: uppercase;
    letter-spacing: 0.085rem;
    margin-bottom: 15px;

    @include breakpoint(tablet) {
      font-size: 30px;
    }
  }

  .h2-title {
    font-family: $font-family-sans-serif;
    font-size: 25px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 25px;
  }

  .h3-title {
    font-family: $font-family-base;
    color: $greyText;
    font-size: 18px;
    font-weight: bold;
  }

  .h4-title {
    font-family: $font-family-base;
    color: $blueColor;
    font-size: 14px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .maintitle {
    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
