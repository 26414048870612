/* ==========================================================================
   Components / Excercice : Vrai ou Faux
   ========================================================================== */

  .truefalse {
    margin-top: 5px;
    margin-bottom: 35px;
    padding: 20px 25px;

    @include breakpoint(phablet) {
      padding: 20px 45px;
    }

    @include breakpoint(desktop) {
      margin: 15px auto 35px;
    }

    &-content {
      max-width: 600px;
      margin: auto auto 15px;

      img, iframe, video {
        width: 100%;
        border-radius: 10px;
        margin-bottom: 10px;
      }

      iframe, video {
        @include breakpoint(phablet) {
          min-height: 240px;
        }

        @include breakpoint(desktop) {
          min-height: 220px;
        }
      }

      .video {
        cursor: pointer;
        position: relative;

        &:after {
          content: ' ';
          display: none;
          width: 65px;
          height: 65px;
          background: url('/assets/front/images/picto-play-video.svg') center center/65px 65px no-repeat;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          z-index: 5;

          @include breakpoint(tablet) {
            top: 0;
            width: 80px;
            height: 80px;
            background: url('/assets/front/images/picto-play-video.svg') center center/80px 80px no-repeat;
          }
        }

        @-moz-document url-prefix() {
          &:after {
            display: none!important;
          }
        }

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          &:after {
            display: block;
          }
        }

        &.isPlay:after {
          display: none;
        }
      }

      p {
        display: block;
        margin: 10px 0 25px;
      }

      .imageson, .image, .video {
        position: relative;
        margin-bottom: 10px;
      }

      .imageson {
        max-width: 400px;
        margin: auto auto 30px;
        margin-bottom: 30px;
        border-radius: 0 0 10px 10px;
        overflow: hidden;

        .image {
          width: 100%;
          height: 180px;
          background: $greyColor;
          border-radius: 10px 10px 0 0;
          overflow: hidden;
          margin-bottom: 0;
          cursor: pointer;

          @include breakpoint(tablet) {
            height: 205px;
          }

          img {
            display: block;
            width: 100%;
            height: 100%;
            min-height: 180px;
            border-radius: 0;
            object-fit: cover;
            margin-bottom: 0;

            @include breakpoint(tablet) {
              min-height: 200px;
            }
          }

          &:after {
            content: ' ';
            display: block;
            width: 70px;
            height: 62px;
            background: url('/assets/front/images/picto-son.svg') center center/70px 62px no-repeat;
            position: absolute;
            top: 20%;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 1;

            @include breakpoint(phablet) {
              top: 22%;
              width: 90px;
              height: 85px;
              background: url('/assets/front/images/picto-son.svg') center center/90px 82px no-repeat;
            }
          }
        }

        .audiocontent {
          width: 100%;
          background: #fafafa;
          padding: 5px;
          filter: brightness(0.95) hue-rotate(-10deg) contrast(105%);
          left: 0;
          bottom: 0;

          label {
            display: none;
            font-family: $font-family-base;
            font-size: 18px;
            font-weight: bold;
            color: $greyText;
            margin: 0 0 0 15px;
            position: relative;
            top: 5px;
            z-index: 1;

              @-moz-document url-prefix() {
                top: 5px;
                color: $whiteColor;
              }

              @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                color: $whiteColor;
              }
          }

          audio {
            width: 100%;
          }
        }
      }
    }

    &-answer {
      text-align: center;
      margin-bottom: 25px;

      .answer {
        display: inline-block;
        width: 43%;
        max-width: 150px;
        margin: 0 3px;

        @include breakpoint(desktop) {
          margin: 0 10px;
        }

        &.disabled {
          label {
            cursor: default;

            &:hover {
              background: transparent;
              color: $greyText;
            }
          }
        }
        &.selectValid {
          label {
            cursor: default;
            background: $greenColor;
            border: 1px solid $greenColor;
            color: $whiteColor;

            &:hover {
              background: $greenColor;
              border: 1px solid $greenColor;
              color: $whiteColor;
            }
          }
        }
        &.selectError {
          label {
            cursor: default;
            background: $redColor;
            border: 1px solid $redColor;
            color: $whiteColor;

            &:hover {
              background: $redColor;
              border: 1px solid $redColor;
              color: $whiteColor;
            }
          }
        }
        &.unselectValid {
          animation-name: bounceIn;
          animation-duration: 1s;
          animation-fill-mode: both;

          label {
            cursor: default;
            border: 1px solid $greenColor;
            box-shadow:inset 0 0 0 2px $greenColor;

            &:hover {
              background: transparent;
              color: $greyText;
            }

          }
        }
      }

      label {
        width: 100%;
        min-height: 100%;
        font-family: $font-family-base;
        font-size: 16px;
        line-height: 1.2;
        color: $greyText;
        text-align: center;
        padding: 15px 15px;
        border: 1px solid $blueColor;
        transition: all $durationSmall $easeInOutQuart;
        cursor: pointer;

        @include breakpoint(desktop) {
          margin: 0 10px;
        }

        @include breakpoint(xldesktop) {
          &:hover {
            background: $blueColor;
            color: $whiteColor;
            transition: all $durationSmall $easeInOutQuart;
          }
        }
      }

      input[type="radio"]:checked + label {
        background: $blueColor;
        color: $whiteColor;
        transition: all $durationSmall $easeInOutQuart;
      }

      input[type="radio"] {
        display: none;
      }
    }

    &-result {
      display: block;
      text-align: center;
      max-width: 750px;
      margin: 35px auto 0;
      padding: 0 15px;
      font-size: 17px;
      line-height: 1.4;
    }
  }