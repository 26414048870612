/*-- RESET ALL --*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin:0;
  padding:0;
  border:0;
  outline:0;
  font-weight:inherit;
  font-style:inherit;
  font-size:100%;
  font-family:inherit;
  vertical-align:baseline;
}

html {
  overflow-x: hidden;
}

body {
  display: block;
  width: 100%;
  max-width: 100%;
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: normal;
  line-height: $line-height-base;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
}


a:focus {
  text-decoration: none;
}

b, strong {
  font-weight: 600;
}

strong.uppercase {
  text-transform: uppercase;
}

.small, small {
  font-size: 13px;
}

em, i {
  font-style: italic;
}

.hidden {
  display: none;
}

.errorColor {
  color: $redColor;
}

.validColor {
  color: $greenColor;
}

.main-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
  padding: 10px 15px 50px;
  background: $greyBackground;
  //background: $greyBackground url('/assets/front/images/background-flamme.svg') right bottom/auto 90% no-repeat;
}

.fullcontent {
  min-height: 100vh;
}

.wrapper {
  display: table;
  width: 100%;
  max-width: 1350px;
  margin: auto;
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}